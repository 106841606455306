// Common Angular modules/components
import { HttpClient, HttpEventType, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';

// User-defined services
import { ArtworkShadowsService, MainService, UtilsService, store } from 'src/app/shared/services';
import { AlertMessageService } from 'src/app/components/alert-message/alert-message.service';

// Environment
import { environment } from '@environments';

// Third party plugins NPM
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { cloneDeep, debounce, sortBy } from 'lodash'
import { Base64 } from 'js-base64';
import moment from 'moment';
import { messages } from '@data';

// User-defined interfaces
import {
	Artwork,
	Exhibition,
	ExhibitionColor,
	MeshesBlocker,
	PositionPlaceholders,
	SceneMetadata,
	State,
	TextWall
} from '@interfaces';

import { ArtworkLoaderService } from '@services'; 
import { LoadingGalleryService } from 'src/app/components/loading-gallery/loading-gallery.service';
import { UndoRedoService } from 'src/app/shared/services/undo-redo.service';
import { TextToSpeechService } from 'src/app/shared/services/text-to-speech.service';
import { StreamUrlArtwork } from 'src/app/shared/interfaces/create-text-to-speech';
import { SplashScreenService } from './publish/splash-screen/splash-screen.service';
import { TextService } from './text/service/text-service.service';
import { TextLoaderService } from './text/service/text-loader.service';

// Third party plugins CDN
declare const BABYLON: any;

@Injectable({providedIn: 'root'})

export class EditorService {

	//#region Properties

	// Field of View
	public showFieldOfView: boolean = false;
	
	// Edit Frame Mode
	public closeEditFrame: boolean = false;

	// Babylon Core Object
	public canvas: any;
	public engine: any;
	public scene: any;

	// Frame Templates
	public displaySaveEditFrameTemplatePopup: boolean = false;
	public displayDeleteFrameTemplatePopop: boolean = false;
	public editFrameTemplateMode: boolean = false;
	public sidDeletedFrameTemplates: any = [];
	public editedFrameTemplates: any = [];
	public defaultFrameTemplate:any = {
        frame: {
            frame: true,
            frame_color: "#000000",
            frame_depth: 0.03,
						frame_width_top: 0.03,
						frame_width_bottom: 0.03,
						frame_width_right: 0.03,
						frame_width_left: 0.03,
            frame_texture: null,
            frame_material_color: true,
            frame_material_texture: false
        },
        passepartout: {
            passepartout: true,
            passepartout_color: "#ffffff",
            passepartout_depth: 0.02,
						passepartout_width_top: 0.1,
						passepartout_width_bottom: 0.1,
						passepartout_width_right: 0.1,
						passepartout_width_left: 0.1,
            passepartout_texture: null,
            passepartout_material_color: true,
            passepartout_material_texture: false
        },
        back_frame: {
            back_frame_color: "#000000",
            back_frame_depth: 0.01
        },
        sid_frame: null
    }

	// Exibitions
	public exhibition: Exhibition = null;
	public activeExhibitionNode: any = null;
	public exhibitionNodes: any = [];
	public publishDataValid: boolean = true;
	public colorsHasChanges: boolean = false;

	// Cameras
	public camera: any;
	public mainCameraNode: any;
	public cameraRay: any;
	public cameraStartPos: any;
	public cameraStartRot: any;

	// Observables
	public observables: any = {};

	// Align limit
	public topLimit: any;
	public bottomLimit: any;
	
	// Artwork
	public artworks: any = [];
	public activeArtwork: Artwork;
	public artworkDonuts:any = [];
	public artworkShadows:any = [];
	public activeArtworkNode: any;
	public activeArtworkDonut:any;
	public artworkNodes: any = [];
	public requestArtworkTmpData = [];
	public activeArtworkId: string = null;
	public deletedFigureIds: number[] = [];
	public artworkDataValid: boolean = true;
	public applyToAllFrameArtwork: boolean = false;
	public applyToAllArtworkInfo: boolean = false;
	public onPlacingArtwork: boolean = false;
	public artworkToPlaced: any = null;
	public loadedArtworks: string[] = [];
	public previewAutoPlacedArtworks: boolean = false;

	// Ordinary Object
	public ordinaryObjects: any = [];
	public ordinaryObjectsNodes: any = [];
	public activeOrdinaryObject: any;
	public activeOrdinaryObjectNode: any;
	public ordinaryObjectDataValid: boolean = true;

	// lights
	public artworkLighting: any;
	public directionalLight: any;

	// Shadow
	public shadowGenerator: any;

	// Log Activity
	public logActivity: string = "";
	public updatedDataAt: string = "";

	// Gizmos
	public gizmos: any;

	public horizontalCameraMovement: boolean = true;
	public advanceSettingArtworks: boolean = false;
	public advanceSettingSetting: boolean = false;
	public addLinksPublish: boolean = true;
	public lockCameraWhenDragArtwork: boolean = true;
	public lockCameraWhenDragOrdinaryObject: boolean = true;
	public dataHasChanges: boolean = false;
	public onSavingData: boolean = false;
	public onPublishData: boolean = false;
	public showAlignLimit: boolean = false;
	public previewMode: boolean = false;
	public editFrameMode: boolean = false;
	public blockUserAction: boolean = false;
	public animationSequence: boolean = false;
	public onInput: boolean = false;
	public validateExhibitionDateOnFirstInit: boolean = false;
	public activeTab: string = 'images';
	public gravity: number = -0.05;
	public glowEffect:any;
	public highlightLayer: any;
	public pointerMesh:any;
	public turnOffGravityTimeout:any;
	public onTheStairs:boolean = false;
	public allShadowArtworkHasInitialized: boolean = false;
	public allDonutArtworkHasInitialized: boolean = false;
	public allAssetsHasLoaded = false;
	public exhibitionTimerHasChanges: boolean = false;
	public ctrlPressed: boolean = false;
	public limitUploadSize: number = 10000000;
	public onFocusedArtwork: boolean = false;
	public isMultiSelectMode: boolean = false;
	public displaySelectOverlappingTexts: boolean = false;
	public selectMultiOverlappingTexts: boolean = false;
	public showNotSubscribePopup: boolean = false;

	public widthDisplay: number = 0;
	public showOverlayDisplay: boolean = false;
	public deviceScreen: string = 'desktop';
	public browserUsed: any;

	public donutAreaMeshes:any = [];
	public stairsMeshes:any = [];
	public dragAreaMeshes:any = [];
	public colisionInvisibleMeshes:any = [];
	private _detectFPSDown: boolean = false;
	private _FPS: number = 0;
	public firstLoadScene: boolean = true;
	private _onOptimizingScene: boolean = false;
	public optimizer:any;
	private _focusOnCanvas: boolean = false;
	
	//#endregion Properties

	constructor(
		public mainService: MainService,
		public http: HttpClient,
		private _artworkShadowsService: ArtworkShadowsService,
		private _artworkLoaderService: ArtworkLoaderService,
		private _messageService: AlertMessageService,
		private _utilsService: UtilsService,
		private _loadingGalleryService: LoadingGalleryService,
		private _undoRedoService: UndoRedoService,
		private _textToSpeechService: TextToSpeechService,
		private _splashScreenService: SplashScreenService,
		private _textLoader: TextLoaderService
	){}


	/**
	 * * ============================================================================================== *
	 * * SETUP LIGHTING FUNCTIONS
	 * * ============================================================================================== *
	 * - SETUP BASIC LIGHTING
	 * - SET AMBIENT COLOR EXHIBITION
	 * - SET OBJECT LIGHTING
	 */

	//#region

	/**
	 * * SETUP BASIC LIGHTING *
	 * Todo: to setup basic lighting
	 */
	setupBasicLigting(){
		this.artworkLighting = new BABYLON.HemisphericLight("mainLightArtwork", new BABYLON.Vector3(0, 0, 0), this.scene);
		this.artworkLighting.intensity = 1.85;
	}

	/**
	 * * SET AMBIENT COLOR EXHIBITION *
	 * Todo: to set ambient color of exhibition childred (meshes)
	 */
	setExhibitionLighting(){        
		this.activeExhibitionNode.getChildren().map((mesh: any) => {
			mesh.material.ambientColor = new BABYLON.Color3(
				this.exhibition.light_intensity, 
				this.exhibition.light_intensity, 
				this.exhibition.light_intensity
			);
			if(this.exhibition.config.folderName !== "FOUR-WALL") {
				mesh.material.environmentIntensity = this.exhibition.light_intensity * (this.exhibition.config.envIntensity / 2);
			}
		});
	}

	/**
	 * * SET OBJECT LIGHTING *
	 * Todo: to set object lighting for ordinary object & artwork object
	 */
	setObjectLighting(){
		this.ordinaryObjectsNodes.map((ordinaryObject: any) => {
			ordinaryObject.getChildren().map((mesh: any) => {
				mesh.material.environmentIntensity = this.exhibition.light_intensity_object / 2;
			});
		})
	}

	//#endregion

	/**
	 * * ============================================================================================== *
	 * * DONUT ARTWORK FUNCTIONS
	 * * ============================================================================================== *
	 * - CREATE ARTWORK DONUT
	 * - SET POSITION DONUT ARTWORK
	 */

	//#region

	/**
	 * * CREATE ARTWORK DONUT *
	 * Todo: create artwork donut mesh
	 */
	createArtworkDonut(artwork:any){
		if(!document.hidden){
			let donut:any = this.scene.getMeshByName("artworkDonut");
			if(!donut){
				// Create artwork donut material
				const donutMat = new BABYLON.StandardMaterial("donutMat",this.scene);
				const donutTexture = new BABYLON.Texture(environment.staticAssets+"images/other/rounded.png?t="+this.mainService.appVersion,this.scene);
				donutMat.emissiveTexture = donutTexture;
				donutMat.emissiveTexture.hasAlpha = true;
				donutMat.opacityTexture = donutTexture;
				donutMat.useAlphaFromDiffuseTexture = true;
				donutMat.disableLighting = true;
				donutMat.backFaceCulling = false;
				donutMat.alphaMode = BABYLON.Engine.ALPHA_ADD;
				donutMat.alpha = 0.5;
				// Creating Artwork donut
				donut = BABYLON.MeshBuilder.CreatePlane("artworkDonut",{ sideOrientation:BABYLON.Mesh.DEFAULTSIDE },this.scene);
				donut.cullingStrategy = BABYLON.AbstractMesh.CULLINGSTRATEGY_BOUNDINGSPHERE_ONLY;
				donut.scaling.x = this.exhibition.placeholder_size;
				donut.scaling.y = donut.scaling.x;
				donut.material = donutMat;
			}else{
				const donutTmp = donut.clone("artworkDonut")
				donutTmp.material = donut.material.clone();
				donut = donutTmp;
			}
			
			donut.id = `donut-${artwork.id}`;
			donut['artworkId'] = artwork.id;
			
			artwork['donut'] = donut;
			this.setPositionDonutArtwork(artwork);

			artwork['donutHasInitialized'] = true;
			
			const nonObjectArtwork = this.artworkNodes.filter(artworkNode => artworkNode['artworkType'] != 'figure-object');
			this.allDonutArtworkHasInitialized = nonObjectArtwork.length == this.artworkDonuts.length;
		}
		
	}

	/**
	 * * SET POSITION DONUT ARTWORK *
	 * Todo: to set position donut artwork 
	 */
	setPositionDonutArtwork(artworkNode){
		const donut = artworkNode['donut'];
		donut.position = artworkNode.position.clone();
		donut.rotation = artworkNode.rotation.clone();
		donut.translate(new BABYLON.Vector3(0, 0, 1.5), 1, BABYLON.Space.LOCAL);
		donut.setEnabled(false);

		this.artworkDonuts.push(donut)

		setTimeout(()=>{
			const hit:any = new BABYLON.Ray(
				donut.position.clone(), 
				new BABYLON.Vector3(0,-100,0), 
				10000
			).intersectsMeshes(this.donutAreaMeshes,true);	
			
			if (hit.length) {
				donut.position.y = hit[0].pickedPoint.y+0.1;
				let target = hit[0].getNormal(true,true);
				target.x += hit[0].pickedPoint.x;
				target.y += hit[0].pickedPoint.y;
				target.z += hit[0].pickedPoint.z;
				donut["onTheFloor"] = true;
				donut.lookAt(target)
			}else{
				donut["onTheFloor"] = false;
			}	

		},500)
	}

	//#endregion DONUT ARTWORK FUNCTIONS

	/**
	 * * ========================================================================================== *
	 * * PUBLISH EXHIBITION FUNCIONS
	 * * ========================================================================================== *
	 * - PUBLISH/UNPUBLISH EXHIBITION
	 * - MOVE SAVED DATA TO EXHIBITION DATA
	 * - PUBLISH ERROR MESSAGE
	 * - SEND PUBLISH REQUEST TO BACKEND
	 * - VALIDATE PUBLISH DATA
   */

	//#region

	/**
	 * * PUBLISH/UNPUBLISH EXHIBITION *
	 * Todo: to publish/unpublish exhibition 
	 */
	publishExhibition(publish: boolean, isUpdate: boolean = false){
		return new Promise((resolve:any,reject:any)=>{
			if(this.validatePublishData()){
				this.blockUserAction = true;
				if(isUpdate) {
					this.sendPublishRequest(true)
					.then(()=>resolve(null)).catch(()=>reject(null));
				}else{
					// Publish exhibition
					if(publish){
						this.mainService.validationProcess('publish').subscribe((res:any)=>{
							this.validationPublishExhibition().subscribe((res:any)=>{
								this.sendPublishRequest(true).then(()=>resolve(null)).catch(()=>reject(null));
							},err=>{
								this.publishErrorMessage(true,err)
								this.blockUserAction = false;
								reject(null);
							})
						},err=>{
							this.publishErrorMessage(true,err)
							this.blockUserAction = false;
							reject(null);
						})
					}

					// Unpublish exbibtion
					else{
						this.exhibition.started = null;
						this.exhibition.ended = null;
						this.sendPublishRequest(false).then(()=>resolve(null)).catch(()=>reject(null));
					}
				}
				
			}
		})
	}

	/**
	 * * MOVE SAVED DATA TO EXHIBITION DATA *
	 * Todo: to move saved data to exhibition data
	 */
	moveSavedDataToExhibitionData(){
		const saved_data = this.exhibition.saved_data;
		this.exhibition.started = saved_data.started;
		this.exhibition.ended = saved_data.ended;
		this.exhibition.unlimited_time = saved_data.unlimited_time;
		this.exhibition.countdown_timer = saved_data.countdown_timer;
	}

	/**
	 * * PUBLISH ERROR MESSAGE *
	 * Todo: to publish error message
	 */
	publishErrorMessage(publishAction,err){
		switch (err.error.statusCode) {
			case 401:
				this.mainService.expiredSesionPopup = true;
			break;
			case 403:
				this.showNotSubscribePopup = true;
				// this.alertMessageService.add({severity:"warn",summary:"Warning", detail: "Your current plan does not support public exhibitions. To access this feature, please upgrade your pricing plan."})		
			break;
			default:
				this._messageService.add({severity:"error", summary:"System Error", detail: `Something went wrong. Failed to ${publishAction ? 'publish':'unpublish'} the exhibition.`})
			break;
		}
	}

	/**
	 * * SEND PUBLISH REQUEST TO BACKEND *
	 * Todo: to send publish request to backend
	 */
	sendPublishRequest(publishAction: boolean) {
		return new Promise(async (resolve, reject)=>{
			await this.setupTextToSpeech();
			await this._splashScreenService.saveSplashScreen(this.exhibition);
			this._textLoader.regenerateTextWalls(this.exhibition.id).then(()=> {
				this.saveChanges().subscribe((res)=>{
					this.publishData(publishAction).subscribe((res)=>{
						this.updateExhibitionViewer().subscribe();
						this.exhibition.published = publishAction;
	
						if(publishAction) {
							// this.moveSavedDataToExhibitionData();
							this.updateLogActivity("Publish data");
							this._messageService.add({severity:'success', summary:'Success', detail:'The exhibition was published'});
						}else {
							this.updateLogActivity("Unpublish data");
							this._messageService.add({severity:'success', summary:'Success', detail:'The exhibition has been unpublished'});
						}
		
						this._undoRedoService.clear();
						this.dataHasChanges = false;
						this.onSavingData = false;
						this.blockUserAction = false;
						this.validateExhibitionDateOnFirstInit = false;

						this.exhibition.edited_description = false;
						this.artworks.map((artwork:any)=>{
							artwork.edited_description = false;
						});
						resolve(null);
		
					},err=>{
						this.publishErrorMessage(publishAction,err)
						this.blockUserAction = false;
						this.onSavingData = false;
						reject(null);
					})
				},err=>{
					this.publishErrorMessage(publishAction,err)
					this.blockUserAction = false;
					this.onSavingData = false;
					reject(null);
				})
			}).catch((err)=> {
				this.publishErrorMessage(publishAction,err)
				this.blockUserAction = false;
				this.onSavingData = false;
				reject(null);
			})
		})
	}

	/**
	 * * VALIDATE PUBLISH DATA *
	 * Todo: to validate publish data
	 */
	validatePublishData(){
		if(this.exhibition.name){
			if(this.publishDataValid) return true
			else{
				this.validateExhibitionDateOnFirstInit = true;
				if(this.activeTab != "publish") this.activeTab = "publish";
				this._messageService.add({severity:"warn", summary: "Warning", detail: "The Publish tab contains invalid data. Please update the data."});
				return false;
			}
		}else{
			this._messageService.add({severity:"warn", summary: "Warning", detail: "The exhibition name cannot be empty"})
			return false;
		}
	}


	//#endregion PUBLISH EXHIBITION FUNCIONS

	/**
	 * * ============================================================================================== * 
	 * * INITIALIZE BABYNON JS ENGINE/SCENE FUNCTIONS
	 * * ============================================================================================== * 
	 * - INIT ENGINE
	 * - CREATE SCENE
	 */

	//#region

	/**
	 * * INIT ENGINE *
	 * Todo: to initialize the babylon engine
	 */
	initEngine(canvas:any){
		return new BABYLON.Engine(canvas, true, { 
			preserveDrawingBuffer: true, 
			stencil: true,  
			disableWebGL2Support: false,
		});
  }

	/**
	 * * CREATE SCENE *
	 * Todo: create a scene of the exhibit and add creating additional object
	 */
	createScene(engine: any){
		// Init Scene
		const scene =  new BABYLON.Scene(engine)
		scene.gravity = new BABYLON.Vector3(0, -0.15, 0);
		scene.collisionsEnabled = true;
		scene.clearColor = BABYLON.Color4.FromHexString("#ffffff");
		scene.ambientColor = new BABYLON.Color3(1, 1, 1);

    // Create Mesh Detector
    const meshDetector = BABYLON.MeshBuilder.CreateSphere('detector',{diameter: 1},scene)
		meshDetector.isPickable = false;
		meshDetector.visibility = 0;
		meshDetector.checkCollisions = true;
		meshDetector.position.y = -100;

		const meshDetectorBox = BABYLON.MeshBuilder.CreateBox('detectorBox',{},scene);
		meshDetectorBox.isPickable = false;
		meshDetectorBox.visibility = 0;
		meshDetectorBox.position.y = -100;

		// Create scaling temp
		const scalingTmp = BABYLON.MeshBuilder.CreateBox('scalingTmp',{},scene);
		scalingTmp.edgesColor = new BABYLON.Color4(1,0.85,0.08,1);
		scalingTmp.edgesWidth = 0.5;
		scalingTmp.position.y = -100;

		const scalingMat = new BABYLON.StandardMaterial('scalingTmp', scene);
		scalingMat.alpha = 0.15;
		scalingMat.emissiveColor = new BABYLON.Color3(1,0.85,0.08);
		scalingMat.disableLighting = true;
		scalingTmp.material = scalingMat;

		// Create Transform Node Helper
		new BABYLON.TransformNode('nodeDetector', scene);
		new BABYLON.TransformNode('wrapCamera', scene);

    // Create Detector
		new BABYLON.FreeCamera('cameraDetector', BABYLON.Vector3.Zero(), scene);

		scene.metadata = {}

    return scene;
  }
	
	//#endregion INITIALIZE BABYNON JS ENGINE/SCENE FUNCTIONS

	/**
	 * * ============================================================================================== * 
	 * * EXHIBITION DATE FUNCTIONS
	 * * ============================================================================================== * 
	 * - SET NEW EXHIBITION DATE
	 * - VALIDATE EXHIBITION DATE
	 */

	//#region
	
	/**
	 * * SET NEW EXHIBITION DATE *
	 * Todo: to set new exhibition date
	 */
	setNewExhibitionDate(newDate: string, type: string){
		if(this.exhibition.unlimited_time){
			this.exhibition.ended = null;
			this.exhibition.started = null;
			this.exhibition.saved_data.ended = null;
			this.exhibition.saved_data.started = null;
			this.exhibition.saved_data.unlimited_time = true;
		} else {
			switch (type) {
				case "start": this.exhibition.started = newDate; break;
				case "end": this.exhibition.ended = newDate; break;
			}
			if(!this.exhibition.started) this.exhibition.started = moment().format("YYYY-MM-DD")
			if(!this.exhibition.ended) this.exhibition.ended = this.exhibition.started;
		}
	}

	/**
	 * * VALIDATE EXHIBITION DATE *
	 * Todo: for validating exhibition date 
	 */
	public startDateValid: boolean = true;
	public endDateValid: boolean = true;
	validateExhibitionDate(){
		const currentDate = moment(moment().format("YYYY-MM-DD"));

		// Create start date and end date moment object
		const startDate = moment(this.exhibition.started,"YYYY-MM-DD");
		const endDate = moment(this.exhibition.ended,"YYYY-MM-DD");

		// Check if the exhibition timer is valid or not
		this.startDateValid = startDate.isSameOrAfter(currentDate);
		this.endDateValid = endDate.isSameOrAfter(startDate)

		// Check whether the data in the publish tab is valid or not
		if(this.exhibition.unlimited_time) this.publishDataValid = true;
		else if(this.startDateValid && this.endDateValid)  this.publishDataValid = true;
		else this.publishDataValid = false;

		store.dispatch({type:"VALIDATE_DATA", validateData: new Date().getTime() });

	}
	
	//#endregion EXHIBITION DATE FUNCTIONS

	/**
	 * * ============================================================================================== *
	 * * SWITCH EXHIBITION FUNCTIONS
	 * * ============================================================================================== *
	 * * - SWITCH EXHIBITION
	 * * - LOAD SELECTED EXHIBITION
	 * * - GET COLOR CONFIG
	 * * - GET EXHIBITION NODE
	 * * - IS EXHIBIT HAS LOADED
	 * * - SHOW SELECTED EXHIBITION
	 * * - HIDE CURRENT EXHIBITION NODE
	 * * - CLEAR CURRENT EXHIBITION DATA
	 * * ============================================================================================== *
	 */

	//#region

	/**
	 * * SWITCH EXHIBITION *
	 * Todo: to switch the exhibition
	 * @param type : string
	 */
	public switchExhibition(type: string): Promise<void> {
		return new Promise((resolve, reject) => {
			this._clearCurrentExhibition();
			this._hideCurrentExhibition();
	
			const _enableLoading = (enable) => {
				this._loadingGalleryService.percent = 0;
				this._loadingGalleryService.show = enable;
				this._loadingGalleryService.backgroundType = enable? 'block':'none';
				this.blockUserAction = enable;
			}
	
			_enableLoading(true);
			this._loadSelectedExhibition(type).then(() => {
				_enableLoading(false);
				resolve();
			}).catch((error) => {
				_enableLoading(false);
				reject();
			});
		})
	}

	/**
   * * LOAD SELECTED EXHIBITION *
   * Todo: to load all nodes related to the selected exhibition
   * @returns Promise
   */
  private _loadSelectedExhibition(type: string): Promise<void> {
    return new Promise((resolve, reject) => {
      try {
        if (this._isExhibitHasLoaded(type)) {
          this._showSelectedExhibition(type);
          resolve();
        } else {
          this.loadExhibition().then(() => {
            resolve();
          });
        }
      } catch (error) {
			reject(error); 
      }
    })
  }

	/**
   * * GET COLOR CONFIG *
   * Todo: to get color config from exhibition node
   * @param exhibitionNode : BABYLON.TransformNode -> exhibition node
   * @returns : ExhibitionColor[]
   */
  private _getColorConfig(exhibitionNode: any): ExhibitionColor[] {
    return exhibitionNode['color_config'].map((color): ExhibitionColor => {
      return {
        color: color.color,
        label: color.label,
        used_original: color.used_original,
      }
    });
  }

  /**
   * * GET EXHIBITION NODE *
   * Todo: to get exhibition node by type
   * @param type : string
   * @returns : BABYLON.TransformNode
   */
  private _getExhibitionNode(type: string): any {
    return this.exhibitionNodes.find((exhibition) => {
      return exhibition['modelType'] === type;
    });
  }

  /**
   * * IS EXHIBIT HAS LOADED *
   * Todo: to check if the selected exhibition has been loaded before or not
   * @param type : string
   * @returns : boolean
   */
  private _isExhibitHasLoaded(type: string) : boolean {
    return this.exhibitionNodes.some((exhibition) => {
      return exhibition['modelType'] === type;
    });
  }

	/**
   * * SHOW SELECTED EXHIBITION *
   * Todo: to show all nodes related to the selected exhibition
   */
  private _showSelectedExhibition(type: string): void {
    const exhibitionNode = this._getExhibitionNode(type);
    exhibitionNode.getChildren().forEach((child) => {
      child.setEnabled(true);
      this._groupingMesh(child);
    });
    this.exhibition['color_config'] = this._getColorConfig(exhibitionNode);
    this.activeExhibitionNode = exhibitionNode;
		(this.scene.metadata as SceneMetadata).activeExhibtionUniquId = exhibitionNode.uniqueId;
  }

	/**
   * * HIDE CURRENT EXHIBITION NODE *
   * Todo: to hide all nodes related to the current exhibition
   */
  private _hideCurrentExhibition(): void {
    this.activeExhibitionNode?.getChildren().forEach((child) => {
      child.setEnabled(false);
    });
  }

	/**
   * * CLEAR CURRENT EXHIBITION DATA *
   * Todo: to clear all data related to the current exhibition
   */
  private _clearCurrentExhibition(): void {
    this.dragAreaMeshes = [];
    this.colisionInvisibleMeshes = [];
    this.stairsMeshes = [];
    this.donutAreaMeshes = [];
  }

	//#endregion 

	/**
	 * * ============================================================================================== * 
	 * * CORRECTING ARTWORK IMAGE POSITION FUNCTIONS SECTION
	 * * ============================================================================================== * 
	 * - GET ARTWORK THICKNESS
	 * - CORRECTING ARTWORK POSITION
	 */
	
	// #region

	/**
	 * * GET ARTWORK THICKNESS *
	 * Todo: to get artwork thickness
	 */
	getArtworkThickness(frame: any){
		const passeThickness = frame.passepartout.passepartout ? frame.passepartout.passepartout_depth : 0;
		const frameThickness = frame.frame.frame ? frame.frame.frame_depth : 0;
		let thickness = Math.max(passeThickness, frameThickness) + frame.back_frame.back_frame_depth;
		return thickness;
	}

	/**
	 * * CORRECTING ARTWORK POSITION *
	 * Todo: to correcting artwork position
	 */
	correctingArtworkPosition(artworkNode: any, oldArtworkFrame: any){
		const oldArtworkThickness = this.getArtworkThickness(oldArtworkFrame);
		const artworkThickness = artworkNode.scaling.z;
		let positionZ = artworkThickness > oldArtworkThickness ? (artworkThickness/2) - (oldArtworkThickness/2) : -((oldArtworkThickness/2) - (artworkThickness/2));
		artworkNode.translate(
			new BABYLON.Vector3(0, 0, positionZ) , 1/artworkThickness, BABYLON.Space.LOCAL
		);
	}

	// #endregion

	/**
	 * * ================================================================================================ *
	 * * VALIDATE EXHIBITION DATA FUNCTIONS
	 * * ================================================================================================ *
	 * - VALIDATE EXHIBITION DATA
	 * - SHOW VALIDATION EXHIBITION MESSAGE
	 */

	//#region

	/** 
	 * * VALIDATE EXHIBITION DATA *
	 * Todo: to validate exhibition data and set global property "exhibitionDataValid"
	 * @return boolean -> true if all data is valid, false if not. 
	*/
	public exhibitionDataValid: boolean = true;
	public validateExhibitionData(showMessages: boolean = false): boolean {
		this.exhibitionDataValid = (
			this.ordinaryObjectDataValid &&
			this.publishDataValid &&
			this.artworkDataValid &&
			this._textLoader.textDataValid
		)

		if (showMessages) this._showValidationExhibitionMessage(); 
		return this.exhibitionDataValid;
	}

	/**
	 * * SHOW VALIDATION EXHIBITION MESSAGE *
	 * Todo: to show validation exhibition message
	 */
	private _showValidationExhibitionMessage(): void {
		if(!this.exhibitionDataValid){
			const invalidMessages = messages.editor.global.invalidData;

			if (!this.artworkDataValid) {
				this._messageService.add({ 
					severity: "warn", 
					summary: "Warning", 
					detail: invalidMessages.artwork
				})
			}
			if (!this._textLoader.textDataValid) {
				this._messageService.add({ 
					severity: "warn", 
					summary: "Warning", 
					detail: invalidMessages.text
				})				
			}
			if (!this.publishDataValid) {
				this._messageService.add({ 
					severity: "warn", 
					summary: "Warning", 
					detail: invalidMessages.publish
				})				
			}
			if (!this.ordinaryObjectDataValid) {
				this._messageService.add({ 
					severity: "warn", 
					summary: "Warning", 
					detail: invalidMessages.objects
				})				
			}
		}

	}

	//#endregion
	
	
	/**
	 * * ================================================================================================ * 
	 * * UTILITIES FUNCTIONS SECTION
	 * * ================================================================================================ * 
	 * * - GET ARTWORK DATA
	 * * - GET POSITION IN FRONT OBJECT
	 * * - GET FOOTING POSITION
	 * * - CREATE RAYCAST
	 * * - GLOBAL VECTOR TO LOCAL
	 * * - REPOSITION NODE ON WALL
	 * * - SET ARTWORK/TEXT POSITION AND ROTATION ON WALL
	 * * ================================================================================================ * 
	 */

	//#region

	
	/**
	 * * GET ARTWORK DATA *
	 * Todo: to getting artwork data
	 * @param nodeId : ArtworkNode Id (BABYLON.TransformNode)
	 * @returns : Artwork
	 */
	public getArtworkData(nodeId: string): Artwork {
		const id = nodeId.replace('artwork-', '');
		const index = this.artworks.findIndex((artwork) => artwork.id === id);
		return this.artworks[index];
	}

	/**
	 * * GET POSITION IN FRONT OBJECT *
	 * Todo: get a position in front of the object
	 * @param node: BABYLON.TransformNode -> Container of TextWall, Artwork and OrdinaryObject.
	 * @return BABYLON.Vector3 -> Position in front of the object
	 */
	positionInFrontObject(node: any) {
    let translateZ = 0;
    const nodeWidth = node.scaling.x;
    const nodeHeight = node.scaling.y;
    const canvasWidth = this.canvas.width;
    const canvasHeight = this.canvas.width;

    const rasioArtwork = nodeHeight / nodeWidth;
    const rasioCanvas = canvasHeight / canvasWidth;

    if (rasioCanvas > rasioArtwork) {
      translateZ = nodeWidth * (rasioCanvas + 0.6);
    } else {
      translateZ = nodeHeight * 1.3;
    }

    if (translateZ < 1) translateZ = 1;

    const sizeArtwork = node.scaling.clone();
    const depth = sizeArtwork.z;
    translateZ += depth;

    const nodeDetector = this.scene.getTransformNodeByName('nodeDetector');
    nodeDetector.position = node.position.clone();
    nodeDetector.rotation = node.rotation.clone();
    nodeDetector.translate(new BABYLON.Vector3(0, 0, sizeArtwork.z + translateZ), 1, BABYLON.Space.LOCAL);
    const watch = nodeDetector.position.clone();
    nodeDetector.position.y = -100;

    return watch;
  }

	/**
	 * * REPOSITION NODE ON DRAG AREA *
	 * Todo: to reposition node on dray area
	 * @param node : BABYLON.TransformNode | BABYLON.Mesh
	 * @param ray : BABYLON.Ray
	 */
	public repositionNodeOnDragArea(node, ray, thickness = null) {
		const dragArea = ray.intersectsMeshes(this.dragAreaMeshes)[0];
		const { position, rotation } = this.getPositionOnWall(dragArea, thickness);
		node.position = position;
		node.rotation = rotation;
	}

	/**
	 * * IS ON DRAG AREA *
	 * Todo: to check if the ray is on drag area
	 * @param ray : BABYLON.Ray
	 * @returns : boolean
	 */
	public getDragArea(ray: any, area: 'wall' | 'all'): any {
		const meshes = area == 'all' ? this.activeExhibitionNode.getChildren() : this.dragAreaMeshes;
		const dragArea = ray.intersectsMeshes(meshes)[0];
		return dragArea
	}

	/**
	 * * SET ARTWORK/TEXT POSITION AND ROTATION ON WALL *
	 * Todo: to set artwork position and rotation on wall
	 */
	public getPositionOnWall(dragAreaHit: any, thickness: number | null = null) {
		const tempMesh = BABYLON.MeshBuilder.CreateBox("tempMesh", {}, this.scene);
		tempMesh.scaling.z = thickness ? thickness : 0.015;
		tempMesh.position = dragAreaHit.pickedPoint;

		// set direction 
		tempMesh.setDirection(dragAreaHit.getNormal(true, true));

		// recalculates the resulting rotation value of the "setDirection" function so that the value is 
		// always between -Math Phi and Math Phi
		tempMesh.rotation.y = this.recalulateRotationNode(tempMesh.rotation.y);
		tempMesh.rotation.x = this.recalulateRotationNode(tempMesh.rotation.x);
		tempMesh.rotation.z = this.recalulateRotationNode(tempMesh.rotation.z);

		tempMesh.translate(
			new BABYLON.Vector3(0, 0, tempMesh.scaling.z/2), 
			thickness ? 1/tempMesh.scaling.z : 0.015, 
			BABYLON.Space.LOCAL
		);

		const position = tempMesh.position.clone();
		const rotation = tempMesh.rotation.clone();
		tempMesh.dispose();

		return { position, rotation }
	}

	//#endregion
	

	// =================================================================================================================================== //
	// =================================================================================================================================== //
	// =================================================================================================================================== //
	
	//#region
	

	/**
     * * GET EXHIBITION SIZE *
     * Todo: for get exibition size
     */
	getExibitionSize(model_path){  
		return new Promise((resolve:any,reject:any)=>{
			this.http.post(environment.baseURL+"/exhibitions/model-path",{ model_path: model_path.replace(environment.exhibition_path,"") }).subscribe((res:any)=>{
				resolve(res.data.url_size)
			},err=>{
				reject(err)
			})
		})
    }

	

	/**
	 * * UPDATING LOG ACTIVITY *
	 * Todo : to updating log activity
	 * @param log: String
	 */
	updateLogActivity(log: string) {
		this.logActivity = log;
		this.updatedDataAt = moment().format("DD-MM-YYYY h:mmA")
	}

	/**
	 * * UPDATING LOG ACTIVITY  WITH DELAY *
	 * Todo : to updating log activity with delay
	 * @param log: String
	 */
	updateLogActivityWithDelay = debounce((log: string) => {
		this.updateLogActivity(log)
	}, 1000)


	/**
	 * * CREATE ALIGN LIMIT OBJECT(MESH) *
	 * Todo: for creating align limit object (top limit & bottom limit)
	 */
	createAlignLimit() {
		// create  align imit
		let limitMat = new BABYLON.StandardMaterial("limitMat", this.scene);
		let limitTexture = new BABYLON.Texture(environment.staticAssets+"images/other/align-limit.png?t="+this.mainService.appVersion, this.scene);
		limitTexture.uScale = 250;
		limitTexture.vScale = 250;
		limitTexture.hasAlpha = true;
		limitMat.emissiveTexture = limitTexture;
		limitMat.opacityTexture = limitTexture;
		limitMat.useAlphaFromDiffuseTexture = true;
		limitMat.disableLighting = true;

		// craete object top limit
		let topLimit = BABYLON.MeshBuilder.CreatePlane('topLimit', {
			height: 2000,
			width: 2000,
			sideOrientation: BABYLON.Mesh.DOUBLESIDE
		}, this.scene);
		topLimit.rotation.x = Math.PI / 2;
		topLimit.visibility = 0;
		topLimit.isPickable = false;
		topLimit.material = limitMat;
		topLimit.position.y = this.activeExhibitionNode['dimensions'].height;

		// craete object bottom limit
		let bottomLimit = BABYLON.MeshBuilder.CreatePlane('bottomLimit', {
			height: 2000,
			width: 2000,
			sideOrientation: BABYLON.Mesh.DOUBLESIDE
		}, this.scene);
		bottomLimit.rotation.x = Math.PI / 2;
		bottomLimit.visibility = 0;
		bottomLimit.isPickable = false;
		bottomLimit.material = limitMat;

		this.topLimit = topLimit;
		this.bottomLimit = bottomLimit;

		this.highlightLayer.addExcludedMesh(topLimit);
		this.highlightLayer.addExcludedMesh(bottomLimit);
	}

	/**
	 * * DELETE COVER IMAGE [API] *
	 * Todo : API for delete cover image from server
	 */
	deleteCoverImage() {
		return this.http.delete(`${environment.baseURL}/image/delete-cover-image/${this.exhibition.id}`)
	}


	/**
	 * * REPLACE ARTWOEK ASSETS [API] *
	 * Todo : API for replacing artwork assets
	 */
	replaceArtworkAsset(exhibitionId, figureId, data, type) {
		return this.http.post(`${environment.baseURL}/image/replace-file/${exhibitionId}/${figureId}?type=${type}`, data, {
			reportProgress: true,
			observe: 'events',
		}).pipe(map((event) => {
			switch (event.type) {
				case HttpEventType.UploadProgress:
					const progress = Math.round(100 * event.loaded / event.total);
					return {status: 'progress', data: progress};

				case HttpEventType.Response:
					return {status: 'response', data: event.body};

				default:
					return `Unhandled event: ${event.type}`;
			}
		}));
	}

	/**
	 * * UPLOAD COVER IMAGE [API] *
	 * Todo : API for upload cover image to serve
	 */
	uploadCoverImage(data) {
		return this.http.post(`${environment.baseURL}/image/upload-cover-image`, data)
	}

	/**
	 * * UPLOAD FILE TO SERVER [API] *
	 * Todo: API for uploading artkwork (glb,gltf,png,jpg or jpeg) to the server
	 * @param formData: FormData
	 * @param exibition_id: Number => id of active exhibition
	 */
	uploadFile(formData, exibition_id) {
		return this.http.post(`${environment.baseURL}/image/upload-file/${exibition_id}`, formData, {
			reportProgress: true,
			observe: 'events',
		}).pipe(map((event) => {
			switch (event.type) {
				case HttpEventType.UploadProgress:
					const progress = Math.round(100 * event.loaded / event.total);
					return {status: 'progress', data: progress};

				case HttpEventType.Response:
					return {status: 'response', data: event.body};

				default:
					return `Unhandled event: ${event.type}`;
			}
		}));
	}

	/**
	 * * UPLOAD FRAME TEXTURE TO SERVER [API] *
	 * Todo: Api for uploading frame/passepartout texture
	 * @param file: FormData
	 * @param type: String -> "passepartout" and "frame"
	 * @param frame_id: Number -> id of frame
	 */
	uploadTexture(file, type, frame_id) {
		return this.http.post(`${environment.baseURL}/image/upload-texture/${frame_id}?type=${type}`, file, {
			reportProgress: true,
			observe: 'events',
		}).pipe(map((event) => {
			switch (event.type) {
				case HttpEventType.UploadProgress:
				const progress = Math.round(100 * event.loaded / event.total);
				return {status: 'progress', data: progress};

				case HttpEventType.Response:
				return {status: 'response', data: event.body};

				default:
				return `Unhandled event: ${event.type}`;
			}
		}));
	}

	/**
	 * * UPLOAD ORDINARY OBJECT FILE TO SERVER [API] *
	 * Todo: Api for uploading ordinay object to server
	 * @param formData: FormData
	 */
	uploadOrdinaryObject(formData) {
		return this.http.post(`${environment.baseURL}/image/upload-ordinary-object`, formData, {
			reportProgress: true,
			observe: 'events',
		}).pipe(map((event) => {
			switch (event.type) {
				case HttpEventType.UploadProgress:
				const progress = Math.round(100 * event.loaded / (event.total * 2));
				return {status: 'progress', data: progress};

				case HttpEventType.Response:
				return {status: 'response', data: event.body};

				default:
				return `Unhandled event: ${event.type}`;
			}
		}));
	}

	/**
	 * * INIT DRAG AND DROP FUNCTION *
	 * Todo: initialization drag and drop event
	 * @param dropArea : Document Object Model
	 * @param handles : Object -> interface { ondragenter, ondragleave, ondragover, ondrag }
	 */
	initDragAndDropFuction(dropArea, handles) {
		const events = ["dragenter", "dragleave", "dragover", "drop", "dragstart"];

		// set prevent default event
		events.map(event => {
			dropArea.addEventListener( event,(e) => {
				e.preventDefault()
				e.stopPropagation()
			},false)
		})

		// set handles
		events.map(event => dropArea.addEventListener(event, handles['on' + event], false))
	}

	/**
	 * * GET EXHIBIBITION DATA [API] *
	 * Todo: API for get exhibition data in the database
	 * @returns HTTP Observable
	 */
	getExhibition(id) {
		return this.http.get(`${environment.baseURL}/exhibition-detail/${id}`);
	}


	

	/**
	 * * UPDATE DIMENSON NEW ARTWORK [QUERY] *
	 * Todo: Query for to re-calculate artwork dimension when replace artwork
	 * @param id : Number -> Id ff Figure
	 * @param data : Object -> { height, width, realHeight, realWidth}
	 */
	updateDimension(id, data) {
		const query = `
			mutation MyMutation {
				update_figures(
					where: {id: {_eq: "${id}"}},
					_set: {
						real_height: ${data.realHeight},
						real_width:  ${data.realWidth},
						width:  ${data.width},
						height:  ${data.height},
						dimension: "${data.width} Cm x ${data.height}"
					}
				) {
					affected_rows
				}
			}
		`;

		return this.mainService.queryGraphql(query)
	}

	/**
	 * * GET IMAGE RESOLUTION *
	 * Todo : for geting resolution of image
	 * @param url: String -> path of image
	 */
	getImageResolutionFromUrl(url): Promise<{width: number, height: number}> {
		return new Promise((resolve, reject) => {
			try {
				let img = new Image();
				img.addEventListener("load", function () {
					resolve({width: this.naturalWidth, height: this.naturalHeight})
				});
				img.src = url;
			} catch (error) {
				reject(error)
			}
		})
	}


	/**
	 * * LOAD TEXTURE *
	 * Todo: to load babylon texture as promise
	 * @param textureSource 
	 * @param scene 
	 * @returns 
	 */
	loadTexture(textureSource, forSceneEnv:boolean = false, scene = null){
		return new Promise((resolve,reject)=>{
			scene = scene || this.scene;
      let texture:any = null;
      this.fetchTextureFile(textureSource).then((fileSource:any) => {
				try {
					if (forSceneEnv) {
						this._loadingGalleryService.label = "Loading room environment ...";
						texture = new BABYLON.CubeTexture(textureSource, scene);
					} else {
						this._loadingGalleryService.label = "Loading room light map ...";
						texture = new BABYLON.Texture(textureSource, scene);
					}

					texture.onLoadObservable.addOnce(()=> {
						resolve(texture)
					})
				} catch (error) {
					reject(error)
				}
      });
		})
	}

	 /**
  * * FETCH TEXTURE FILE *
  * Todo: fetch texture file before render for babylon
  */
	 private texturePercent = 0;
	 fetchTextureFile(url: string) {
		 return new Promise((resolve, reject)=>{
			 this.http.get(url, {
				 responseType: 'arraybuffer',
				 reportProgress: true,
				 observe: 'events',
			 }).subscribe({
				 next: (event) => {
					 if (event.type === HttpEventType.DownloadProgress) {
						 const loaded = event.loaded;
						 const total = event.total;
 
						 if (total) {
							 const percent =  Math.round(Math.round(loaded/total * 100) / 10);
							 if (this.texturePercent !== percent) {
								 this.texturePercent = percent;
								 if (this._loadingGalleryService.percent >= 90) this._loadingGalleryService.percent = 90 + this.texturePercent;
								 else if (this._loadingGalleryService.percent >= 80) this._loadingGalleryService.percent = 80 + this.texturePercent;
							 }
						 }
					 } else if (event instanceof HttpResponse) {
						 const arrayBuffer: ArrayBuffer | null = event.body;
						 if (arrayBuffer) {
							 const blob = new Blob([ arrayBuffer ]);
							 const blobUrl = URL.createObjectURL(blob);
							 resolve(blobUrl);
						 }
					 }
				 },
				 error: (err:any) => {
					reject(err)
				},
			 });
		 });
	 }

	/**
	 * * UNIT CONVERTER *
	 * Todo: for convert unit
	 */
	covertPxToCm(pixel) {
		return pixel * 0.0264583333
	}

	convertPxToM(px) {
		return px * 0.0002645833
	}


	/**
	 * * CHECK JSON VERSION *
	 * Todo: to check json version
	 */
	checkJsonVersion(shareString: string){
		return this.http.get(`${environment.baseURL}/users/check-json-version/${shareString}`)
	}

	/**
	 * * UPDATE JSON VERSION *
	 * Todo: to update json version
	 */
	updateJsonVersion(){
		return this.http.post(`${environment.baseURL}/users/json-version`, {})
	}

	/**
	 * * HANDLE WEBGL LOST CONTEXT *
	 * Todo: to handle webgl lost context
	 */
	handleWebGLLostContext(){
	const loseContext = this.engine._gl.getExtension('WEBGL_lose_context');
	this.engine.onContextLostObservable.add((e)=>{
		setTimeout(()=>{
			loseContext.restoreContext();
		},10)
	})
	
	this.engine.onContextRestoredObservable.add((e)=>{
		window.dispatchEvent( new Event("resize"))
	})
	}

	/**
	 * * RENDER SCENE *
	 * Todo: to render the active scene, but if previously there was an active scene, the scene will be stopped and replaced by a new scene
	 * @param scene : BABYLON.Scene
	 * @param engine : BABYLON.Engine
	 * @param oldScene : BABYLON.Scene
	 */
	renderScene(){
			if(this.scene) this.engine.stopRenderLoop();
			this.engine.runRenderLoop(()=>{
				this._FPS = this.engine.getFps().toFixed();

				if (this._FPS < 40 && this._detectFPSDown && !this._onOptimizingScene) {
					this.optimizeScene();
				}
				this.scene.render()
			});
			window.addEventListener("resize", () => {
				this.engine.resize()
			});
	}

	initScalingFigure():number{
		const artworkData = this.artworks.find((x)=> x.id == this.activeArtworkNode.id.replace('artwork-',''))
		const frameWidth = artworkData.frame.frame.frame_width_left + artworkData.frame.frame.frame_width_right;
		const passepartoutWidth = artworkData.frame.passepartout.passepartout_width_right + artworkData.frame.passepartout.passepartout_width_left;
		let	 minWidth = 0.05;
		if (artworkData.frame.frame.frame){
			minWidth += frameWidth;
		}
		if (artworkData.frame.passepartout.passepartout){
			minWidth += passepartoutWidth;
		}
		return minWidth
	}

	/**
	 * * DETECT FPS *
	 * Todo: to detect if the FPS is below 30, then the scene will be optimized
	 */
	public handleFPSDown() {
		if (this.firstLoadScene) this._enableDetectFPSDown();

		document.addEventListener('visibilitychange', () => {
			this.firstLoadScene = false;
			if (!document.hidden) {
				this._enableDetectFPSDown();
			} else  {
				this._enableDetectFPSDown.cancel();
				this._detectFPSDown = false;
			}
		});
	}

	private _enableDetectFPSDown = debounce(() => {
    this._detectFPSDown = true;
		if (this.optimizer) this.optimizer.dispose();
		this.optimizer = null;
		this.optimizeScene();
  }, 5000);
















	


	/**
	 * * RECALCULATE ROTATION NODE *
	 * Todo: recalculates the resulting rotation value of the "setDirection" function so that the value is always between -Math Phi and Math Phi
	 */
	recalulateRotationNode(rotationValue:any){
		while(!(rotationValue <= Math.PI && rotationValue >= -Math.PI)){
            if(rotationValue > Math.PI) {
                rotationValue -= Math.PI*2
            }
            if(rotationValue < -Math.PI){
                rotationValue -= Math.PI*2 
            }
        }
        return rotationValue;
	}

	/**
	 * * UPDATE FIGURE DATA *
	 * Todo: for updating old figure data to new figure data
	 */
	updateArtworkData(artworkNode, artworkData: Artwork, updateState = true, updateUndoRedoStateWithDelay = false) {
		if(!this.transformValuesIsSame(artworkNode, artworkData)) {
			artworkData.position.position_x = artworkNode.position.x;
			artworkData.position.position_y = artworkNode.position.y;
			artworkData.position.position_z = artworkNode.position.z;
			artworkData.rotation.rotation_x = artworkNode.rotation.x;
			artworkData.rotation.rotation_y = artworkNode.rotation.y;
			artworkData.rotation.rotation_z = artworkNode.rotation.z;
			artworkData.scaling.scaling_x = artworkNode.scaling.x;
			artworkData.scaling.scaling_y = artworkNode.scaling.y;
			artworkData.scaling.scaling_z = artworkNode.scaling.z;
	
			if(artworkData.file_type != 'figure-object') {
				this._artworkShadowsService.setShadowCastPosition(artworkNode);
				this._artworkShadowsService.setShadowComponentLightsPosition(artworkNode);
				this.setPositionDonutArtwork(artworkNode);
			}
			
			if(updateState){
				store.dispatch({type: "UPDATE_OBJECT_DATA", updateObjectData: new Date().getTime()});
				if (updateUndoRedoStateWithDelay) this.updateUndoRedoStateWithDelay();
				else this.updateUndoRedoState();
			}
		}
	}

	transformValuesIsSame(artworkNode, artworkData: Artwork){
		const position = artworkNode.position;
		const rotation = artworkNode.rotation;
		const scaling = artworkNode.scaling;

		return (
			position.x == artworkData.position.position_x &&
			position.y == artworkData.position.position_y &&
			position.z == artworkData.position.position_z &&
			rotation.x == artworkData.rotation.rotation_x &&
			rotation.y == artworkData.rotation.rotation_y &&
			rotation.z == artworkData.rotation.rotation_z &&
			scaling.x == artworkData.scaling.scaling_x &&
			scaling.y == artworkData.scaling.scaling_y &&
			scaling.z == artworkData.scaling.scaling_z
		)
	}

	/**
	 * * CHANGE HEIGHT CAMERA *
	 * Todo: for adjust camera height
	 */
	changeHeightCamera() {
		this.camera.height_camera = this.mainCameraNode.ellipsoid.y;
		this.camera.position = {
			position_x: this.mainCameraNode.position.x,
			position_y: this.mainCameraNode.position.y,
			position_z: this.mainCameraNode.position.z
		},

		this.dataHasChanges = true;
	}

	/**
	 * * UPDATE TEXT DATA *
	 * Todo: for updating old text data to new text data
	 */
	updateTextData(textMesh, textData, updateUndoRedoStateWithDelay = false) {
		textData.position.position_x = textMesh.position.x;
		textData.position.position_y = textMesh.position.y;
		textData.position.position_z = textMesh.position.z;
		textData.rotation.rotation_x = textMesh.rotation.x;
		textData.rotation.rotation_y = textMesh.rotation.y;
		textData.rotation.rotation_z = textMesh.rotation.z;
		store.dispatch({type: "UPDATE_OBJECT_DATA", updateObjectData: new Date().getTime()});
		if (updateUndoRedoStateWithDelay) this.updateUndoRedoStateWithDelay();
		else this.updateUndoRedoState();
	}

	/**
	 * * UPDATE ORDINARY OBJECT DATA *
	 * Todo: for updating old ordinary object data to new ordinary object data
	 */
	updateOrdinaryObjectData(objectMesh, objectData) {
		objectData.position.position_x = objectMesh.position.x;
		objectData.position.position_y = objectMesh.position.y;
		objectData.position.position_z = objectMesh.position.z;
		objectData.rotation.rotation_x = objectMesh.rotation.x;
		objectData.rotation.rotation_y = objectMesh.rotation.y;
		objectData.rotation.rotation_z = objectMesh.rotation.z;
		objectData.scaling.scaling_x = objectMesh.scaling.x;
		objectData.scaling.scaling_y = objectMesh.scaling.y;
		objectData.scaling.scaling_z = objectMesh.scaling.z;
		store.dispatch({type: "UPDATE_OBJECT_DATA", updateObjectData: new Date().getTime()});
		this.updateUndoRedoState();
	}

	/**
	 * * VALIDATE SLIDER MANUAL INPUT *
	 * Todo: to validate slider manual input
	 */
	validateSliderManualInput(value,min,max){
		// if the input value is not empty and is of type number
		if(value!=null&&typeof value == "number"){
			// Validate the input value based on the maximum and minimum values.
			// if the input value is more than the maximum value that has been determined, 
			// then the input value will be filled with the maximum value, and vice versa
			if(value > max ) value = max;
			if(value < min ) value = min;
		}
		
		// if the input value is empty or the data type is not number, 
		// then the input value will be filled with the minimum value
		else if (min < 0&&min >= -180) {
			if(typeof value != "number") value = 0;
		} else {
			value = min;
		}

		return value;
	}


	/**
	 * * SAVE EXHIBITION DATA CHANGES [API] *
	 * Todo: API for save all changes to exhibition-related data such as cameras,artworks,text wall and object also exhibition itself
	 */
	saveChanges() {
		this.exhibition.name = this.exhibition.name.trim();
		// Crete body API
		const body = {
			exhibition: this._convertDataToBase64("exhibition"),
			figures: this._convertDataToBase64("artworks"),
			text_walls: this._convertDataToBase64("texts"),
			objects: this._convertDataToBase64("ordinaryObjects"),
		}

		return this.http.post(`${environment.baseURL}/exhibition/save-changes`, body)
	}
	

	/**
	 * ANCHOR Convert Data To Base64
	 * @description: to convert data to base64
	 * @param dataFrom : "artworks" | "texts" | "exhibition" | "ordinaryObjects"
	 */
	private _convertDataToBase64(dataFrom: "artworks" | "texts" | "exhibition" | "ordinaryObjects"){
		switch (dataFrom) {
			case "texts":
				let clonedTexts = this.cloneAndSortingData(this._textLoader.texts);
				return Base64.encode(JSON.stringify(clonedTexts));

			case "ordinaryObjects": 
				let clonedOrdinaryObjects = this.cloneAndSortingData(this.ordinaryObjects);
				clonedOrdinaryObjects = clonedOrdinaryObjects.map((ordinaryObject: any) => {
					const splited = ordinaryObject.model_path.split('/');
					ordinaryObject.model_path = splited.slice(splited.length - 3,splited.length).join('/');
					return ordinaryObject;
				})
				return Base64.encode(JSON.stringify(clonedOrdinaryObjects));

			case "artworks":
				let clonedArtworksData = this.cloneAndSortingData(this.artworks);
				clonedArtworksData = clonedArtworksData.map((artwork: any) => {
					const frameData = artwork.frame.frame;
					const passeData = artwork.frame.passepartout;

					delete artwork.edited_description;
					delete passeData.passepartout_texture_url;
					delete frameData.frame_texture_url;
					return artwork
				})

				return Base64.encode(JSON.stringify(clonedArtworksData));

			case "exhibition": 
				const clonedExhibitionData = cloneDeep(this.exhibition);
				clonedExhibitionData['camera'] = cloneDeep(this.camera);
				if (clonedExhibitionData.unlimited_time) clonedExhibitionData.ended = null;

				delete clonedExhibitionData['edited_description'];
				delete clonedExhibitionData['enableSplashScreen'];
				return Base64.encode(JSON.stringify(clonedExhibitionData));
		}
	}

	/**
	 * * CLONE AND SORTING DATA *
	 * Todo: to clone and sorting data
	 */
	cloneAndSortingData(data: any){
		const clonedTexts = cloneDeep(data);
		return sortBy(clonedTexts, ['id']);
	}

	/**
	 * * PUBLISH EXHIBITION DATA [API] *
	 * Todo: API to publish exibition data
	 * @param status: boolean
	 */
	publishData(status) {
		const body = {
			exhibition_id: this.exhibition.id,
		}
		return this.http.post(`${environment.baseURL}/exhibition-publish?publish=${status}`, body)
	}

	/**
	 * * DELETE EXHIBITION [API] *
	 * Todo: API to delete the exhibition data and all data associated with it
	 * @param id : Number -> Id of Exhibtion
	 */
	deleteExhibition(id) {
		return this.http.delete(environment.baseURL + "/delete-exhibition/" + id)
	}

	/**
	 * * UPDATE EXHIBITION VIEWER *
	 * Todo: to update exhibition json viewer
	 */
	updateExhibitionViewer(){
		return this.http.post(`${environment.baseURL}/exhibitions/update-viewer-json/${this.exhibition.share_string}`,{});
	}

	/**
	 * * TAKE SCREENSHOT *
	 * Todo: for take screenshot of scene
	 * @param camera : BABYLON.FreeCamera
	 * @param engine : BABYLON.Engine
	 * @param canvas : BABYLON.Canvas
	 */
	takeScreenshot(camera, engine, canvas, width = 6000) {
		return new Promise((resolve, reject) => {
			const ratioXY = canvas.innerHeight / canvas.innerWidth;
			let height = width * ratioXY;
			BABYLON.Tools.CreateScreenshotUsingRenderTarget(
				engine,
				camera,
				{ width, height },
				(data) => {
					resolve(data)
				}
			)
		})
	}

	/**
	 * * DOWNLOAD FILE FROM BASE64 *
	 * Todo: for downloading file from base64
	 * @param base64 : String
	 * @param fileName : String
	 */
	downloadFileFromBase64(base64, fileName) {
		var a = document.createElement("a"); //Create <a>
		a.href = base64; //Image Base64 Goes here
		a.download = fileName; //File name Here
		a.click();
	}

	/**
	 * * VALIDATION PUBLISH EXHIBITION [API] *
	 * Todo: API to validaion publish exhibition
	 */
	validationPublishExhibition() {
		return this.http.get(`${environment.baseURL}/validation-publish-exhibition`)
	}


	/**
	 * * SET REQUEST URL *
	 * Todo: for set request via url
	*/
	setRequestUrl(reqArtwork){
		let url: string = "";
		if(reqArtwork.request_via_link&&reqArtwork.request_link_value){
			const link = reqArtwork.request_link_value;
			url = (link.includes("https://"||link.includes("http://"))) ? link : "https://"+link
		}else if(reqArtwork.request_via_email&&reqArtwork.request_email_value) {
			url = `mailto:${reqArtwork.request_email_value}`
		}
		else url = null		
		return url
	}

	/**
	 * * GET FRAME TEMPLATE *
	 * Todo: to get frame template
	 */
	getFrameTemplate(refetch: boolean = false) {
		return this.http.get(this.mainService.fetchDataFromApi({
			host: "image/list-template-frame",
			refetch: refetch
		}))
	}
	
	/**
	 * * SAVE FRAME TEMPLATE *
	 * Todo: save frame template
	 */
	saveFrameTemplate(data:any) {

		const body = new FormData();
		body.append("frame_name", data.frameName);
		body.append("thumbnail", data.thumbnail);
		body.append("frame", this.covertObjectToString(data.frame));
		body.append("passepartout", this.covertObjectToString(data.passepartout));
		body.append("back_frame", this.covertObjectToString(data.backFrame));

		return this.http.post(`${environment.baseURL}/image/add-template-frame`,body)

	}
	
	/**
	 * * EDIT FRAME TEMPLATE *
	 * Todo: to edit frame template
	 */
	editFrameTemplate(data:any) {
		const body = new FormData();
		body.append("frame_name", data.frameName);
		body.append("thumbnail", data.thumbnail);
		body.append("frame", this.covertObjectToString(data.frame));
		body.append("passepartout", this.covertObjectToString(data.passepartout));
		body.append("back_frame", this.covertObjectToString(data.backFrame));
		body.append("template_frame_id", data.templateId);
		body.append("except_frame_id", data.exceptFrameId);

		return this.http.post(`${environment.baseURL}/image/edit-template-frame`,body)
	}

	/**
	 * * COVERT OBJECT TO  *
	 * @param object 
	 * @returns 
	 */
	covertObjectToString(object){
		return JSON.stringify(object).replace(/"/g,`\\"`)
	}

	/**
	 * * DELETE FRAME TEMPLATE *
	 * Todo: to delete frame template
	 */
	deleteFrameTemplate(data:any) {
		const options = {
			headers: new HttpHeaders({
				"accept": 'application/json',
			}),
			body : {
				frame_template_id : data.frameId,
				except_frame_id : data.exceptFrameId,
			}
		}

		return this.http.delete(`${environment.baseURL}/image/delete-template-frame`,options)
	}

	exportToBabylon(filename) {
		// Remove custom camera control (keyboard)
		this.mainCameraNode.inputs.remove(
			this.mainCameraNode.inputs.attached.keyboardRotate
		);

		// Add default camera control (keyboard)
		this.mainCameraNode.inputs.add(
			new BABYLON.FreeCameraKeyboardMoveInput()
		);

		this.pointerMesh.setEnabled(false);
		this.artworkNodes.map((artworkNode:any)=>{
			artworkNode['shadow']?.setEnabled(false);
			artworkNode['donut'].setEnabled(false);
		})

		const serializedScene = BABYLON.SceneSerializer.Serialize(this.scene);
		const strScene = JSON.stringify(serializedScene);
		if (filename.toLowerCase().lastIndexOf(".babylon") !== filename.length - 8 || filename.length < 9) {
		  filename += ".babylon";
		}
	  
		var blob = new Blob([strScene], { type: "octet/stream" });
	  
		// turn blob into an object URL; saved as a member, so can be cleaned out later
		const objectUrl = (window.webkitURL || window.URL).createObjectURL(blob);
	  
		const link = window.document.createElement("a");
		link.href = objectUrl;
		link.download = filename;
		const click = document.createEvent("MouseEvents");
		click.initEvent("click", true, false);
		link.dispatchEvent(click);

		this._addCameraControl();
		this.pointerMesh.setEnabled(true);
		this.artworkNodes.map((artworkNode:any)=>{
			artworkNode['shadow']?.setEnabled(true);
			artworkNode['donut']?.setEnabled(true);
		});
	}

	/**
	 * * EXPORT SCENE TO GLB *
	 * Todo: to exporting scene to glb file
	 * @param fileName 
	 * @param scene 
	 */
	exportToGLB(fileName){
		return  new Promise((resolve:any,reject:any)=>{
			const options:any = {
				shouldExportNode: function (node) {
					let canBeExported: boolean = true;
					const cantBeExported: any = [
						"detector",
						"detectorBox",
						"pointerMesh",
						"topLimit",
						"bottomLimit",
						"artworkDonut",
						"shadowArtwork",
						"passepartoutDimension",
						"frameDimension",
					]

					if(node.getClassName()=="TransformNode" && node.isEnabled() && node.name!="textWall"){
						canBeExported = true;
					}else if(node.getClassName()=="Mesh" && !cantBeExported.includes(node.name) && !node.name.includes("invisible")){
						canBeExported = true;
					}else{
						canBeExported = false;
					}

					return canBeExported
				},
			};

			BABYLON.GLTF2Export.GLBAsync(this.scene, fileName, options).then((glb) => {
				glb.downloadFiles();
				resolve(null);
			}).catch((err)=>{
				reject(err);
			});
		})
	}

	/**
     * * GET TIMEZONE LIST *
     * Todo: to get timezone list
     */
	getTimezoneList() {
		return this.http.get(`${environment.staticAssets}json/timezone.json?t=${this.mainService.appVersion}`);
    }

	/**
	 * * MERGED MESHES *
	 * Todo: merged meshes 
	 */
	mergedMeshes(meshes: any, name: string = ""){
		const mergedMeshes: any = BABYLON.Mesh.MergeMeshes(meshes, true, true, undefined, false, true);
		mergedMeshes.name = name;
		return mergedMeshes;
	}

	getMeshesToCheck(type: 'artwork' | 'text-wall' | 'ordinary-object') {
		let meshesToCheck = [];
		if (type === 'artwork' || type === 'text-wall') {
			meshesToCheck = this.dragAreaMeshes;
		}
		if (type === 'ordinary-object') meshesToCheck = this.activeExhibitionNode.getChildren();

		return meshesToCheck;
	}

	/**
	 * * GET POSITION FOR NEW ARTWORK *
	 * Todo: to get vector position for new artwork
	 */
	getInitialPositionAssets(type: 'artwork' | 'text-wall' | 'ordinary-object') {
	 	this.mainCameraNode.rotation.x = 0;
		const ray = this.mainCameraNode.getForwardRay(2);

		let meshesToCheck = this.getMeshesToCheck(type)		
		const intesectMesh = ray.intersectsMeshes(meshesToCheck)[0];
		if (intesectMesh) {
			if(type === 'ordinary-object') {
				return {
					position: intesectMesh.pickedPoint,
					rotation: BABYLON.Vector3.Zero()
				}
			} else {
				const artworkThickness = 0.060000000000000005;	
				return this.getPositionOnWall(intesectMesh, type == 'artwork' ? artworkThickness : null);
			}
		} else {
			// Getting position and rotation based on mesh in front of camera
			const mesh = this.scene.getMeshByName("_frontOfCamera").clone();
			mesh.setParent(null);
			const position = mesh.position.clone();
			const rotation = mesh.rotation.clone();
			mesh.dispose();

			return { position, rotation }
		}
	}

	 /**
   * * CREATE CAMERA BOTTOM RAYCAST *
   * Todo: to create camera bottom raycast
   */
	createCameraBottomRaycast(): void {
		const nodeDetector = this.scene.getTransformNodeByName('nodeDetector');
		const cameraHeight = this.camera.height_camera / (49.75124378109452/100);
		this.mainCameraNode['bottomRay'] = this._utilsService.createRaycast({ 
			node: nodeDetector, 
			direction: 'bottom', 
			length: cameraHeight + 0.1,
			scene: this.scene,
		});
	}

	/**
	 * * DETECT CAMERA ABOVE STAIRS *
	 * Todo: to detect the camera above the stairs
	 */
	detectCameraAboveStairs() {
		this.mainCameraNode['bottomRay'].origin = this.mainCameraNode.position.clone();
		const hit = this.mainCameraNode['bottomRay'].intersectsMeshes(this.stairsMeshes);
		if (hit.length > 0) {
			this.mainCameraNode['aboveFloor'] = true;
		} else {
			this.mainCameraNode['aboveFloor'] = false;
		}
	}












	



	// ============================================================================================================================================ //
	// ============================================================================================================================================ //
	// ============================================================================================================================================ //

	/**
	 * * ================================================================================================ *
	 *  SECTION Delete Exhibition Assets (Artwork, Object & Texts)
	 * * ================================================================================================ *
	 */
	//#region
	/**
	 * ANCHOR Delete Ordinary Object From Scene
	 * @description: to delete ordinary object in the scene
	 * @param id : Number -> id of ordinary object
	 */
	public deleteOrdinaryObjectFromScene(id) {
		this.unselectExhibitAsset();
		this.scene.getTransformNodeByID("ordinaryObject-" + id)?.setEnabled(false);
		this.ordinaryObjects.find(object => object.id == id).deleted = true;
		this.ordinaryObjects = this.ordinaryObjects.filter(object => object.id != id);
		this.ordinaryObjectsNodes = this.ordinaryObjectsNodes.filter((ordinaryObjectNode:any) => ordinaryObjectNode.isEnabled());
		this.updateUndoRedoState();
	}

	/**
	 * ANCHOR Delete Artwork From Scene
	 * @description: to delete a artwork in the scene
	 */
	public deleteArtworkFromScene(): void {
		const artworkId = this.activeArtwork.id;
		const artworkNode = this.activeArtworkNode;
		this.unselectExhibitAsset();
		
		const { file_type } = artworkNode.metadata.artworkData;
		if(file_type !== 'figure-object') {
			this.markPositionPlaceholderAsUnused(artworkId);
			this._artworkShadowsService.removeShadowCast(artworkNode);
			this._artworkShadowsService.removeShadowComponents(artworkNode);
			artworkNode['donut'].dispose();
			artworkNode.dispose();
			this.artworkDonuts = this.artworkDonuts.filter((donut:any)=>!donut.isDisposed())
			this.artworks = this._updateArtworkSequence(this.artworks);
		} else {
			artworkNode.setEnabled(false);
		}
		this.artworkNodes = this.artworkNodes.filter((artwork:any)=> !artwork.isDisposed() && artwork.isEnabled())
		this.artworks.find(x => x.id == artworkId).deleted = true;
		this.updateUndoRedoState();
	}

	/**
	 * ANCHOR Delete Multi Artwork From Scene
	 * @description: to delete multi artwork in the scene
	 */
	public deleteMultiArtworkFromScene(){
		const selectedExhibitAssets = this.selectedExhibitAssets;
		this.unselectMultiExhibitAssets();		
		selectedExhibitAssets.forEach((node: any) => {
			const artworkData = node.metadata.artworkData as Artwork;
			if(artworkData.file_type !== 'figure-object') {
				this._artworkShadowsService.removeShadowCast(node);
				this._artworkShadowsService.removeShadowComponents(node);
				node['donut']?.dispose();
				node.dispose();
				const artworkId = node.id.replace("artwork-", "");
				this.markPositionPlaceholderAsUnused(artworkId);
			} else {
				node.setEnabled(false)
			}
			artworkData.deleted = true;
		})

		this.artworkNodes = this.artworkNodes.filter((artwork:any)=>!artwork.isDisposed() && artwork.isEnabled())
		this.artworkDonuts = this.artworkDonuts.filter((donut:any)=>!donut.isDisposed())
		this.artworks = this._updateArtworkSequence(this.artworks);
		this.updateUndoRedoState();
	}

	/**
	 * ANCHOR Update Artwork Sequence
	 */
	private _updateArtworkSequence(data) {
		let artworks = data.filter(x => x.file_type != "ordinary-object").filter(x => !x.deleted);
		artworks = artworks.sort((a, b) => a['sequence'] > b['sequence'] ? 1 : a['sequence'] === b['sequence'] ? 0 : -1);
		for (let i = 0; i < artworks.length; i++) {
			artworks[i]['sequence'] = i + 1
		}

		return artworks;
	}

	/**
	 * ANCHOR Delete Text From Scene
	 * @description: to delete a text in the scene
	 * @param id : Number -> id of text
	 */
	public deleteTextFromScene(id) {
		this.scene.getTransformNodeById("textWall-" + id)?.dispose();
		this.unselectExhibitAsset();
		this._textLoader.texts.find(text => text.id == id).deleted = true;
		this._textLoader.textsNode = this._textLoader.textsNode.filter((textNode:any)=>!textNode.isDisposed());
		this.updateUndoRedoState();
	}

	/**
	 * ANCHOR Delete Multi Text From Scene
	 * @description: to delete multi text in the scene
	 * @param ids : String[] -> id of text
	 */
	public deleteMultiTextFromScene(ids: string[]){
		this.unselectMultiExhibitAssets();
		ids.forEach((id: string) => {
			this.scene.getTransformNodeByID("textWall-" + id)?.dispose();
			this._textLoader.texts.find(text => text.id == id).deleted = true;
		})
		this._textLoader.textsNode = this._textLoader.textsNode.filter((textNode:any)=>!textNode.isDisposed());
		this.updateUndoRedoState();
	}
	//#endregion
	//!SECTION

	/**
	 * * ================================================================================================ * 
	 *   SECTION Load Exhibition Functions
	 * * ================================================================================================ * 
	 */
	//#region

	/**
	 * ANCHOR Load Exhibition
	 * @description to load exhibition model to scene
	 * @returns : Promise<void>
	 */
	public loadExhibition() : Promise<void> {
    return new Promise(async (resolve,reject)=>{
			this._loadingGalleryService.label = "Loading room ...";
			// Get folderName
			const folderName = this.exhibition.model_path.split("/").slice(-2)[0];

			this.exhibition.model_path = this._getExhibitionPath('model');
			this.exhibition.light_map = this._getExhibitionPath('lightmap');
			const model_size = this._getModelSize();

			// handler function if process load model to scene successfully
			const onSuccess = async (meshes:any) => {
				try {
					// load lightmap texture 
					let lightMapTexture:any = await this.loadTexture(this.exhibition.light_map);

					// Create a container(Transform Node) for exhibition objects(meshes)
					let wrapExhibition = new BABYLON.TransformNode('room',this.scene);
					wrapExhibition.id = this.exhibition.id;
					wrapExhibition['modelType'] = this.exhibition.model_type ? this.exhibition.model_type : 'ori';
					
					// Setup scene color, lighting environtment & glow effect
					this._setupSceneColor();					
					this._setupGlowEffect();
					if(this.exhibition.config?.useEnv){
						await this._setupSceneEnvironment(folderName);
					}

					if (folderName === 'FOUR-WALL') meshes.push(this._setInfinityFloor(meshes));

					meshes.forEach((mesh:any) =>{
						if(mesh.name!="__root__"){
							this.artworkLighting.excludedMeshes.push(mesh);
							if(!mesh.name.toLowerCase().includes("environment")){
								mesh.id = wrapExhibition.id;
								mesh.isPickable = true;
								
								this._groupingMesh(mesh);
								this._setMeshCollision(mesh);
								this._hideInvisibleMesh(mesh);
								this._setAmbientColorExhibitionMesh(mesh);
								this._applyLightmapTexture(mesh, lightMapTexture);
								this._utilsService.excludedMeshHighlightLayer({
									mesh,
									highlightLayer: this.highlightLayer,
									isAdd: true,
								});
								if(this.glowEffect) this.glowEffect.addIncludedOnlyMesh(mesh)

								this._setFourWallCollision(folderName, mesh);
							
								mesh.setParent(wrapExhibition);
							}else{
								mesh.setParent(null);
								mesh.material.ambientColor = BABYLON.Color3.White();
							}
						}
					});

					// Remove lightmap from some mesh
					this._removeLigtmapForSomeMesh();

					// Remove empty node
					this.scene.getMeshByName("__root__").dispose();
	
					// Calulate room dimension & setup exhibition colors
					wrapExhibition['dimensions'] = this._utilsService.getNodeDimension(wrapExhibition);
					this._setupExhibtionColors(wrapExhibition)				

					this.activeExhibitionNode = wrapExhibition;
					this._addToExhibitionNodes(wrapExhibition);

					this._createPositionPlaceholderMeshes();
					this._createMeshesBlocker();
					
					this._loadingGalleryService.percent = 100;

					if (folderName === 'NEW-WOODEN-GALLERY') this.createMeshBlokerForNewHokaido();
					if (folderName === 'BALCONY-GALLERY') this.createMeshBlokerForBelfast();
					if (folderName === 'FOUR-WALL') {
						const mesh = meshes.find((mesh:any) => mesh.name === 'color_floor_infinity@Floor Color')
						mesh.material.ambientColor = BABYLON.Color3.FromHexString("#d6d6d6");
					}

					this._setSceneMetadataRelatedWithExhibion();
					this._loadingGalleryService.label = "";
					resolve(wrapExhibition);
				}
				catch (error) {
					reject(error)
				}
			}

			// handler function if process load model to scene still on progress
			const onProgress = (e:any) => {
				let percentage = Math.round(Math.round(e.loaded/model_size*100) / 1.25);
        if (percentage < 0) percentage = 0;
        this._loadingGalleryService.percent = percentage;
			}

			// handler function if process load model to scene failed
			const onError = (err)=>{
				reject(err);
			}
    
			// Import exhibition model to the scene
			const extention = this.exhibition.model_path.split(".").slice(-1)[0];
			BABYLON.SceneLoader.ImportMesh("","",this.exhibition.model_path,this.scene,onSuccess,onProgress,onError,`.${extention}`)      
		})		
	}

	/**
	 * ANCHOR Set Scene Metadata Related With Exhibition
	 * @description to set scene metadata related with exhibition
	 */
	private _setSceneMetadataRelatedWithExhibion() {
		if(this.scene.metadata.exhibitions && this.scene.metadata.exhibitions[this.activeExhibitionNode.uniqueId]) {
			this.scene.metadata['activeExhibtionUniquId'] = this.activeExhibitionNode.uniqueId;
		} else {
			this.scene.metadata.exhibitions = this.scene.metadata.exhibitions || {}
			this.scene.metadata.exhibitions[`${this.activeExhibitionNode.uniqueId}`] = {
				dragAreaMeshesUniqueIds: this.dragAreaMeshes.map((mesh: any) => mesh.uniqueId),
			}
			this.scene.metadata.activeExhibtionUniquId = this.activeExhibitionNode.uniqueId;
		}
	}

	/**
	 * ANCHOR Setup Scene Environment
	 * @description to setup scene environment
	 * @param folderName : string
	 * @returns Promise<any>
	 */
	private _setupSceneEnvironment(folderName: string): Promise<any>{
		return new Promise(async (resolve, reject)=>{
			try {
				if (!this.scene.environmentTexture) {
					if(this.exhibition.config?.useEnv){
						this.scene.environmentTexture = await this.loadTexture(`${environment.exhibition_path}/${folderName}/environment.env`,true)
						this.scene.environmentIntensity = 1;

						if(this.exhibition.config?.envRotationY) {
							this.scene.environmentTexture.rotationY = this.exhibition.config.envRotationY
						}
					}
				}
				resolve(null)
			} catch (error) {
				reject(error)
			}
		})
	}

	private _blockerAnimation: any = []
	createMeshBlokerForBelfast() {
    const data = [
			{
      	position: new BABYLON.Vector3(-2.57, 6, 11.1),
      	scaling: new BABYLON.Vector3( 0.095, 4, 20.5),
    	},
			{
      	position: new BABYLON.Vector3(2.5, 6, 21.4),
      	scaling: new BABYLON.Vector3(10, 4, 0.095),
    	},
		]

		data.forEach((x) => {
			const meshBloker = BABYLON.MeshBuilder.CreateBox('meshBlokerAnimation', {}, this.scene);
			meshBloker.position = x.position;
			meshBloker.scaling = x.scaling;
			meshBloker.visibility = 0;
			meshBloker.isPickable = false;
			this._blockerAnimation.push(meshBloker);
		})
  }

	/**
	 * ANCHOR Set Mesh Collision
	 * @description to set mesh collision
	 * @param mesh : BABYLON.Mesh
	 */
	private _setMeshCollision(mesh: any): void {
		if(['stairs_invisible', 'collision_invisible'].includes(mesh.name.toLowerCase())) {
			mesh.checkCollisions = true
		}
	}

	/**
	 * ANCHOR Hide Invisible Mesh
	 * @description to hide invisible mesh
	 * @param mesh : BABYLON.Mesh
	 */
	private _hideInvisibleMesh(mesh: any): void  {
		if(mesh.name.toLowerCase().includes("invisible")){
			mesh.visibility = 0;
			mesh.isPickable = false;
			mesh.material.lightmapTexture = null;
			if(mesh.name.toLowerCase().includes("donut_area") || mesh.name.toLowerCase().includes("stairs_invisible")){
				mesh.isPickable = true;
			}
		}
	}

	/*
	*ANCHOR CLONING FLOOR OF FOUR WALL EXHIBITION	
	*/
	private _setInfinityFloor(meshes:any[]=[]) : void {
		if (meshes && meshes.length > 0) {
			const mesh: any = meshes.find((mesh:any) => mesh.name.toLowerCase().includes('floor'));
			if (mesh) {
				const secondFloor = mesh.clone();
				secondFloor.name = 'color_floor_infinity@Floor Color'
				secondFloor.position.y = -0.002;
				secondFloor.scaling = new BABYLON.Vector3(50, 50, 50);

				const secondFloorMat = mesh.material.clone();
				secondFloor.material = secondFloorMat;
				return secondFloor
			}
		}
	}

	/*
	* ANCHOR SET COLLISION FOUR WALL
	*/
	private _setFourWallCollision(folderName: string, mesh:any) {
		if (folderName === "FOUR-WALL" && mesh.name.toLowerCase().includes('collision')) {
			mesh.position.y = -0.01;
			mesh.isPickable = true;
			return mesh;
		}
	}

	/**
	 * ANCHOR Grouping Mesh
	 * @description to grouping mesh
	 * @param mesh : BABYLON.Mesh
	 */
	private _groupingMesh(mesh: any): void {
		const mashName = mesh.name.toLowerCase();
		if(mashName.includes("donut_area")){
			this.donutAreaMeshes.push(mesh);
		}
		if(mesh.name.toLowerCase().includes("stairs_invisible")){
			this.stairsMeshes.push(mesh);
		}
		if(mashName.includes("drag_area")){
			this.dragAreaMeshes.push(mesh);
		}
		if(mashName.includes("collision_invisible")){
			this.colisionInvisibleMeshes.push(mesh);
		}

	}

	/**
	 * ANCHOR Apply Lightmap Texture
	 * @description to apply lightmap texture
	 * @param mesh : BABYLON.Mesh
	 * @param lightMapTexture : BABYLON.Texture
	 */
	private _applyLightmapTexture(mesh: any, lightMapTexture: any): void {
		if(this.exhibition.light_map){
			mesh.material.lightmapTexture = lightMapTexture;
			mesh.material.lightmapTexture.coordinatesIndex = this.exhibition.config.lightmapCoordinatesIndex;
			mesh.material.useLightmapAsShadowmap = true;
		}
	}

	/**
	 * ANCHOR Set Environment Intensity for Mesh of Exhibition
	 * @description to set environment intensity for mesh of exhibition
	 * @param mesh : BABYLON.Mesh
	 */
	private _setEnvIntensity(mesh: any): void {
		if (this.exhibition.config?.useEnv) {
			mesh.material.environmentIntensity = this.exhibition.config.envIntensity;
		}
	}

	/**
	 * ANCHOR Remove Lightmap Texture
	 * @description to remove lightmap texture for a mesh that belongs to a mesh without a lightmap, 
	 *              look at the configuration data in exhibition, there is a list of object names 
	 *              that lightmpa is not allowed to install
	 */
	private _removeLigtmapForSomeMesh(): void {
		if(this.exhibition.config?.noLightmapMeshes){
			this.exhibition.config.noLightmapMeshes.map((meshName:any)=>{
				const mesh = this.scene.getMeshByName(meshName);
				if(mesh) mesh.material.lightmapTexture = null;
			})
		}
	}

	/**
	 * ANCHOR Setup Exhibition Colors
	 * @description to setup exhibition colors
	 * @param wrapExhibition : BABYLON.TransformNode
	 */
	private _setupExhibtionColors(wrapExhibition: any): void {
		const colorsData = this._getColors(this.exhibition,wrapExhibition)
		wrapExhibition['color_config'] = colorsData.colorsWithMeshes;
		this.exhibition['color_config'] = colorsData.colors;
	}


	/**
	 * ANCHOR Get Exhibition Colors
	 * @param type : 'model' | 'lightmap' -> type of path
	 * @returns : string -> exhibition path
	 */
	private _getExhibitionPath(type: 'model' | 'lightmap'): string {
		const extension = this._getExtension(type);
		const path = type === 'model' ? this.exhibition.model_path : this.exhibition.light_map;
		const baseName = path.split("@")[0];
		return `${baseName}@${this.exhibition.model_type}.${extension}`;
	}

	/**
	 * ANCHOR Get Model Size
	 * @returns : number -> exhibition model size
	 */
	private _getModelSize(): number {
		const model_name = this.exhibition.model_path.split("/").slice(-1)[0].replace(".glb","");
		return this.exhibition.model_size_json[model_name];
	}

	/**
	 * ANCHOR Get Extension
	 * @description to get extension from exhibition path
	 * @param type : 'model' | 'lightmap' -> type of path
	 * @returns : string -> exhibition path extension
	 */
	private _getExtension(type: 'model' | 'lightmap'): string {
		const path = type === 'model' ? this.exhibition.model_path : this.exhibition.light_map;
		return path.split(".").slice(-1)[0];
	}

	/**
	 * ANCHOR Add Loaded Exhibition To Exhibition Nodes
	 * @description to add loaded exhibition to exhibition nodes
	 * @param exbibitionNode : BABYLON.TransformNode -> exhibition node
	 */
	private _addToExhibitionNodes(exbibitionNode: any): void {
		const inTheList = this.exhibitionNodes.find((node: any) => { 
			return node.modelType === exbibitionNode.modelType
		});
		if (!inTheList) this.exhibitionNodes.push(exbibitionNode);
	}

	/**
	 * ANCHOR Set Ambient Color Exhibition Mesh
	 * @description to set ambient color exhibition mesh
	 * @param mesh : BABYLON.Mesh -> exhibition mesh
	 */
	private _setAmbientColorExhibitionMesh(mesh: any): void {
		mesh.material.ambientColor = new BABYLON.Color3(
			this.exhibition.light_intensity, 
			this.exhibition.light_intensity, 
			this.exhibition.light_intensity
		);
		if(this.exhibition.config.folderName === 'FOUR-WALL') {
			mesh.material.environmentIntensity = 0;
		} else {
			mesh.material.environmentIntensity = this.exhibition.light_intensity * (this.exhibition.config.envIntensity / 2);

		}
	}

	/**
	 * ANCHOR Create Meshes Blocker
	 * @description to create meshes blocker
	 */
	private _createMeshesBlocker() {
		if (this.exhibition.config.meshesBlocker) {
			this.exhibition.config.meshesBlocker.forEach((x: MeshesBlocker) => {
				const box = BABYLON.MeshBuilder.CreateBox("meshBlocker", {}, this.scene);
				box.position = new BABYLON.Vector3(...x.position);
				box.scaling = new BABYLON.Vector3(...x.scaling);
				if(x.isInvisible) box.visibility	= 0;
				this._utilsService.excludedMeshHighlightLayer({
					mesh: box,
					highlightLayer: this.highlightLayer,
					isAdd: true,									
				});
			})
		}
	}

	/**
	 * ANCHOR Get Exhibition Colors
	 * @description get the color changeable mesh on the exhibition model
	 */
	private _getColors(exhibitionData,exhibitionMesh){
		// Get color meshes from model
		const colorMeshes = exhibitionMesh.getChildren().filter(child=>child.name.toLowerCase().includes("color"));
		// Get color data from database and covert it to an array
		let colorsData = exhibitionData.color_config || "[]";
		if(typeof colorsData == 'string') colorsData = JSON.parse(colorsData.replace(/'/g, '\"'));

		// Create Wrapper for colors (with meshes and without meshes)
		const colorsWithMeshes = [];
		const colors = []
		
		colorMeshes.map((x)=>{
			// Get label name from model name
			const label = this.mainService.toUpperFirstLetter(
				x.name.toLowerCase().split('@').slice(-1)[0].split('_')[0].split('.')[0]
			);

			x.material = x.material.clone()
			x['original_color'] = x.material.albedoColor.clone();
			
			const colorData = colorsData.find(x=>x.label==label)
			const i = colorsWithMeshes.findIndex((x) => x.label == label);

			if (i >= 0) {
				colorsWithMeshes[i].meshes.push(x);
			} else {
				colorsWithMeshes.push({
					label: label,
					color: (colorData) ? colorData.color : "#ffffff",
					meshes: [x],
					used_original: (colorData)? colorData.used_original : true
				});
			}
		})

		colorsWithMeshes.map(color=>{
			colors.push({
				label: color.label,
				used_original: color.used_original,
				color: color.color
			})
			
			if(!color.used_original){
				color.meshes.map(mesh=>mesh.material.albedoColor = BABYLON.Color3.FromHexString(color.color))
			}
		})
		return {
			colorsWithMeshes,
			colors
		};
	}

	/**
	 * ANCHOR Setup Scene Color
	 * @description to setup scene color
	 */
	private _setupSceneColor(){
		this.scene.clearColor = BABYLON.Color3.White();
		if(this.exhibition.config?.ambientSceneColor){
			this.scene.ambientColor = new BABYLON.Color3(
				this.exhibition.config?.ambientSceneColor, 
				this.exhibition.config?.ambientSceneColor, 
				this.exhibition.config?.ambientSceneColor
			);
		}else{
			this.scene.ambientColor = BABYLON.Color3.White();
		}
	}

	/**
	 * ANCHOR Setup Glow Effect
	 * @description to setup glow effect
	 */
	private _setupGlowEffect(){
		if(this.exhibition.config?.useGlowEffect && !this.glowEffect){
			this.glowEffect = new BABYLON.GlowLayer("glow", this.scene);
			this.glowEffect.intensity = this.exhibition.config.glowIntesity;
		}
	}

	

	//#endregion LOAD EXHIBITION FUNCTIONS
	//!SECTION

	/**
	 * * ================================================================================================ *
	 *   SECTION Register Main Keyboard Events Functions
	 * * ================================================================================================ *
	 */
	//#region

	private _keys: number[] = [
		65, // Key : "A"
		83, // key : "S"
		68, // Key : "D"
		87, // Key : "W"
		37, // Key : ArrowLeft
		40, // Key : ArrowBottom
		38, // Key : ArrowUP
		39, // Key : ArrowRight
		27  // Key : Escape
	];


	/**
	 * ANCHOR Register Main Keyboard Events
	 * @description : to register main keyboard events
	 */
	public registerMainKeyboardEvent(): void {
		document.addEventListener('keydown', this._handleKeyDownEvent)
		document.addEventListener('keyup', this._handleKeyUpEvent)
		this.canvas.addEventListener('blur', this._handleBlurCanvas);
		this.canvas.addEventListener('focus', this._handleFocusCanvas);
	}

	/**
	 * ANCHOR Handle Key Down Event
	 * @description : to handle key down event
	 * @param e : KeyboardEvent
	 */
	private _handleKeyDownEvent = (e: KeyboardEvent): void => {
		if(this._keys.includes(e.keyCode)){
			if (this.onFocusedArtwork || this.focusAnimationIsRun && this._focusOnCanvas) {
				setTimeout(() => {
					this.unfocusFromArtworkAnimation();
				}, 100);
			}
			else this._makeClimbupStairsMoreSmooth('keydown');
			
			this._cancelMovingCameraAnimation();
			
		}
		this.ctrlPressed = e.ctrlKey;
	}

	/**
	 * ANCHOR Handle Key Up Event
	 * @description : to handle key up event
	 * @param e : KeyboardEvent
	 */
	private _handleKeyUpEvent = (e: KeyboardEvent): void => {
		if(this._keys.includes(e.keyCode)) {
			this._makeClimbupStairsMoreSmooth('keyup');
		}

		this.ctrlPressed = e.ctrlKey;
	}
	
	/**
	 * ANCHOR Handle Blur Canvas Event
	 * @description : to handle key down event outside canvas
	 * @param e : KeyboardEvent
	 */
	private _handleBlurCanvas = (e: FocusEvent): void => {
		this._focusOnCanvas = false;
	}

	private _handleFocusCanvas = (e: FocusEvent): void => {
		this._focusOnCanvas = true;
	}

	/**
	 * ANCHOR Make Climbup Stairs More Smooth
	 * @description : to make climbup stairs more smooth
	 * @param event : 'keyup' | 'keydown'
	 */
	private _makeClimbupStairsMoreSmooth(event: 'keyup' | 'keydown') {
		if(event == 'keyup') {
			this.turnOffGravityTimeout = setTimeout(() => {
				this.scene.gravity.y = 0;
			}, 500)
		} else {
			clearTimeout(this.turnOffGravityTimeout);
			this.scene.gravity.y = this.gravity;
		}
	}

	//#endregion
	//!SECTION

	/**
	 * * ================================================================================================ *
	 *   SECTION 'Get Position in Front of Artwork Functions
	 * * ================================================================================================ *
	 */
	//#region

	/**
   * ANCHOR Get Position in Front of Artwork (Main Function)
   * @description to get position in front of artwork like a visitor in real life
   * @param artworkNode: BABYLON.TransformNode -> Container of artwork
   * @return BABYLON.Vector3 | null -> Position in front of the artwork.
   *         if return is null, it mean the gap too narrow
  */
  private async _positionInFrontArtwork(artworkNode: any) {
		const artworkContainer = this._utilsService.cloneArtworkContainer(artworkNode, this.scene);
    const translateVector = this._getTranslateVector(artworkContainer, artworkNode['artworkType']);
		const nodeDetector = this.scene.getTransformNodeByName('nodeDetector');
		this._setNodeDetectorPostion(artworkContainer, nodeDetector);

		nodeDetector.translate(translateVector, 1, BABYLON.Space.LOCAL);

		const yPosition = this._utilsService.getCameraYPosition({
			position: nodeDetector.position.clone(),
			camera: this.camera,
			scene: this.scene,
		});

    if(!yPosition) {
      artworkContainer.dispose();
      nodeDetector.position = -1000;
      return null;
    }
    nodeDetector.position.y = yPosition;

		const distanceToObject = await this._utilsService.getDistanceToFrontObject({
			watchPosition: nodeDetector.position.clone(),
			artworkContainer,
			scene: this.scene,
		});
		const distanceToWatchPosition = BABYLON.Vector3.Distance(artworkContainer.position, nodeDetector.position.clone());

    if(distanceToObject < distanceToWatchPosition) {
      translateVector.z = distanceToObject - 0.4;
      this._setNodeDetectorPostion(artworkContainer, nodeDetector);
      nodeDetector.translate(translateVector, 1, BABYLON.Space.LOCAL);
			const yPosition = await this._utilsService.getCameraYPosition({
				position: nodeDetector.position.clone(),
				camera: this.camera,
				scene: this.scene,
			});
      nodeDetector.position.y = yPosition;

      if(this._isGapTooNarrow(artworkContainer, nodeDetector)){
        artworkContainer.dispose();
        nodeDetector.position = -1000;
        return null;
      };
    }

		artworkContainer.dispose();
    const watchPosition = nodeDetector.position.clone();
    nodeDetector.position = -1000;

    return watchPosition;

  }

	 /**
   * ANCHOR Gap Too Narrow Detector
   * @description to detect if the gap between artwork and node detector too narrow
   * @param artworkContaier : BABYLON.Mesh -> Clone of artwork container
   * @param nodeDetector : BABYLON.TransformNode -> Node detector
   */
	 private _isGapTooNarrow(artworkContaier: any, nodeDetector: any) {
    const artworkPosition = artworkContaier.position.clone();
    const nodeDetectorPosition = nodeDetector.position.clone();
    artworkPosition.y = nodeDetectorPosition.y;

    const distance = BABYLON.Vector3.Distance(artworkPosition, nodeDetectorPosition);
    return distance < 0.8;
  }

	/**
   * ANCHOR Set Node Detector Position Same As Artwork Container
   * @description to set node detector position same as artwork container
   * @param artworkContainer : BABYLON.Mesh -> Clone of artwork container
   * @param nodeDetector : BABYLON.TransformNode -> Node detector
   * @returns : BABYLON.TransformNode
   */
  private _setNodeDetectorPostion(artworkContainer: any, nodeDetector: any): any {
    nodeDetector.position = artworkContainer.position.clone();
    nodeDetector.rotation = artworkContainer.rotation.clone();
  }

	/**
   * ANCHOR Get Translate Vector3
   * @description to get translate vector3
   * @param artworkContainer : BABYLON.Mesh -> Clone of artwork container
   * @returns : BABYLON.Vector3
   */
  private _getTranslateVector(artworkContainer: any, artworkType: string): any {
    const translateZ = this._getTranslateZ(artworkContainer);
    const translateVector = BABYLON.Vector3.Zero();
    translateVector.z = translateZ;
    if(artworkType == 'figure-object') translateVector.z -= 0.8;
    else translateVector.z += 0.8;
    return translateVector;
  }

	/**
   * ANCHOR Get Translate Z
   * @description to get translate Z
   * @param artworkContainer: BABYLON.Mesh -> Clone of artwork container
   * @returns Number -> Translate Z
   */
  private _getTranslateZ(artworkContainer: any): number {
    const artworkWidth = artworkContainer.scaling.x;
    const artworkHeight = artworkContainer.scaling.y;
    const canvasWidth = window.innerWidth;
    const canvasHeight = window.innerHeight;

    const rasioArtwork = artworkHeight / artworkWidth;
    const rasioCanvas = canvasHeight / canvasWidth;

    let translateZ = rasioCanvas > rasioArtwork ? artworkWidth * (rasioCanvas + 0.6) : artworkHeight * 1.3;
    if (translateZ < 1) translateZ = 1;

    const artworkThickness = artworkContainer.scaling.clone().z;
    translateZ += artworkThickness;

    return artworkThickness + translateZ;
  }

	//#endregion
	//!SECTION

	/**
	 * * ================================================================================================ *
	 *   SECTION 'Focus on Artwork' Animation Functions 
	 * * ================================================================================================ *
	 */
	//#region

	public focusAnimationIsRun: boolean = false;
	public focusAnimationObjects: unknown[] = [];
	public unfocusAnimatonIsRun: boolean = false;

	/**
	 * ANCHOR Focus on Artwork Animation
	 * @description to run focus on artwork animation
	 * @param artworkNode: BABYLON.TransformNode
	 * @returns Promise<void>
	 */
	public focusOnArtworkAnimation(artworkNode: any): Promise<void>{
    return new Promise(async (resolve, reject)=>{
			if(this.activeArtworkId !== artworkNode.id){
				try {
					this.resetCameraZoomArtwork();
					this._cancelMovingCameraAnimation();

					this.mainCameraNode.inputs.attached.mouse.detachControl();
					this.mainCameraNode.detachControl(this.canvas);

					this.focusAnimationIsRun = true;
					this.blockUserAction = true;
					this.scene.gravity.y = 0;

					this.scene.onPointerObservable.remove(this.observables['mainObservable'])
					this.observables['mainObservable'] = null;
					this.setHorizontalCameraMovementObs(false);

					const wrapCamera = this.scene.getTransformNodeByName('wrapCamera');

					const watchPosition = await this._positionInFrontArtwork(artworkNode);
					if (watchPosition) {
						// Get target rotation animation
						const endRotation = this._targetRotationAnimation({
							artwork: artworkNode,
							wrapCamera: wrapCamera,
							endPosition: watchPosition,
						});

						// Run animations
						const ease = this._utilsService.setEaseMode();
						const fovAnimation = BABYLON.Animation.CreateAndStartAnimation('fovAnimation', this.mainCameraNode, 'fov', 40, 100, this.mainCameraNode.fov, 0.8, 0, ease);
						const rotationAnimation = BABYLON.Animation.CreateAndStartAnimation('rotationAnimation', this.mainCameraNode, 'rotation', 40, 100, this.mainCameraNode.rotation, endRotation, 0, ease);
						const positionAnimation = BABYLON.Animation.CreateAndStartAnimation('positionAnimation', wrapCamera, 'position', 40, 100, wrapCamera.position, watchPosition, 0, ease, () => {
							this._handleWhenAnimationStops(artworkNode.id);

							const artworkContainer = this._utilsService.cloneArtworkContainer(artworkNode, this.scene);
							this.mainCameraNode.setTarget(artworkContainer.position);
							artworkContainer.dispose();
							
							this.onFocusedArtwork = true;
							this.setupCameraZoomArtwork(artworkNode);
							resolve(null);
						});

						// Assign all animations
						this.focusAnimationObjects = [
							rotationAnimation,
							fovAnimation,
							positionAnimation,
						];
					} else {
						artworkNode['focus'] = false;
						this.focusAnimationIsRun = false;
						this.blockUserAction = false;
						this.scene.gravity.y = this.gravity;
						this.initMainPointerObs();
						this.mainCameraNode.attachControl(this.canvas, true);

						this._messageService.add({ severity: 'warn', summary: 'Warning', detail: 'Unable to focus on artwork. The gap is too narrow!' });
						resolve(null);
					}
				} catch (error) {
					reject(error);
				}
			}else{
				resolve(null)
			}
		});
  }

	/**
	 * ANCHOR Handle When Animation Stops
	 * @description to handle when animation stops
	 * @param artworkId: string
   */
  private _handleWhenAnimationStops(artworkId: string = null): void {
    // Get camera and artwork node
    const nodeCamera = this.scene.getTransformNodeByName('wrapCamera');

    // Re-position camera
    this.mainCameraNode.rotation.z = 0;
    this.mainCameraNode.parent = null;
    this.mainCameraNode.position = nodeCamera.position.clone();
    this.mainCameraNode.rotation = nodeCamera.rotation.clone();
    this.mainCameraNode.rotation.z = 0;
    this.mainCameraNode.rotation.x = 0;

    this.initMainPointerObs();

    this.focusAnimationIsRun = false;
    this.activeArtworkId = artworkId;
    this.focusAnimationObjects = [];
		this.blockUserAction = false;

  }

	/**
	 * ANCHOR Get Target Rotation Animation
	 * @description to get target rotation animation
   * @param params: { 
	 * 	 artwork: BABYLON.TransformNode, 
	 *	 wrapCamera: BABYLON.TransformNode, 
	 *	 endPosition: BABYLON.Vector3 
	 * }
   * @return BABYLON.Vector3 -> Result of calcuation (Target Rotation)
   */
  private _targetRotationAnimation(params : { artwork: any, wrapCamera: any, endPosition: any }) {
    const { artwork, wrapCamera, endPosition } = params;

    const artworkContaier = this._utilsService.cloneArtworkContainer(artwork, this.scene);
		const cameraRotation = this.mainCameraNode.rotation.clone();
    
    wrapCamera.rotation = cameraRotation.clone();
		wrapCamera.rotation.z = 0;
    wrapCamera.rotation.x = 0;
    wrapCamera.position = this.mainCameraNode.position.clone();
    this.mainCameraNode.parent = wrapCamera;
    this.mainCameraNode.position = BABYLON.Vector3.Zero();
    this.mainCameraNode.rotation.y = 0;
    this.mainCameraNode.rotation.z = 0;

    const meshDetector = new BABYLON.Mesh('meshDetector', this.scene);
    const camera = this.scene.activeCamera;

    meshDetector.position = endPosition.clone();
    meshDetector.lookAt(artworkContaier.position);
    meshDetector.setParent(wrapCamera);
    const rotation = meshDetector.rotation.clone();
		rotation.x -= cameraRotation.x;

    const calcRotation = (axis: number) => {
      const posisiB = BABYLON.Angle.FromRadians(axis).degrees();
      const di360 = posisiB - (Math.floor(posisiB/360) * 360);
      const ratio = Math.floor(di360/180);
      const di180 = di360 - (ratio * 180);
      const degreeToRadian = BABYLON.Angle.FromDegrees(di180).radians();
      return ratio == 0 ? degreeToRadian : degreeToRadian - Math.PI;
    };

    const rotationTargetArtwork = camera.rotation.clone();
    rotationTargetArtwork.y += calcRotation(rotation.y);
    rotationTargetArtwork.x += calcRotation(rotation.x);
    
    meshDetector.parent = null;
    meshDetector.dispose();
    artworkContaier.dispose();

    return rotationTargetArtwork;
  }

	/**
	 * ANCHOR Unfocus From Artwork Animation
	 * @description to unfocus from artwork animation
	 */
	public unfocusFromArtworkAnimation() {
		if (this.onFocusedArtwork) this.resetCameraZoomArtwork();
		if (this.focusAnimationIsRun) this.cancelFocusOnArtworkAnimation();

		this.activeArtworkId = null;
    const tmpNode = BABYLON.MeshBuilder.CreateBox('tmpNode', {}, this.scene);
    tmpNode.position = this.mainCameraNode.position.clone();
    tmpNode.rotation.y = this.mainCameraNode.rotation.y;
		tmpNode.visibility = 0;
    tmpNode.isPickable = false;

    setTimeout(async () => {
      const ray = this._utilsService.createRaycast({ 
				node: tmpNode, 
				direction: 'backward',
				scene: this.scene
			});
			this._blockerAnimation.forEach((x) => x.isPickable = true)
      const { distance } = this.scene.pickWithRay(ray);
			this._blockerAnimation.forEach((x) => x.isPickable = false)

      let zTranslate = 0;
      if (distance - 0.4 >= 2) zTranslate = -2;
      else zTranslate = -(distance - 0.4);
      tmpNode.translate(
          new BABYLON.Vector3(0, 0, zTranslate ),
          1,
          BABYLON.Space.GLOBAL,
      );

      const endPosition = tmpNode.position.clone();
      endPosition.y = this._utilsService.getCameraYPosition({
				position: endPosition,
				camera: this.camera,
				scene: this.scene,
			});
      tmpNode.dispose();

      // Effect animation
      const totalFrame = Math.max(zTranslate * -1, 0.5)
			this.unfocusAnimatonIsRun = true;
			this.scene.onPointerObservable.remove(this.observables['mainObservable'])
			this.observables['mainObservable'] = null;
      if (this.showOverlayDisplay){
				switch (this.widthDisplay) {
					case 498: BABYLON.Animation.CreateAndStartAnimation('cameraFov', this.mainCameraNode, 'fov', 100, 10, this.mainCameraNode.fov, this.exhibition.mobile_fov, 0); break;
					case 809: BABYLON.Animation.CreateAndStartAnimation('cameraFov', this.mainCameraNode, 'fov', 100, 10, this.mainCameraNode.fov, this.exhibition.tablet_fov, 0); break;
				}
			} else {
				BABYLON.Animation.CreateAndStartAnimation('cameraFov', this.mainCameraNode, 'fov', 100, 10, this.mainCameraNode.fov, this.exhibition.desktop_fov, 0);
			}

			BABYLON.Animation.CreateAndStartAnimation('cameraRotateZ', this.mainCameraNode, 'rotation.x', 6, totalFrame, this.mainCameraNode.rotation.x, 0, 0);
      BABYLON.Animation.CreateAndStartAnimation('cameraPosition', this.mainCameraNode, 'position', 6, totalFrame, this.mainCameraNode.position, endPosition, 0, null, () => {
				this.unfocusAnimatonIsRun = false;
				this.canvas.blur();
        this.canvas.focus();
				this.initMainPointerObs();
				this.setHorizontalCameraMovementObs(this.exhibition.horizontal_view_movement)
			});
    }, 50);
	}

	/**
	 * ANCHOR Cancel Focus On Artwork Animation
	 * @description to cancel focus on artwork animation
	 */
  public cancelFocusOnArtworkAnimation() {
    this.focusAnimationObjects.map((animation: any) => animation.pause());
    this._handleWhenAnimationStops();
  }

	//#endregion
	// !SECTION

	/**
	 * * ================================================================================================ *
	 *   SECTION Load Text Wall Functions
	 * * ================================================================================================ *
	 */
	//#region

	/**
	 * ANCHOR Change Text Quality
	 * @description to change text quality
	 * @param quality : number -> ratio of quality
	 * @param textWallNode : BABYLON.TransformNode
	 */
	public changeTextQuality(quality: number, textWallNode: any): void {
		const advancedTexture = textWallNode['advancedTexture'];	
		const size = 1024 * quality;
		advancedTexture.scaleTo(size, size)
		const text = textWallNode.metadata.textData;
		const guiText = textWallNode['textGUI'];
		this._textLoader.configurationTextGUI(text, guiText, quality);	
	}

	//#endregion
	//!SECTION

	/**
	 * * ================================================================================================ *
	 *   SECTION Create/Load Artwork Functions
	 * * ================================================================================================ *
	 */
	//#region


	/**
	 * ANCHOR Set Artwork Mesh Lighting
	 * @description Set lighting for artwork mesh
	 * @param mesh : BABYLON.Mesh
	 */
	private _setArtworkMeshLighting(mesh, artwork: Artwork): void {
		this._artworkLoaderService.setLighting({
			artwork,
			mesh,
			light: this.artworkLighting
		})
	}


	/**
	 * ANCHOR Load Artwork Object
	 * @description to load artwork object
	 * @param artwork : Artwork
	 */
	public async loadArtworkObject(artwork: Artwork): Promise<any> {
		const artworkNode =  await this._artworkLoaderService.loadArtworkObject({
			artwork,
			scene: this.scene,
			light: this.artworkLighting,
			glowEffect: this.glowEffect,
			highlightLayer: this.highlightLayer,
			artworks: this.artworks
		})

		this.artworkNodes.push(artworkNode);
		this.loadedArtworks.push(artwork.id);
		return artworkNode;
	}

	/**
	 * ANCHOR Create Artwork Image/Video
	 * @description to creating artwork image or video
	 * @param artworkData: Artwork
	 * @param scene: BABYLON.Scene -> default: this.scene
	 * @param forEditFrame: boolean -> default: false
	 * @returns BABYLON.TransformNode
	 */
	public async createArtworkImageVideo(artwork: Artwork, scene: any = null, forEditFrame: boolean = false, useTemporaryMarker: boolean = true): Promise<any> {
		scene = scene || this.scene;
		const artworkNode =  await this._artworkLoaderService.createArtworkImageVideo({
			artwork,
			scene,
			forEditFrame,
			highlightLayer: this.highlightLayer,
			glowEffect: this.glowEffect,
			light: this.artworkLighting,
			artworks: this.artworks,
			useTemporaryMarker
		});

		if(!forEditFrame) {
			this.artworkNodes.push(artworkNode);
			this.loadedArtworks.push(artwork.id);
		}

		return artworkNode;
	};


	//#endregion
	//!SECTION

	/**
	 * * ================================================================================================ *
	 *   SECTION Pointer Effect Functions
	 * * ================================================================================================ *
	 */

	//#region

	/**
	 * ANCHOR Display Pointer On Donut Area
	 * @description to display pointer on donut area
	 * @param ray: BABYLON.Ray
	 */
	private _displayPointerOnDonutArea(ray: any) : void {
		this.pointerMesh.setEnabled(true);
		const hits = this.scene.multiPickWithRay(ray);
		hits.sort((a,b) => a.distance - b.distance)
		if(hits[0]) {
			const name = hits[0].pickedMesh.name.toLowerCase();
			if(name.includes("donut_area") || name.includes("stairs") || name.includes("floor")) this.pointerEffect(hits[0]);
			else this.pointerIntesectWithWall = true;
		}
	}

	/**
	 * ANCHOR Set Pointer Effect Transparency
	 * @description to set the pointer effect transparency
	 * @param ray : BABYLON.Ray
	 */
	private _setPointerEffectTransparency(ray: any) : void {
		const hitsArtworkDonut = ray.intersectsMeshes(this.artworkDonuts,true);
		if(!hitsArtworkDonut[0]){
			if(this.activeArtworkDonut){
				this.activeArtworkDonut.material.alpha = 0.5;
				this.activeArtworkDonut = null
			}
			this._displayPointerOnDonutArea(ray);
		}else{
			this.pointerMesh.setEnabled(false);
			if(this.activeArtworkDonut && this.activeArtworkDonut.id != hitsArtworkDonut[0].pickedMesh.id){
				this.activeArtworkDonut.material.alpha = 0.5;
			}
			this.activeArtworkDonut = hitsArtworkDonut[0].pickedMesh;
			this.activeArtworkDonut.material.alpha = 1;
		}
	}

	/**
	 * ANCHOR Create Pointer Mesh
	 * @description to create pointer mesh
	 */
	public createPointerMesh(): void {
		// Create Pointer Object
		this.pointerMesh = BABYLON.MeshBuilder.CreatePlane('pointerMesh', {size:1,sideOrientation:BABYLON.Mesh.DOUBLESIDE},this.scene);
		this.pointerMesh.material = this.createPointerMaterial(); 
		this.pointerMesh.position.y = -100;
		this.pointerMesh.isPickable = false;


		this.createPointerMeshRays();
	}

	/**
	 * ANCHOR Create Pointer Material
	 * @description to create pointer material
	 */
	createPointerMaterial(){
		let pointerMeshMaterial = new BABYLON.StandardMaterial('pointerMat',this.scene);
		let pointerMeshTexture = new BABYLON.Texture(environment.staticAssets+"images/other/rounded.png?t="+this.mainService.appVersion, this.scene);
		pointerMeshMaterial.emissiveTexture = pointerMeshTexture;
		pointerMeshMaterial.emissiveTexture.hasAlpha = true;
		pointerMeshMaterial.opacityTexture = pointerMeshTexture;
		pointerMeshMaterial.alphaMode = BABYLON.Engine.ALPHA_ADD;

		return pointerMeshMaterial;
	}

	/**
	 * ANCHOR Create Pointer Mesh Rays
	 * @description to create pointer mesh rays
	 */
	createPointerMeshRays(){
		const directions = [
			new BABYLON.Vector3(1,0,0),
			new BABYLON.Vector3(-1,0,0),
			new BABYLON.Vector3(0,0,-1),
			new BABYLON.Vector3(0,0,1),
			new BABYLON.Vector3(1,0,1),
			new BABYLON.Vector3(-1,0,1),
			new BABYLON.Vector3(1,0,-1),
			new BABYLON.Vector3(-1,0,-1),
		]

		this.pointerMesh['rays'] = directions.map((direction:any) => {
			return new BABYLON.Ray(BABYLON.Vector3.Zero(), direction, 1);
		});
	}

	/**
	 * ANCHOR Pointer Effect
	 * @description to display the pointer effect (white circle object) when the pointer moves
	 */
	pointerEffect(pickInfo:any){
		if(this.allAssetsHasLoaded){
			this.setPointerEffectPoisition(pickInfo);
			this.setPointerEffectSize(pickInfo);
			this.detectIntesectPointerMesh();
			this.animatingPointerEffect();
		}
	}

	/**
	 * ANCHOR Animating Pointer Effect
	 * @description to animating pointer effect
	 */
	private pointerEffectAnimation = null;
	animatingPointerEffect(){
		this.scene.stopAnimation(this.pointerMesh, 'pointerEffectAnimation');
		this.pointerEffectAnimation = BABYLON.Animation.CreateAndStartAnimation('pointerEffectAnimation', this.pointerMesh.material, 'alpha',7,10,this.pointerMesh.material.alpha,0,0,undefined,() => {
			this.pointerMesh.material.alpha = 0;
			this.pointerEffectAnimation = null;
		});
	}
	
	/**
	 * ANCHOR Set Pointer Effect Position
	 * @description to set pointer effect position
	 */
	setPointerEffectPoisition(pickInfo: any){
		this.pointerMesh.position = pickInfo.pickedPoint;
		this.pointerMesh.position.y += 0.05;
		this.pointerMesh.setDirection(pickInfo.getNormal(true,true));
	}

	/**
	 * ANCHOR Set Pointer Effect Size
	 * @description to set pointer effect size
	 */
	setPointerEffectSize(pickInfo: any){
		if ((pickInfo.distance/30)<0.4) {
			this.pointerMesh.scaling.x = 0.4;
			this.pointerMesh.scaling.y = 0.4;
		}else{
			this.pointerMesh.scaling.x = pickInfo.distance/30;
			this.pointerMesh.scaling.y = pickInfo.distance/30;
		}
	}

	/**
	 * ANCHOR Detect Intesect Pointer Mesh
	 * @description to detect intesect pointer mesh
	 */
	public pointerIntesectWithWall: boolean = false;
	detectIntesectPointerMesh(){
		let intersectCount = 0;
		this.pointerMesh['rays'].map((ray: any) => {
			ray.origin = this.pointerMesh.position;
			ray.length = this.pointerMesh.scaling.x/2;
			const hit = ray.intersectsMeshes(this.colisionInvisibleMeshes,true);
			if(hit.length > 0) intersectCount++;
		})
		
		if(intersectCount > 0) {
			this.pointerMesh.material.alpha = 0.2;
			this.pointerIntesectWithWall = true;
		}else{
			this.pointerIntesectWithWall = false;
			this.pointerMesh.material.alpha = 1;
		}
	}

	//#endregion
	// !SECTION

	/**
	 * * ================================================================================================ * 
	 *   SECTION Select/Unselect Multi Exhibit Assets Functions
	 * * ================================================================================================ *
	 */
	//#region

	/**
	 * ANCHOR Select Multi Exhibit Assets
	 * @description to select multi exhibit assets
	 * @param exhibitAsset: BABYLON.TransformNode -> Artwork Node, Text Wall Node or Ordinary Object Node
	 */
	public selectedExhibitAssets: any = []
	public selectMultiExhibitAssets(exhibitAsset: any, ray: any = null): void {
		if(this._isSameExhibitAsset(exhibitAsset)) {
			const overlapingTextWalls = ray && exhibitAsset.name == 'textWall' ? this._getOverlapingTextWall(ray) : null;
			if (overlapingTextWalls) {
				this._selectOverlapingTextWalls(overlapingTextWalls, true);
			} else {
				store.dispatch({type: "UPDATE_STAT_SELECT_OBJECT", objectHasSelected: false});
				const inList = this.selectedExhibitAssets.find((x) => x.id == exhibitAsset.id);
				if(inList){
					this.selectedExhibitAssets = this.selectedExhibitAssets.filter((x) => x.id != exhibitAsset.id);
					this.setInactiveExhibitAsset(exhibitAsset);
					const length = this.selectedExhibitAssets.length;
					if(length > 1){
						this.setActiveExhibitAsset(this.selectedExhibitAssets[length-1]);
					} else if (length == 1) {
						const activeArtworkNode = this.selectedExhibitAssets[0];
						this.unselectMultiExhibitAssets();
						this.selectExhibitAsset(activeArtworkNode);
					} else {
						this.unselectMultiExhibitAssets();
					}
				} else {
					this.selectedExhibitAssets.push(exhibitAsset);
					this.setActiveExhibitAsset(exhibitAsset);
				}
			}
		} else {
			this._messageService.add({ 
				severity: "warn", 
				summary: "Warning", 
				detail: messages.editor.global.differentExhibitAsset
			})
		}
	}

	/**
	 * ANCHOR Is Same Exibit Asset
	 * @description to check if the exhibit asset is the same with the selected exhibit asset
	 * @param exhibitAsset :`BABYLON.TransformNode` -> Artwork Node, Text Wall Node or Ordinary Object Node
	 * @returns boolean -> true if the exhibit asset is the same with the selected exhibit asset
	 */
	private _isSameExhibitAsset(exhibitAsset: any): boolean {
		if (this.selectedExhibitAssets.length == 0) return true;
		return this.selectedExhibitAssets[0]?.name == exhibitAsset?.name;
	}
 
	/**
	 * ANCHOR Unselect Multi Exhibit Assets
	 * @description to unselect multi exhibit assets
	 */
	public unselectMultiExhibitAssets(keepList: boolean = false): void {
		this.selectedExhibitAssets.map((node) => this.setInactiveExhibitAsset(node))
		this.hideAxis();
		this.mainCameraNode.attachControl(this.canvas, true);
		this.setHorizontalCameraMovementObs(this.exhibition.horizontal_view_movement);
		if(!keepList) this.selectedExhibitAssets = [];
		this.unselectExhibitAsset();

		store.dispatch({type: "UPDATE_STAT_SELECT_OBJECT", objectHasSelected: false});
	}
 
	/**
	 * ANCHOR Set Active Exhibit Asset
	 * @description to set active exhibit asset
	 * @param exhibitAsset: BABYLON.TransformNode -> Artwork Node, Text Wall Node or Ordinary Object Node
	 */
	public setActiveExhibitAsset(exhibitAsset: any): void {
		if (exhibitAsset.name == 'artwork') this._setActiveArtworkNode(exhibitAsset);
		if (exhibitAsset.name == 'textWall') this._setActiveTextNode(exhibitAsset);

		store.dispatch({type: "UPDATE_STAT_SELECT_OBJECT", objectHasSelected: true});
	}

	/**
	 * ANCHOR Set Active Artwork Node
	 * @description to set active artwork node
	 * @param artworkNode : BABYLON.TransformNode
	 */
	private _setActiveArtworkNode(artworkNode): void {
		this.activeTab = "images";
		this.activeArtworkNode = artworkNode;
		this.activeArtwork = this.artworks.find(x => x.id == artworkNode.id.replace("artwork-", ""));

		this.setHorizontalCameraMovementObs(false);

		if(this.gizmos.position.attachedMesh) {
			this.hideAxis();
			this.showAxis("position", this.activeArtworkNode);
		}

		this._utilsService.enableHighlight({
			exhibitAsset: artworkNode,
			enable: true,
			highlightLayer: this.highlightLayer,
		});
	}

	/**
	 * * SET ACTIVE TEXT NODE *
	 * ANCHOR Set Active Text Node
	 * @description to set active text node
	 * @param textWallNode : BABYLON.TransformNode
	 */
	private _setActiveTextNode(textWallNode): void {
		this.activeTab = "text";
		this._textLoader.activeTextNode = textWallNode;
		this._textLoader.activeText = this._textLoader.texts.find(x => x.id == textWallNode.id.replace("textWall-", ""));

		this.setHorizontalCameraMovementObs(false);

		if(this.gizmos.position.attachedMesh) {
			this.hideAxis();
			this.showAxis("position", this._textLoader.activeTextNode);
		}

		this._utilsService.enableHighlight({
			exhibitAsset: textWallNode,
			enable: true,
			highlightLayer: this.highlightLayer,
		});
	}
 
	/**
	 * ANCHOR Set Inactive Exhibit Asset
	 * @description to set inactive exhibit asset
	 * @param exhibitAsset: BABYLON.TransformNode -> Artwork Node, Text Wall Node or Ordinary Object Node
	 */
	public setInactiveExhibitAsset(exhibitAsset) {
		this.activeArtwork = null;
		this.activeArtworkNode = null;
		this._textLoader.activeTextNode = null;
		this._textLoader.activeText = null;
		this._utilsService.enableHighlight({
			exhibitAsset,
			enable: false,
			highlightLayer: this.highlightLayer,
		});
	}

	//#endregion
	// !SECTION

	/**
	 * * ================================================================================================ * 
	 *   SECTION Select/Unselect Single Exhibit Asset Functions
	 * * ================================================================================================ * 
	 */
	//#region

	/**
	 * ANCHOR Select Single Exhibit Asset
	 * @description to select single exhibit asset such as artwork, text wall or ordinary object
	 * @param exhibitAsset: BABYLON.TransformNode
	 * @param scalingArtwork: boolean
	 */
	public selectExhibitAsset(
		exhibitAsset: any, ray: any = null, scalingArtwork: boolean = false
	): void {
		if (this.editFrameMode) return;

		if (exhibitAsset.id == this.activeArtworkNode?.id ||
			exhibitAsset.id == this.activeOrdinaryObjectNode?.id
		) {
			if (!scalingArtwork) return;
		}

		if(
			exhibitAsset.name == 'textWall' &&
			exhibitAsset.id == this._textLoader.activeTextNode?.id
		) {
			const overlapingTextWalls = ray ? this._getOverlapingTextWall(ray) : null;
			if (!overlapingTextWalls) return;
		}

		this.unselectExhibitAsset();
		if(this.selectedExhibitAssets.length > 1) this.unselectMultiExhibitAssets(true);

		switch (exhibitAsset.name) {
			case 'artwork': this._handleSelectArtwork(exhibitAsset); break;
			case 'ordinaryObject': this._handleSelectOrdinaryObject(exhibitAsset); break;
			case 'textWall': this._handleSelectTextWall(exhibitAsset, ray); break;
		}

		store.dispatch({type: "UPDATE_STAT_SELECT_OBJECT", objectHasSelected: true});
		
		if(this.overlapingTextWalls.length == 0) {
			this.setHorizontalCameraMovementObs(false);
			this.moveExhibitAssetByDragObs();
			this._utilsService.enableHighlight({
				exhibitAsset,
				enable: true,
				highlightLayer: this.highlightLayer,
			});
		}
	}

	/**
	 * ANCHOR Handle Select Artwork
	 * @description to handle select artwork
	 * @param artworkNode : BABYLON.TransformNode
	 */
	private _handleSelectArtwork(artworkNode: any): void {
		this.activeTab = "images";
		this.activeArtworkNode = artworkNode;
		this.activeArtwork = this.artworks.find(x => x.id == artworkNode.id.replace("artwork-", ""));
		this.selectedExhibitAssets = [this.activeArtworkNode]
	}

	/**
	 * ANCHOR Handle Select Ordinary Object
	 * @description to handle select ordinary object
	 * @param ordinaryObjectNode : BABYLON.TransformNode
	 */
	private _handleSelectOrdinaryObject(ordinaryObjectNode: any): void {
		this.activeTab = "objects";
		this.activeOrdinaryObjectNode = ordinaryObjectNode;
		this.activeOrdinaryObject = this.ordinaryObjects.find(x => x.id == ordinaryObjectNode.id.replace("ordinaryObject-", ""))
		this.selectedExhibitAssets = [this.activeOrdinaryObject]
	}

	/**
	 * ANCHOR Unselect Exhibit Asset
	 * @description to unselect exhibit asset such as artwork, text wall or ordinary object
	 */
	public unselectExhibitAsset(): void {
		if (this.editFrameMode) return;

		const activeExhibitAsset = this.activeArtworkNode || this.activeOrdinaryObjectNode || this._textLoader.activeTextNode;
		if (activeExhibitAsset) {
			activeExhibitAsset.isMove = false;

			if (this.activeArtworkNode && this.activeArtworkId !== null) this.unfocusFromArtworkAnimation();

			this._utilsService.enableHighlight({
				exhibitAsset: activeExhibitAsset,
				enable: false,
				highlightLayer: this.highlightLayer,
			});
			this.hideAxis()
	
			this.mainCameraNode.attachControl(this.canvas, true);
			this.setHorizontalCameraMovementObs(this.exhibition.horizontal_view_movement);
	
			this.scene.onPointerObservable.remove(this.observables['moveExhibitAssetByDragObs'])
			this.observables['moveExhibitAssetByDragObs'] = null;
	
			this.activeArtwork = null;
			this.activeArtworkNode = null;
			this._textLoader.activeText = null;
			this._textLoader.activeTextNode = null;
			this.activeOrdinaryObject = null;
			this.activeOrdinaryObjectNode = null;
			
			store.dispatch({type: "UPDATE_STAT_SELECT_OBJECT", objectHasSelected: false});
		}

		if(this.overlapingTextWalls.length > 0) {
			this.overlapingTextWalls.forEach((textWall: any) => {
				const textWallNode = this.scene.getTransformNodeById("textWall-" + textWall.id)
				this._utilsService.enableHighlight({
					exhibitAsset: textWallNode,
					enable: false,
					highlightLayer: this.highlightLayer,
				});
			})
			this.overlapingTextWalls = [];
			store.dispatch({type: "UPDATE_STAT_SELECT_OBJECT", objectHasSelected: false});
		}

	}

	/**
	 * ANCHOR Handle Select Text Wall
	 * @description to handle select text wall
	 * @param textWallNode : BABYLON.TransformNode
	 * @param ray : BABYLON.Ray
	 */
	public overlapingTextWalls: TextWall[] = [];
	private _handleSelectTextWall(textWallNode: any, ray: any): void {
		this.activeTab = "text";		
		const overlapingTextWalls = ray ? this._getOverlapingTextWall(ray) : null;
		if (overlapingTextWalls) {
			this._selectOverlapingTextWalls(overlapingTextWalls);
		} else {
			this._textLoader.activeTextNode = textWallNode;
			this._textLoader.activeText = this._textLoader.texts.find(x => x.id == textWallNode.id.replace("textWall-", ""));
			this.showAlignLimit = false;
			this.bottomLimit.visibility = this.topLimit.visibility = 0;
			this.selectedExhibitAssets = [this._textLoader.activeTextNode]
		}
	}
	
	//#endregion
	//!SECTION

	/**
	 * * ================================================================================================ * 
	 *   SECTION Handle Overlaping Text Walls Functions
	 * * ================================================================================================ * 
	 */
	//#region

	/**
	 * ANCHOR Get Overlaping Text Wall
	 * @description to get overlaping text wall
	 * @param ray : BABYLON.Ray
	 */
	private _getOverlapingTextWall(ray: any): any {
		const hits = this.scene.multiPickWithRay(ray);
		hits.sort((a,b) => a.distance - b.distance)

		let pickedMeshes = [];
		for (let i = 0; i < hits.length; i++) {
			if(hits[i].pickedMesh.name === 'textWall') {
				pickedMeshes.push(hits[i].pickedMesh._parentNode);
			} else {
				break;
			}
		}

		if(pickedMeshes.length > 1) return pickedMeshes;
		return null;
	}

	/**
	 * ANCHOR Enable Highlight Overlaping Text Walls
	 * @description to enable highlight overlaping text walls
	 * @param enable : boolean
	 */
	public enableHighlightOverlapingTextWalls(enable: boolean): void {
		this.overlapingTextWalls.forEach((textWall: any) => {
			const textWallNode = this.scene.getTransformNodeById("textWall-" + textWall.id)
			this._utilsService.enableHighlight({
				exhibitAsset: textWallNode,
				enable,
				highlightLayer: this.highlightLayer,
			});
		})
	}

	/**
	 * ANCHOR Select Overlaping Text Walls
	 * @description to select overlaping text walls
	 * @param textWallNodes : BABYLON.TransformNode[]
	 */
	private _selectOverlapingTextWalls(textWallNodes, multiSelect: boolean = false): void {
		this.displaySelectOverlappingTexts = true;
		this.selectMultiOverlappingTexts = multiSelect;
		textWallNodes.forEach((textNode: any) => {
			const textWallData = this._textLoader.texts.find(x => x.id == textNode.id.replace("textWall-", ""));
			this.overlapingTextWalls.push(textWallData);
		});
	}

	//#endregion
	//!SECTION

	/**
	 * * ================================================================================================ * 
	 *   SECTION Move Exhibit Asset By Drag Functions
	 * * ================================================================================================ * 
	 */
	//#region

	/**
	 * ANCHOR Main Function of Move Exhibit Asset By Drag
	 * @description to initialize move exhibit asset by drag pointer observable
	 */
	public moveExhibitAssetByDragObs(): void {
		const observer = this.scene.onPointerObservable.add((pointerInfo) => {
			if(this.selectedExhibitAssets.length == 1) {
				switch (pointerInfo.type) {
					case BABYLON.PointerEventTypes.POINTERDOWN:
						this._moveExhibitAssetByDragPointerDownHandler(pointerInfo);
					break;
					case BABYLON.PointerEventTypes.POINTERUP:
						this._moveExhibitAssetByDragPointerUpHandler()
					break;
					case BABYLON.PointerEventTypes.POINTERMOVE:
						this._moveExhibitAssetByDragPointerMoveHandler(pointerInfo);
					break;
				}
			}
		});

		this.observables['moveExhibitAssetByDragObs'] = observer;
	}

	/**
	 * ANCHOR Get Active Exhibit Asset
	 * @description to get active exhibit asset
	 */
	private _getActiveExhibitAsset(): any {
		return this.activeArtworkNode || this._textLoader.activeTextNode || this.activeOrdinaryObjectNode;
	}


	// SECTION Move Exhibit Asset By Drag Pointer Handlers
	// #region

	/**
	 * ANCHOR Move Exhibit Asset By Drag Pointer Down Handler
	 * @description to handle move exhibit asset by drag pointer down
	 * @param pointerInfo : BABYLON.PointerInfo
	 */
	private _moveExhibitAssetByDragPointerDownHandler(pointerInfo): void {
		let pickedMesh:any;
		let pickedMeshName:any;
		const moveObject = this._getActiveExhibitAsset();

		if (moveObject?.name == 'textWall') {
			const ray = pointerInfo.pickInfo.ray;
			const hit = ray.intersectsMeshes(moveObject.getChildren());
			pickedMesh = hit[0]?.pickedMesh;
		} else {
			pickedMesh = pointerInfo.pickInfo.pickedMesh;
		}
		
		pickedMeshName = pickedMesh?._parentNode?.name;
		if (pickedMesh?._parentNode?.id == moveObject?.id) {
			if (pickedMeshName == "artwork") this._dragArtworkPointerDownHandler(pointerInfo);
			if (pickedMeshName == "ordinaryObject") this._dragOrdinaryObjectPointerDownHandler();
			if (pickedMeshName == 'textWall') this._dragTextWallPointerDownHandler();
		}
	}

	/**
	 * ANCHOR Move Exhibit Asset By Drag Pointer Move Handler
	 * @description to handle move exhibit asset by drag pointer move
	 * @param pointerInfo : BABYLON.PointerInfo
	 */
	private _moveExhibitAssetByDragPointerMoveHandler(pointerInfo): void {
		if (pointerInfo.event.buttons == 1) {
			const moveObject = this._getActiveExhibitAsset();
			if (moveObject?.name == "artwork") this._dragArtworkPointerMoveHandler(pointerInfo);
			if (moveObject?.name == "ordinaryObject") this._dragOrdinaryObjectPointerMoveHandler(pointerInfo);
			if (moveObject?.name == "textWall") this._dragTextWallPointerMoveHandler(pointerInfo);
		}
	}

	/**
	 * ANCHOR Move Exhibit Asset By Drag Pointer Up Handler
	 * @description to handle move exhibit asset by drag pointer up
	 */
	private _moveExhibitAssetByDragPointerUpHandler(): void {
		const moveObject = this._getActiveExhibitAsset();
		if (moveObject?.name == "artwork") this._dragArtworkPointerUpHandler();
		if (moveObject?.name == "ordinaryObject") this._dragOrdinaryObjectPointerUpHandler();
		if (moveObject?.name == "textWall") this._dragTextWallPointerUpHandler();
	}

	// #endregion
	// !SECTION


	// SECTION Drag Artwork Pointer Handlers
	// #region
	
	/**
	 * ANCHOR Drag Artwork Pointer Down Handler
	 * @description to handle drag artwork pointer down
	 * @param pointerInfo : BABYLON.PointerInfo
	 */
	private _dragArtworkPointerDownHandler(pointerInfo) {
		if (this.lockCameraWhenDragArtwork) this.mainCameraNode.detachControl(this.canvas);
		this.activeArtworkNode.rotation.x = 0;

		if (this.activeArtworkNode['artworkType'] !== 'figure-object') {
			const dragOrigin = this._createDragOrigin(pointerInfo);
			this._repositionDragOrigin(dragOrigin, pointerInfo.pickInfo.ray);
	
			const positionMarker = this._createArtworkPositionMarker();
			positionMarker.setParent(dragOrigin);
	
			this.activeArtworkNode['positionMarker'] = positionMarker;
			this.activeArtworkNode['dragOrigin'] = dragOrigin;
			this._artworkShadowsService.setEnableShadowCast(this.activeArtworkNode, false);
			this._artworkShadowsService.setEnableShadowComponents(this.activeArtworkNode, false);
		}
		
		this.activeArtworkNode['isMove'] = true;
	}

	/**
	 * ANCHOR Create Mesh Blocker
	 * @description to create mesh blocker
	 * @returns : BABYLON.Mesh
	 */
	private _createMeshBlocker(): any {	
		const blocker = BABYLON.MeshBuilder.CreatePlane('blocker', {}, this.scene);
		blocker.position = this.activeArtworkNode.position.clone();
		blocker.rotation = this.activeArtworkNode.rotation.clone();
		blocker.rotation.y += Math.PI;

		return blocker
	}

	/**
	 * ANCHOR Create Artwork Position Marker
	 * @description to create artwork position marker
	 * @param dragOrigin : BABYLON.Mesh
	 * @param ray : BABYLON.Ray
	 */
	private _repositionDragOrigin(dragOrigin, ray): void {
		const blocker = this._createMeshBlocker();
		dragOrigin.setParent(blocker);
		dragOrigin.position.z = 0;
		dragOrigin.setParent(null)
		blocker.dispose();
	}

	/**
	 * ANCHOR Create Drag Origin
	 * @description to create drag origin
	 * @param pointerInfo : BABYLON.PointerInfo
	 * @returns : BABYLON.Mesh
	 */
	private _createDragOrigin(pointerInfo): any {
		const dragOrigin = this.scene.getMeshByName('detectorBox').clone();
		dragOrigin.position = pointerInfo.pickInfo.pickedPoint;
		dragOrigin.rotation = this.activeArtworkNode.rotation.clone();
		dragOrigin.scaling.z = this.activeArtworkNode.scaling.z;
		dragOrigin.scaling.x = 0.05;
		dragOrigin.scaling.y = 0.05;
		dragOrigin.isPickable = false;
		return dragOrigin;
	}

	/**
	 * ANCHOR Create Artwork Position Marker
	 * @description to create artwork position marker
	 * @returns : BABYLON.Mesh
	 */
	private _createArtworkPositionMarker(): any {
		const marker = this.scene.getMeshByName('detectorBox').clone();
		marker.position = this.activeArtworkNode.position.clone();
		marker.rotation = this.activeArtworkNode.rotation.clone();
		marker.scaling = this.activeArtworkNode.scaling.clone();
		marker.isPickable = false;
		return marker
	}

	/**
	 * ANCHOR Drag Artwork Pointer Move Handler
	 * @description to handle drag artwork pointer move
	 * @param pointerInfo: BABYLON.PointerInfo
	 */
	private _dragArtworkPointerMoveHandler(pointerInfo): void {
		if (this.activeArtworkNode['isMove']) {
			this.hideAxis();
			const isArtworkObject = this.activeArtworkNode['artworkType'] == 'figure-object';
			const ray = pointerInfo.pickInfo.ray;
			const area = isArtworkObject ? 'all' : 'wall';
			const dragArea = this.getDragArea(ray, area);
			if (dragArea) {
				if (isArtworkObject) {
					this.activeArtworkNode.position = dragArea.pickedPoint
				} else {
					const dragOrigin = this.activeArtworkNode['dragOrigin'];
					this.repositionNodeOnDragArea( dragOrigin, ray, dragOrigin.scaling.z);
					const { position, rotation } = this._getPositionOfMarker();
					this.activeArtworkNode.position = position;
					this.activeArtworkNode.rotation = rotation;
				}
			}	
		}
	}

	/**
	 * ANCHOR Get Position & Rotation Of Marker
	 * @description to get position & rotation of marker
	 * @returns : { position: BABYLON.Vector3, rotation: BABYLON.Vector3 }
	 */
	private _getPositionOfMarker(): any {
		const positionMarker = this.activeArtworkNode['positionMarker'].clone();
		positionMarker.setParent(null);
		const position = positionMarker.position.clone();
		const rotation = positionMarker.rotation.clone();
		positionMarker.dispose();
		return { position, rotation };
	}

	/**
	 * ANCHOR Drag Artwork Pointer Up Handler
	 * @description to handle drag artwork pointer up
	 */
	private _dragArtworkPointerUpHandler(): void {
		if (this.activeArtworkNode['isMove']) {
			this.mainCameraNode.attachControl(this.canvas, true);
			if(this.activeArtworkNode['artworkType'] != 'figure-object') {
				this.activeArtworkNode['dragOrigin'].dispose();
				this.activeArtworkNode['positionMarker'].dispose();
				this.markPositionPlaceholderIsUsedOrNot(this.activeArtworkNode);
			};

			this.markForUpdate(this.activeArtwork, 'transform');
			this.updateArtworkData(this.activeArtworkNode, this.activeArtwork);
			this.updateLogActivity("Update artwork position")
			this.dataHasChanges = true;
			this.activeArtworkNode['isMove'] = false;

		}
	}

	// #endregion
	// !SECTION


	// SECTION Drag Ordinary Object Pointer Handlers
	//#region 

	/** 
	 * ANCHOR Drag Ordinary Object Pointer Down Handler
	 * @description to handle drag ordinary object pointer down
	*/
	private _dragOrdinaryObjectPointerDownHandler(): void {
		this.activeOrdinaryObjectNode['isMove'] = true;
		if(this.lockCameraWhenDragOrdinaryObject) this.mainCameraNode.detachControl(this.canvas);
	}

	/**
	 * ANCHOR Drag Ordinary Object Pointer Move Handler
	 * @description to handle drag ordinary object pointer move
	 * @param pointerInfo : BABYLON.PointerInfo
	 */
	private _dragOrdinaryObjectPointerMoveHandler(pointerInfo): void {
		if (this.activeOrdinaryObjectNode['isMove']) {
			this.hideAxis();
			const exhibitionArea = pointerInfo.pickInfo.ray.intersectsMeshes(this.activeExhibitionNode.getChildren());
			this.activeOrdinaryObjectNode.position = exhibitionArea[0].pickedPoint;
		}
	}

	/**
	 * ANCHOR Drag Ordinary Object Pointer Up Handler
	 * @description to handle drag ordinary object pointer up
	 */
	private _dragOrdinaryObjectPointerUpHandler(): void {
		if(this.activeOrdinaryObjectNode['isMove']) {
			this.activeOrdinaryObjectNode['isMove'] = false;
			this.mainCameraNode.attachControl(this.canvas, true);
			this.updateOrdinaryObjectData(this.activeOrdinaryObjectNode, this.activeOrdinaryObject);
			this.updateLogActivity("Update ordinary object position")
			this.dataHasChanges = true;
		}
	}

	//#endregion
	// !SECTION


	// SECTION Drag Text Wall Pointer Handlers
	//#region

	/**
	 * ANCHOR Drag Text Wall Pointer Down Handler
	 * @description to handle "drag text wall" pointer down event
	 */
	private _dragTextWallPointerDownHandler(): void {
		this._textLoader.activeTextNode['isMove'] = true;
		this.mainCameraNode.detachControl(this.canvas);
	}

	/**
	 * ANCHOR Drag Text Wall Pointer Move Handler
	 * @description to handle "drag text wall" pointer move event
	 * @param pointerInfo : BABYLON.PointerInfo
	 */
	private _dragTextWallPointerMoveHandler(pointerInfo): void {
		if (this._textLoader.activeTextNode['isMove']) {
			this.hideAxis();
			const ray = pointerInfo.pickInfo.ray;
			const dragArea = this.getDragArea(ray, 'wall');
			if (dragArea) {
				this.repositionNodeOnDragArea(this._textLoader.activeTextNode, ray);
			}
		}
	}

	/**
	 * ANCHOR Drag Text Wall Pointer Up Handler
	 * @description to handle "drag text wall" pointer up event
	 */
	private _dragTextWallPointerUpHandler(): void {
		if (this._textLoader.activeTextNode['isMove']) {
			this.mainCameraNode.attachControl(this.canvas, true);
			this.updateTextData(this._textLoader.activeTextNode, this._textLoader.activeText);
			this.updateLogActivity("Update text position")
			this.dataHasChanges = true;
			this._textLoader.activeTextNode['isMove'] = false;
		}
	}
	
	// #endregion
	//!SECTION


	//#endregion
	//!SECTION

	/**
	 * * ================================================================================================ *
	 *   SECTION Main Pointer Observable Handler Functions
	 * * ================================================================================================ *
	 */
	//#region 

	/**
	 * ANCHOR Init Main Pointer Observable
	 * @description : to initialize the main pointer observable
	 */
	public initMainPointerObs() {
		setTimeout(() => {
			if (!this.observables['mainObservable']) {
				this.observables['mainObservable'] = this.scene.onPointerObservable.add((pointerInfo) => {
					if (!this._loadingGalleryService.show && !this.previewAutoPlacedArtworks) {
						let mesh = pointerInfo.pickInfo.pickedMesh;
						switch (pointerInfo.type) {

							case BABYLON.PointerEventTypes.POINTERTAP:
								if (this._canMoveTheCamera(mesh?.name)) {
									if (!this.previewMode) this._displayPointerOnDonutArea(pointerInfo.pickInfo.ray)
									else this._setPointerEffectTransparency(pointerInfo.pickInfo.ray);
									this._moveCamera(pointerInfo.pickInfo.pickedPoint);
								}
								if(!this.showFieldOfView) {
									if (!this.previewMode) this._handleSelectExhibitAssets(pointerInfo);
									else this._handleFocusOnArtworkAnimation(pointerInfo);
								}
							break;
							case BABYLON.PointerEventTypes.POINTERMOVE:
								if (!this.previewMode) this._displayPointerOnDonutArea(pointerInfo.pickInfo.ray)
								else this._setPointerEffectTransparency(pointerInfo.pickInfo.ray);
								this._setCameraGravityOnStairs(pointerInfo);
							break;
						}
					}
				})
			}
		}, 100);
	}

	/**
	 * ANCHOR Set Camera Gravity On Stairs
	 * @description : to set the camera gravity on stairs
	 * @param pointerInfo : BABYLON.PointerInfo
	 */
	private _setCameraGravityOnStairs(pointerInfo) {
		if (pointerInfo.event.buttons === 1) {
			if (!this.mainCameraNode['aboveFloor']) this.scene.gravity.y = this.gravity;
		} else {
			if (this.mainCameraNode['aboveFloor']) this.scene.gravity.y = 0;
		}
	}

	/**
	 * ANCHOR Handle Focus On Artwork Animation
	 * @description : to handle focus on artwork animation via pointer observable
	 * @param pointerInfo : BABYLON.PointerInfo
	 */
	private _handleFocusOnArtworkAnimation(pointerInfo): void {
		const mesh = pointerInfo.pickInfo.pickedMesh;
		if(!this.focusAnimationIsRun){
			if (mesh?.name == "artworkDonut" || mesh?._parentNode?.name == "artwork") {
				let artworkNode;
				if(mesh?.name == "artworkDonut") artworkNode = this.scene.getTransformNodeByID(mesh['artworkId']);
				if(mesh?._parentNode?.name == "artwork") artworkNode = mesh?._parentNode;
				this.focusOnArtworkAnimation(artworkNode);
			} else {
				if (this.activeArtworkId != null) {
					this.unfocusFromArtworkAnimation();
				}
			}
		}
	}

	/**
	 * ANCHOR Can Move The Camera
	 * @description : to check if the camera can move
	 * @param meshName : string
	 * @returns : boolean
	 */
	private _canMoveTheCamera(meshName: string): boolean {
		if (this.onFocusedArtwork) return false
		return (
			meshName.toLowerCase().includes('donut_area') ||
			meshName.toLowerCase().includes('floor') || 
			meshName.toLowerCase().includes('stairs_invisible')
		)
	}

	//#endregion
	// !SECTION

	/**
	 * * ================================================================================================ *
	 *   SECTION Select Exhibit Assets Handler Functions
	 * * ================================================================================================ *
	 */
	// #region

	/**
	 * ANCHOR Handle Select Exhibit Assets
	 * @description: to handle select exhibit assets (artwork, text wall, ordinary object)
	 * @param pointerInfo : BABYLON.PointerInfo
	 */
	private _handleSelectExhibitAssets(pointerInfo): void {
		const mesh = pointerInfo.pickInfo.pickedMesh;
		if (this._isExhibitionDataValid()) {
			if (this.activeArtworkId === null) {
				const exhibitAssetNode = mesh?._parentNode;
				const exhibitAssetType = exhibitAssetNode?.name;
				if (this._isExhibitAsset(exhibitAssetType)) {
					if (this._isMultiSelectExhibitAssets(exhibitAssetType)) {
						this.selectMultiExhibitAssets(exhibitAssetNode,pointerInfo.pickInfo.ray);
					} else {
						this.selectExhibitAsset(exhibitAssetNode, pointerInfo.pickInfo.ray);
					}
					this.updateLogActivity("Select object");
				} else {
					this.scene.onPointerObservable.remove(this.observables['moveExhibitAssetByDragObs'])
					this.observables['moveExhibitAssetByDragObs'] = null;
					
					this.showAlignLimit = false;
					this.bottomLimit.visibility = this.topLimit.visibility = 0;
					
					if(this.selectedExhibitAssets.length > 1) this.unselectMultiExhibitAssets();
					else this.unselectExhibitAsset();

					this.selectedExhibitAssets = [];
					this.updateLogActivity("Unselect object")
				}
			} else {
				this.unfocusFromArtworkAnimation();
			}
		}
	}

	/**
	 * ANCHOR Is Exhibit Asset
	 * @description: to check if the mesh is exhibit asset (artwork, text wall, ordinary object)
	 * @param name : string
	 * @returns : boolean
 	 */
	private _isExhibitAsset(name: string): boolean {
		return ['artwork', 'textWall', 'ordinaryObject'].includes(name);
	}

	/**
	 * ANCHOR Is Exhibition Data Valid
	 * @description to check if the exhibition data is valid
	 * @returns : boolean
	 */
	private _isExhibitionDataValid(): boolean {
		if (this.artworkDataValid && this._textLoader.textDataValid && this.ordinaryObjectDataValid && this.publishDataValid) {
			return true;
		} else {
			if (!this.artworkDataValid) {
				this._messageService.add({
					severity: "warn",
					summary: "Warning",
					detail: "The Artworks tab contains invalid data. Please update the data."
				})
			}
			if (!this._textLoader.textDataValid) {
				this._messageService.add({
					severity: "warn",
					summary: "Warning",
					detail: "The Text tab contains invalid data. Please update the data."
				})
			}
			if (!this.ordinaryObjectDataValid) {
				this._messageService.add({
					severity: "warn",
					summary: "Warning",
					detail: "The Objects tab contains invalid data. Please update the data."
				})
			}

			if (!this.publishDataValid) {
				this._messageService.add({
					severity: "warn",
					summary: "Warning",
					detail: "The Publish tab contains invalid data. Please update the data."
				})
			}
			return false;
		}
	}

	/**
	 * ANCHOR Is Multi Select Exhibit Assets
	 * @description to check if in multi select mode
	 * @param assetType : string
	 * @returns : boolean
	 */
	private _isMultiSelectExhibitAssets(assetType: string): boolean {
		return this.ctrlPressed && (assetType == 'artwork' || assetType == 'textWall') && this.selectedExhibitAssets.length >= 1;
	}

	// #endregion
	// !SECTION

	/**
	 * * ================================================================================================ *
	 *   SECTION Zoom in/out Artwork on Focus Functions
	 * * ================================================================================================ *
	 */
	//#region 

	/**
	 * ANCHOR Setup Camera Zoom Artwork
	 * @description : enable zoom camera on artwork focus
	 * @param: Artwork
	 */
	public async setupCameraZoomArtwork(artwork: any): Promise<void>  {
		const artworkContainer = this._utilsService.cloneArtworkContainer(artwork, this.scene);
    const zoomCamera = this._createZoomCamera(artworkContainer);

    this.mainCameraNode.detachControl();
    this.scene.activeCamera = zoomCamera;

    zoomCamera.minZ = 0.01;
    zoomCamera.lowerRadiusLimit = await this._getMinimunRadius(artworkContainer, zoomCamera);
    zoomCamera.upperRadiusLimit = await this._getMaximunRadius(artworkContainer, zoomCamera);
    zoomCamera.lowerAlphaLimit = zoomCamera.upperAlphaLimit = this.scene.activeCamera.alpha;
    zoomCamera.lowerBetaLimit = zoomCamera.upperBetaLimit = this.scene.activeCamera.beta;

    zoomCamera.attachControl(this.canvas, true);
    artworkContainer.dispose();
	}

	/**
	 * ANCHOR Reset Camera
	 * @description : reset camera to main camera
	 */
	public resetCameraZoomArtwork() {
		if (this.scene.activeCamera.name == 'zoomCamera') {
			this.onFocusedArtwork = false;
			const position = this.scene.activeCamera['_position'].clone();
	
			this.mainCameraNode.position = position;
			this.scene.activeCamera.detachControl();
			this.scene.activeCamera = this.mainCameraNode;
			this.scene.activeCamera.attachControl(this.canvas, true);
		}
	}

	/**
   * ANCHOR Create Zoom Camera
   * @description : create zoom camera
   * @param artworkContainer : BABYLON.Mesh -> Clone of artwork container
   * @returns : BABYLON.ArcRotateCamera
   */
  private _createZoomCamera(artworkContainer: any): any {
    let zoomCamera = this.scene.getCameraByName('zoomCamera');
    if (zoomCamera) zoomCamera.dispose();

    zoomCamera = new BABYLON.ArcRotateCamera('zoomCamera', 0, 0, 0, new BABYLON.Vector3(0, 0, 0), this.scene);
    zoomCamera.wheelPrecision = 200;
    zoomCamera.panningSensibility = 0;
    zoomCamera.pinchPrecision = 200;

    zoomCamera.position = this.mainCameraNode.position.clone();
    zoomCamera.setTarget(artworkContainer.position.clone());

    return zoomCamera;
  }

	// SECTION Calculate Minimum Radius
  //#region 

  /**
   * ANCHOR Get Distance Camera To Artwork
   * @description : get distance camera to artwork
   * @param artworkContainer: BABYLON.Mesh -> Clone of artwork container
   * @param zoomCamera : BABYLON.ArRotateCamera -> Zoom camera
   * @returns : Promise<number>
   */
  private _getDistanceCameraToArtwork(artworkContainer: any, zoomCamera: any): Promise<number> {
    return new Promise((resolve, reject) => {
      try {
        setTimeout(() => {
          const ray = this._utilsService.createRaycast({ 
						node: zoomCamera, 
						direction: artworkContainer.position,
						scene: this.scene
					});
          const distance = this.scene.pickWithRay(ray).distance;
          resolve(distance);
        }, 100)
      } catch (error) {
        reject(error);
      }
    })
  }

  /**
   * ANCHOR Get Minimum Radius
   * @description : get minimum radius
   * @param artworkContainer : BABYLON.Mesh -> Clone of artwork container
   * @param zoomCamera : BABYLON.ArRotateCamera -> Zoom camera
   * @returns : Promise<number>
   */
  private async _getMinimunRadius(artworkContainer: any, zoomCamera: any): Promise<number> {
    let distance = await this._getDistanceCameraToArtwork(artworkContainer, zoomCamera);
    distance += 0.1

    if (distance < zoomCamera.radius) return zoomCamera.radius * 0.18;
    else return zoomCamera.radius * 0.1;
  }

  //#endregion
  //!SECTION


  // SECTION Calculate Maximun Radius
  //#region 

  /**
   * ANCHOR Get Maximun Radius
   * @description : get maximun radius
   * @param artworkContainer : BABYLON.Mesh -> Clone of artwork container
   * @param zoomCamera : BABYLON.ArRotateCamera -> Zoom camera
   * @returns : Promise<number>
   */
  private _getMaximunRadius(artworkContainer: any, zoomCamera: any): Promise<number> {
    return new Promise((resolve, reject) => {
      try {
        const directionMarker = BABYLON.MeshBuilder.CreateBox('box', { size: 1 }, this.scene);
        directionMarker.position = zoomCamera.position.clone();
        directionMarker.lookAt(artworkContainer.position);
        directionMarker.translate(new BABYLON.Vector3(0, 0, -3), 1, BABYLON.Space.LOCAL);
        
        const defaultDistance = BABYLON.Vector3.Distance(directionMarker.position, artworkContainer.position);
        
        setTimeout(() => {
          directionMarker.translate(new BABYLON.Vector3(0, 0, -100), 1, BABYLON.Space.LOCAL);
          
          const raycast = this._utilsService.createRaycast({ 
            node: zoomCamera, 
            direction: directionMarker.position,
						scene: this.scene 
          });

          directionMarker.dispose();
    
          const pickedPoint = this.scene.pickWithRay(raycast).pickedPoint;   
          const distanceToObjectBehindCamera = BABYLON.Vector3.Distance(pickedPoint, artworkContainer.position);

          const distance = Math.min(defaultDistance, distanceToObjectBehindCamera) - 0.4;
          resolve(distance);
        }, 100)
      } catch (error) {
        reject(error);
      }
    })
  }

  //#endregion
  //!SECTION


	//#endregion
	//!SECTION

	/**
	 * * ================================================================================================ *
	 *   SECTION Move/Rotate Exhibit Assets By Axis Functions
	 * * ================================================================================================ *
	 */
	//#region

	/**
	 * ANCHOR Init Gizmos
	 * @description : to initialize the gizmos
	 */
	public initGizmos(): void {
		this.gizmos = {
			position: new BABYLON.PositionGizmo(),
			rotation: new BABYLON.RotationGizmo(),
		}

		Object.entries(this.gizmos).forEach((gizmo: any) => {
			gizmo[1]['onDragStartObservable'].add(() => this._onDragStartGizmoAxisHandler());
			gizmo[1]['onDragEndObservable'].add(() => {
				this._onDragEndGizmoAxisHandler();
				this.updateLogActivity(`Update object ${gizmo[0]}`)
			})
		});
	}
  
	// SECTION On Drag Start Gizmo Axis Handler

	/**
	 * ANCHOR On Drag Start Gizmo Axis Handler
	 * @description : to handle on drag start gizmo axis
	 */
	private _firstPosition: any = null;
	private _firstPositions: any = [];
	private _onDragInterval: any = null;
	private _onDragStartGizmoAxisHandler(): void {
		if (this.activeArtworkNode) this._onDragStartGizmoAxisArtworkHandler();
		if (this._textLoader.activeTextNode) this._onDragStartGizmoAxisTextWallHandler();
		if (this.activeOrdinaryObjectNode) this._onDragStartGizmoAxisOrdinaryObjectHandler();
	}

	/**
	 * ANCHOR On Drag Start Gizmo Axis Artwork Handler
	 * @description : to handle on drag start gizmo axis for artwork
	 */
	private _onDragStartGizmoAxisArtworkHandler(): void {
		const { file_type } = this.activeArtworkNode.metadata.artworkData;
		if(file_type !== 'figure-object') {
			this._artworkShadowsService.setEnableShadowCast(this.activeArtworkNode, false);
			this._artworkShadowsService.setEnableShadowComponents(this.activeArtworkNode, false);
		}
		if (this.selectedExhibitAssets.length > 1) {
			this._firstPosition = this.activeArtworkNode.position.clone();
			this.selectedExhibitAssets.forEach((node: any) => {
				if (node.id != this.activeArtworkNode.id) {
					node['shadow']?.setEnabled(false);
					this._firstPositions.push({
						node: node,
						position: node.position.clone()
					});
				}
			})
		}

		this._onDragInterval = this.scene.onBeforeRenderObservable.add(()=>{
			if(this.selectedExhibitAssets.length > 1){
				let additionalXPos = this.activeArtworkNode.position.x - this._firstPosition.x;
				let additionalYPos = this.activeArtworkNode.position.y - this._firstPosition.y;
				let additionalZPos = this.activeArtworkNode.position.z - this._firstPosition.z;

				this._firstPositions.map((x)=>{
					x.node.position.x = x.position.x + additionalXPos;
					x.node.position.y = x.position.y + additionalYPos;
					x.node.position.z = x.position.z + additionalZPos;
				});
			}

			const gizmoRotateMesh = this.scene.getMeshByName('_gizmoRotateMesh');
			if(gizmoRotateMesh && gizmoRotateMesh.isEnabled()) {
				this.activeArtworkNode.rotation = gizmoRotateMesh.rotation.clone();
				this._firstPositions.map((x)=>{ x.node.rotation = gizmoRotateMesh.rotation.clone() });
			}
		});
	}

	/**
	 * ANCHOR On Drag Start Gizmo Axis Text Wall Handler
	 * @description : to handle on drag start gizmo axis for text wall
	 */
	private _onDragStartGizmoAxisTextWallHandler(): void {
		if (this.selectedExhibitAssets.length > 1) {
			this._firstPosition = this._textLoader.activeTextNode.position.clone();
			this.selectedExhibitAssets.forEach((node: any) => {
				if (node.id != this._textLoader.activeTextNode.id) {
					this._firstPositions.push({
						node: node,
						position: node.position.clone()
					});
				}
			});

			this._onDragInterval = this.scene.onBeforeRenderObservable.add(()=>{
				if(this.selectedExhibitAssets.length > 1){
					let additionalXPos = this._textLoader.activeTextNode.position.x - this._firstPosition.x;
					let additionalYPos = this._textLoader.activeTextNode.position.y - this._firstPosition.y;
					let additionalZPos = this._textLoader.activeTextNode.position.z - this._firstPosition.z;
	
					this._firstPositions.map((x)=>{
						x.node.position.x = x.position.x + additionalXPos;
						x.node.position.y = x.position.y + additionalYPos;
						x.node.position.z = x.position.z + additionalZPos;
					});
				}
			});
		}
	}

	/**
	 * ANCHOR On Drag Start Gizmo Axis Ordinary Object Handler
	 * @description : to handle on drag start gizmo axis for ordinary object
	 */
	private _onDragStartGizmoAxisOrdinaryObjectHandler(): void {
		this._onDragInterval = this.scene.onBeforeRenderObservable.add(()=>{
			const gizmoRotateMesh = this.scene.getMeshByName('_gizmoRotateMesh');
			if(gizmoRotateMesh && gizmoRotateMesh.isEnabled()) {
				this.activeOrdinaryObjectNode.rotation = gizmoRotateMesh.rotation.clone();
			}
		});
	}

	// !SECTION

	// SECTION On Drag End Gizmo Axis Handler

	/**
	 * ANCHOR On Drag End Gizmo Axis Handler
	 * @description : to handle on drag start gizmo axis
	 */
	private _onDragEndGizmoAxisHandler(): void {
		this.scene.onBeforeRenderObservable.remove(this._onDragInterval);
		this._onDragInterval = null;
		this._firstPosition = null
		this._firstPositions = [];

		if (this._textLoader.activeTextNode) this._onDragEndGizmoAxisTextWallHandler()
		if (this.activeArtworkNode) this._onDragEndGizmoAxisArtworkHandler()
		if (this.activeOrdinaryObjectNode) this._onDragEndGizmoAxisOrdinaryObjectHandler()
	}

	/**
	 * ANCHOR On Drag End Gizmo Axis Artwork Handler
	 * @description : to handle on drag end gizmo axis for artwork
	 */
	private _onDragEndGizmoAxisArtworkHandler(): void {
		if (this.selectedExhibitAssets.length > 1) {
			this.selectedExhibitAssets.map((node) => {
				const artworkData = this.artworks.find((x: any)=> x.id == node.id.replace("artwork-",""));
				this.markForUpdate(node.metadata.artworkData, 'transform')
				this.updateArtworkData(node, artworkData, true, true);
			})
		} else {
			this.markForUpdate(this.activeArtwork, 'transform')
			this.updateArtworkData(this.activeArtworkNode, this.activeArtwork, true, true);
		}
		this.dataHasChanges = true;
	}

	/**
	 * ANCHOR On Drag End Gizmo Axis Text Wall Handler
	 * @description : to handle on drag end gizmo axis for text wall
	 */
	private _onDragEndGizmoAxisTextWallHandler(): void {		
		if (this.selectedExhibitAssets.length > 1) {
			this.selectedExhibitAssets.map((node) => {
				const textWallData = this._textLoader.texts.find((x: any)=> x.id == node.id.replace("textWall-",""));
				this.updateTextData(node, textWallData, true);
			})
		} else {
			this.updateTextData(this._textLoader.activeTextNode, this._textLoader.activeText);
		}
		this.dataHasChanges = true;
	}

	/**
	 * ANCHOR On Drag End Gizmo Axis Ordinary Object Handler
	 * @description : to handle on drag end gizmo axis for ordinary object
	 */
	private _onDragEndGizmoAxisOrdinaryObjectHandler(): void {
		this.updateOrdinaryObjectData(this.activeOrdinaryObjectNode, this.activeOrdinaryObject);
		this.dataHasChanges = true;
	}

	// !SECTION

	// SECTION Visibility Gizmo Axis Handler

	/**
	 * ANCHOR Show Gizmo Axis
	 * @description to showing gizmo axis
	 * @param action : string -> position | rotation
	 * @param attachedMesh : BABYLON.Mesh
	 */
	public showAxis(action: 'position' | 'rotation', attachedMesh: any): void {
		if(action === 'rotation') {
			let gizmoRotateMesh = this.scene.getMeshByName('_gizmoRotateMesh');
			if (!gizmoRotateMesh) {
				gizmoRotateMesh = BABYLON.MeshBuilder.CreateBox('_gizmoRotateMesh', {}, this.scene);
				gizmoRotateMesh.visibility = 0;
			}

			gizmoRotateMesh.setEnabled(true)
			gizmoRotateMesh.rotation = attachedMesh.rotation.clone();
			gizmoRotateMesh.position = attachedMesh.position.clone();

			attachedMesh = gizmoRotateMesh;
		}
		this.gizmos[action].attachedMesh = attachedMesh;
	}

	/**
	 * ANCHOR Hide Gizmo Axis
	 * @description to hiding gizmo axis
	 */
	public hideAxis(): void {
		if (this.gizmos.rotation.attachedMesh || this.gizmos.position.attachedMesh) {
			this.scene.getMeshByName('_gizmoRotateMesh')?.setEnabled(false);
			Object.entries(this.gizmos).forEach((gizmo: any[]) => gizmo[1].attachedMesh = null);
		}
	}

	// !SECTION

	//#endregion
	// !SECTION

	/**
	 * * ================================================================================================ *
	 *   SECTION Manage Artworks Position Placaholder Functions
	 * * ================================================================================================ *
	 */
	//#region 

	public positionPlaceholders: PositionPlaceholders[] = [];
	public positionPlaceholdersMeshes: any[] = [];

	/**
	 * ANCHOR Create Artwork Position Placeholder Meshes
	 * @description : to create the artwork position placeholder meshes
	 */
	private _createPositionPlaceholderMeshes(): void {
		if(this.positionPlaceholders.length === 0) {
			this.positionPlaceholders = this.exhibition.config.positionPlaceholders || [];
			this.positionPlaceholders.forEach((position: PositionPlaceholders) => {
				const mesh = BABYLON.MeshBuilder.CreateBox(
					"positionPlaceholder-" + position.id, 
					{ depth: 0.05, height: 1.58, width: 1.58 }, 
					this.scene
				);
				mesh.artworkId = position.artwork_id;
				mesh.id = position.id;
				mesh.position = new BABYLON.Vector3(
					position.position_x, 
					position.position_y, 
					position.position_z
				);
				mesh.rotation = new BABYLON.Vector3(
					position.rotation_x,
					position.rotation_y,
					position.rotation_z
				);
				mesh.visibility = 0;
				mesh.isPickable = false;
				mesh.occlusionType = BABYLON.AbstractMesh.OCCLUSION_TYPE_OPTIMISTIC;
				this.positionPlaceholdersMeshes.push(mesh);
			});
		}
	}

	/**
	 * ANCHOR Mark As Unused If Related Artwork Not Exists
	 * @description : to mark as unused if related artwork not exists
	 */
	public markAsUnusedIfRelatedArtworkNotExists(): void {
		this.positionPlaceholders.forEach((position: PositionPlaceholders) => {
			const artworkNode = this.scene.getTransformNodeByID('artwork-' + position.artwork_id);
			if(!artworkNode) this.markPositionPlaceholderAsUnused(position.artwork_id);
		});
	
	}

	/**
	 * ANCHOR Get Unused Position Placeholders
	 * @description : to get the unused position placeholders
	 * @returns : PositionPlaceholders[]
	 */
	public getUnusedPositionPlaceholders(): any[] {
		const unusedPositionPlaceholders: any[] = [];
		this.positionPlaceholdersMeshes.forEach((mesh: any) => {
			if(!mesh.artworkId) unusedPositionPlaceholders.push(mesh);
		});
		return unusedPositionPlaceholders;
	}

	/**
	 * ANCHOR Get Position Placeholder In Front Of Camera
	 * @description : to get the position placeholder in front of camera
	 * @returns : any
	 */
	public async getPositionPlaceholderInFrontOfCamera(): Promise<{ mesh: any, data: PositionPlaceholders}> {
		const meshes = this.getUnusedPositionPlaceholders();
		let closestMesh = null;
		let closestDistance = 1000;
		for(let i = 0; i < meshes.length; i++) {
			const mesh = meshes[i];
			mesh.visibility = 0.1;
			mesh.pickable = true;
			const distance = this._utilsService.calculateDistanceCameraAndMesh(mesh, this.mainCameraNode);
			await new Promise((resolve, reject) => {
				setTimeout(() => {
					if(this.mainCameraNode.isInFrustum(mesh) && !mesh.isOccluded && distance < closestDistance) {
						closestMesh = mesh;
						closestDistance = distance;
					}
					mesh.visibility = 0;
					mesh.ispicked = false;
					resolve(null);
				}, 50)
			})
		}

		if(!closestMesh) return { mesh: null, data: null };

		const closestPositionPlaceholder = this.positionPlaceholders.find((position: PositionPlaceholders) => 
			position.id === closestMesh.id
		);

		return { mesh: closestMesh, data: closestPositionPlaceholder };
	}

	/**
	 * ANCHOR Mark Position Placeholder As Used
	 * @description : to mark the position placeholder as used
	 * @param mesh : BABYLON.Mesh
	 * @param artworkId : string
	 */
	public markPositionPlaceholderAsUsed(mesh: any, artworkId: string): void {
		const positionPlaceholder = this.positionPlaceholders.find((position: PositionPlaceholders) => 
			position.id === mesh.id
		);
		positionPlaceholder.artwork_id = artworkId;
		mesh.artworkId = artworkId;

		this.updateExhibtionConfigInDatabase();
	}

	/**
	 * ANCHOR Mark Position Placeholder As Unused
	 * @description : to mark the position placeholder as unused
	 * @param artworkId : string
	 */
	public markPositionPlaceholderAsUnused(artworkId: any): void {
		const positionPlaceholder = this.positionPlaceholders.find((position: PositionPlaceholders) => 
			position.artwork_id === artworkId
		);

		if (positionPlaceholder) {
			positionPlaceholder.artwork_id = null;
			const mesh = this.positionPlaceholdersMeshes.find((mesh: any) => mesh.id === positionPlaceholder.id);
			mesh.artworkId = null;
		}
		
		this.updateExhibtionConfigInDatabase();
	}

	/**
	 * ANCHOR Mark Position Placeholder Is Used Or Not
	 * @description : to mark the position placeholder is used or not
	 * @param artworkNode : any
	 */
	public async markPositionPlaceholderIsUsedOrNot(artworkNode: any): Promise<void> {
		const tmpMesh = BABYLON.MeshBuilder.CreateBox('tmpMesh', {}, this.scene);
		tmpMesh.visibility = 0;
		tmpMesh.position = artworkNode.position.clone();
		tmpMesh.rotation = artworkNode.rotation.clone();
		tmpMesh.scaling = artworkNode.scaling.clone();


		let isOnPlaceholderArea = false;
		let intesectedMesh = [];
		for(let i = 0; i < this.positionPlaceholdersMeshes.length; i++) {
			if (await this._utilsService.isMeshesIntesected(this.positionPlaceholdersMeshes[i], tmpMesh)) {
				isOnPlaceholderArea = true;
				intesectedMesh.push(this.positionPlaceholdersMeshes[i]);
			}
		}

		const artworkId = artworkNode.id.replace('artwork-', '');
		if(isOnPlaceholderArea) {
			if(this._isArtworkRelatedToPositionPlaceholder(artworkId)) {
				this.markPositionPlaceholderAsUnused(artworkId);
			}

			intesectedMesh.forEach((mesh: any) => {
				this.markPositionPlaceholderAsUsed(mesh, artworkId);
			})
		} else {
			this.markPositionPlaceholderAsUnused(artworkId);
		}
		
		tmpMesh.dispose();
	}

	private _isArtworkRelatedToPositionPlaceholder(artworkId: any): boolean {
		const positionPlaceholder = this.positionPlaceholders.find((position: PositionPlaceholders) => 
			position.artwork_id === artworkId
		);

		if(positionPlaceholder) return true;
		return false;
	}

	//#endregion
	//!SECTION

	/**
	 * * ================================================================================================ * 
	 *   SECTION Artwork Shadow Cast Functions
	 * * ================================================================================================ * 
	 */
	
	//#region

	/**
	 * ANCHOR Create Shadow Cast of Artwork
	 * @description Create Shadow Cast of Artwork
	 * @param artworkNode : BABYLON.TransformNode
	 * @param dontSetPosition : boolean
	 */
	public createShadowCast(artworkNode: any){
		const { shadowReciver } = this._artworkShadowsService.setShadowCastMetadata(artworkNode);
		this._utilsService.excludedMeshHighlightLayer({
			mesh: shadowReciver,
			highlightLayer: this.highlightLayer,
			isAdd: true
		})
		this._artworkShadowsService.addShadowCaster(artworkNode);
		this._artworkShadowsService.setShadowCastPosition(artworkNode);
	}

	//#endregion
	//!SECTION

	/**
	 * * ================================================================================================ * 
	 *   SECTION Artwork Shadow Component Functions
	 * * ================================================================================================ * 
	 */

	//#region 

	/**
	 * ANCHOR Create Shadow Components
	 * @description Create Shadow Components
	 * @param artworkNode : BABYLON.TransformNode
	 */
	public createShadowComponents(artworkNode: any): void {
		const lights = this._artworkShadowsService.createShadowComponentLights(artworkNode);
		artworkNode.metadata = {
			...artworkNode.metadata,
			shadowComponent: {
				lightIds: lights.map((light: any) => light.id),
			}
		}
		this._artworkShadowsService.setShadowComponentLightsPosition(artworkNode);
	}


	//#endregion
	//!SECTION

	/**
	 * * ================================================================================================ *
	 *   SECTION Undo/Redo Functions
	 * * ================================================================================================ *
	 */
	//#region 


	/**
	 * ANCHOR Undo Redo Changes
	 * @description : to handle undo redo changes
	 * @param action: String -> "undo" or "redo"
	 */
	public undoRedo = debounce((action: "undo" | "redo") => { 
		this.dataHasChanges = true;
		this.blockUserAction = true;
		this._undoRedoService.onUndoRedo(true, action);
		setTimeout(async()=> {
			const state = this._undoRedoService.undoRedo(action)
			this.ordinaryObjects = state.ordinaryObjects;
			this.artworks = state.artworks;
			this._textLoader.texts = state.texts;
			this.camera = state.camera;
			this.exhibition = state.exhibition;
			
			this.updateSettings();
			await this.undoRedoArtworks();
			this.undoRedoTextWalls();
			this.undoRedoOrdinaryObjects();
			
			store.dispatch({type: "UNDO_REDO_ACTION", undoRedo: Date.now()});
			this._undoRedoService.onUndoRedo(false, action);
			this.blockUserAction = false;
		}, 100)
	}, 500)

	/**
	 * ANCHOR Register Undo Redo Keyboard Event
	 * @description : to register undo redo keyboard event
	 */
	public registerUndoRedoKeyboardEvent() {
		this._undoRedoService.registerUndoRedoKeyboardEvent({
			onUndo: () => !this.blockUserAction && this.undoRedo("undo"),
			onRedo: () => !this.blockUserAction && 	this.undoRedo("redo")
		})
	}

	/**
	 * ANCHOR Undo Redo Changes For "Setting"
	 * @description : to handle undo redo changes for "setting"
	 */
	public async updateSettings(): Promise<void> {
		this.mainCameraNode.speed = this.camera.movement_speed;
		this.mainCameraNode.inputs.attached.keyboardRotate.sensibility = this.camera.rotate_speed;
		
		this.setExhibitionLighting();

		const footingPosition: any = this._utilsService.getFootingPosition(this.mainCameraNode.position, this.scene);
		let realHeight = this.camera.height_camera / (49.75124378109452/100);
		this.mainCameraNode.position.y = realHeight + footingPosition.y;
		this.mainCameraNode.ellipsoid.y = this.camera.height_camera;
		
		if (this.activeArtworkNode || this._textLoader.activeTextNode || this.activeOrdinaryObjectNode){
			this.setHorizontalCameraMovementObs(false);
		} else {
			this.setHorizontalCameraMovementObs(this.exhibition.horizontal_view_movement)
		}

		if (this.exhibition.model_type != this.activeExhibitionNode.modelType) {
			this.switchExhibition(this.exhibition.model_type);
		}

		this.exhibition.color_config.map(color => {
			const colorWithMeshes = this.activeExhibitionNode.color_config.find(x => x.label == color.label);
			colorWithMeshes.used_original = color.used_original;
			colorWithMeshes.color = color.color;
			if (!color.used_original) {
				colorWithMeshes.meshes.map(mesh => mesh.material.albedoColor = BABYLON.Color3.FromHexString(color.color))
			} else {
				colorWithMeshes.meshes.map(mesh => mesh.material.albedoColor = cloneDeep(mesh.original_color))
			}
		})
	}


	/**
	 * ANCHOR Unselect Artworks Before Undo Redo
	 * @description : to unselect artworks before undo redo
	 */
	private _unselectArtworkBeforeUndoRedo(): void {
		if(this.activeTab == "images") {
			if(this.selectedExhibitAssets.length > 1) this.unselectMultiExhibitAssets();
			else this.unselectExhibitAsset();
		}
	}

	/**
	 * ANCHOR Update Artwork Transform Undo Redo
	 * @description : to update artwork transform undo redo
	 * @param artwork : Artwork
	 * @returns : BABYLON.TransformNode
	 */
	private _updateArtworkTransformUndoRedo(artwork: Artwork) {
		const artworkNode = this.scene.getTransformNodeByID('artwork-' + artwork.id);
		const position = [...Object.values(artwork.position)]
		const rotation = [...Object.values(artwork.rotation)]
		const scaling = [...Object.values(artwork.scaling)]
		artworkNode.position = new BABYLON.Vector3(position[0], position[1], position[2]);
		artworkNode.rotation = new BABYLON.Vector3(rotation[0], rotation[1], rotation[2]);
		if (artwork.file_type == 'figure-object') {
			artworkNode.scaling = new BABYLON.Vector3(scaling[0], scaling[1], scaling[2]);
		}
		return artworkNode;
	}

	/**
	 * ANCHOR Recreate Artwork Undo Redo
	 * @description : to recreate artwork undo redo
	 * @param artwork : Artwork
	 * @returns : BABYLON.TransformNode
	 */
	private async _recreateArtworkUndoRedo(artwork: Artwork) {
		let artworkNode = this.scene.getTransformNodeByID('artwork-' + artwork.id);
		const metadata = artworkNode.metadata;
		this.artworkNodes = this.artworkNodes.filter((x: any) => x.id != artworkNode.id);
		this.artworkDonuts = this.artworkDonuts.filter((x: any) => x.uniqueId != artworkNode['donut'].uniqueId);
		const newArtworkNode = await this.createArtworkImageVideo(artwork, this.scene, false, false);
		artworkNode['donut']?.material?.emissiveTexture?.dispose();
		artworkNode['donut']?.material?.opacityTexture?.dispose();
		artworkNode['donut']?.material?.dispose();
		artworkNode['donut']?.dispose();
		artworkNode.getChildren().map((child: any) => child.material?.dispose())
		artworkNode.dispose()
		newArtworkNode.metadata = metadata;
		this.createArtworkDonut(newArtworkNode);
		return newArtworkNode;
	}

	/**
	 * ANCHOR Undo Redo Changes For "Artwork"
	 * @description : to handle undo redo changes for "artwork"
	 */
	public undoRedoArtworks(): Promise<void> {
		return new Promise((resolve) => {
			let activeArtworkNodeId: string = "";
			let activeArtworkNodesId: string[] = [];
			if(this.selectedExhibitAssets.length > 1) activeArtworkNodesId = this.selectedExhibitAssets.map((x: any) => x.id);
			else activeArtworkNodeId = this.activeArtworkNode?.id;

			const artworks = this.artworks.filter((artwork: Artwork) => !artwork.deleted)

			Promise.all(artworks.map((artwork: Artwork) => {
				return new Promise(async (resolve) => {
					let artworkNode = this.scene.getTransformNodeByID('artwork-' + artwork.id);
					if(artworkNode) {
						if(artwork.file_type != 'figure-object') {
							this._recreateArtworkUndoRedo(artwork).then((newArtworkNode: any) => {
								artworkNode = newArtworkNode;
								this._artworkShadowsService.addShadowCaster(artworkNode);
								this._artworkShadowsService.setShadowCastPosition(artworkNode);
								this._artworkShadowsService.setShadowComponentLightsPosition(artworkNode);
								resolve(artworkNode);
							})
						} else {
							artworkNode.setEnabled(true);
							artworkNode = this._updateArtworkTransformUndoRedo(artwork);
							artworkNode.getChildren().map((child: any) => this._setArtworkMeshLighting(child, artwork))
							resolve(artworkNode);
							this.artworkNodes.push(artworkNode)
						}
					} else {
						this.createArtworkImageVideo(artwork).then((newArtworkNode) => {
							this.createShadowCast(newArtworkNode);
							this.createShadowComponents(newArtworkNode);
							this.createArtworkDonut(newArtworkNode);
							resolve(newArtworkNode);
						});
					}
				})
			})).then((artworkNodes: any) => {
				const artworkIds = artworks.map((x: any) => x.id);
				this.artworkNodes.map((artworkNode: any) => {
					if(!artworkIds.includes(artworkNode.id.replace('artwork-', ''))) {
						if(artworkNode.metadata.artworkData.file_type != 'figure-object') {
							this._artworkShadowsService.removeShadowCast(artworkNode);
							this._artworkShadowsService.removeShadowComponents(artworkNode);
							artworkNode['donut']?.material?.emissiveTexture?.dispose();
							artworkNode['donut']?.material?.opacityTexture?.dispose();
							artworkNode['donut']?.material?.dispose();
							artworkNode['donut']?.dispose();
							artworkNode.getChildren().map((child: any) => child.material?.dispose())
							artworkNode.dispose();
						} else {
							artworkNode.setEnabled(false)
						}
					}
				})

				
				this.artworkNodes = this.artworkNodes.filter((x: any) => !x.isDisposed() || !x.isEnabled());
				this.artworkDonuts = this.artworkDonuts.filter((x: any) => !x.isDisposed());		
				this._unselectArtworkBeforeUndoRedo();
				this.artworkNodes.forEach((artworkNode: any) => {
					this.markPositionPlaceholderIsUsedOrNot(artworkNode);
					if(activeArtworkNodesId.length > 0) {
						if(activeArtworkNodesId.includes(artworkNode.id)){
							this.setActiveExhibitAsset(artworkNode);
							this.selectedExhibitAssets.push(artworkNode);
						}
					} else {
						if(artworkNode.id==activeArtworkNodeId) this.selectExhibitAsset(artworkNode);
					}
				});
				this.markAsUnusedIfRelatedArtworkNotExists();
				resolve()
			})
		})
	}


	/**
	 * ANCHOR Undo Redo Changes For "Text Wall"
	 * @description : to handle undo redo changes for "text wall"
	 */
	public undoRedoTextWalls(): void {
		let activeTextWallNodeId: string = "";
		let activeTextWallNodesId: string[] = [];
		if(this.selectedExhibitAssets.length > 1) activeTextWallNodesId = this.selectedExhibitAssets.map((x: any) => x.id);
		else activeTextWallNodeId = this._textLoader.activeTextNode?.id;
		if(this.activeTab == "text") {
			if(this.selectedExhibitAssets.length > 1) this.unselectMultiExhibitAssets();
			else this.unselectExhibitAsset();
		};

		this._textLoader.textsNode.map((textNode:any)=> textNode.dispose());
		this._textLoader.textsNode = [];

		const texts = this._textLoader.texts.filter((text: any) => !text.deleted);
		texts.map(async (text:any) => {
			const textWallNode = await this._textLoader.loadText(text, this.scene);
			if(activeTextWallNodesId.length > 0) {
				if(activeTextWallNodesId.includes(textWallNode.id)){
					this.setActiveExhibitAsset(textWallNode);
					this.selectedExhibitAssets.push(textWallNode);
				}
			} else {
				if(textWallNode.id == activeTextWallNodeId) this.selectExhibitAsset(textWallNode);
			}
		});

	}

	/**
	 * ANCHOR Undo Redo Changes For "Ordinary Object"
	 * @description : to handle undo redo changes for "ordinary object"
	 */
	public undoRedoOrdinaryObjects(): void {
		const activeOrdinaryObjectId = this.activeOrdinaryObject?.id;
		if (this.activeTab == "objects") this.unselectExhibitAsset();

		this.ordinaryObjectsNodes.map((objectNode:any)=> objectNode.setEnabled(false));
		this.ordinaryObjectsNodes = [];
		this.ordinaryObjects.map((ordinaryObject) => {
			if (!ordinaryObject.deleted) {
				const ordinaryObjectMesh = this.scene.getTransformNodeByID("ordinaryObject-" + ordinaryObject.id);
				ordinaryObjectMesh.position = new BABYLON.Vector3(
				ordinaryObject.position.position_x,
				ordinaryObject.position.position_y,
				ordinaryObject.position.position_z
				);
				ordinaryObjectMesh.rotation = new BABYLON.Vector3(
					ordinaryObject.rotation.rotation_x,
					ordinaryObject.rotation.rotation_y,
					ordinaryObject.rotation.rotation_z
				);
				ordinaryObjectMesh.scaling = new BABYLON.Vector3(
					ordinaryObject.scaling.scaling_x,
					ordinaryObject.scaling.scaling_y,
					ordinaryObject.scaling.scaling_z
				);

				ordinaryObjectMesh.getChildren().forEach((mesh: any) => {
					mesh.material.environmentIntensity = ordinaryObject.light_intensity / 2;
				})

				if (ordinaryObject.id == activeOrdinaryObjectId) this.selectExhibitAsset(ordinaryObjectMesh);
				ordinaryObjectMesh.setEnabled(true);
				this.ordinaryObjectsNodes.push(ordinaryObjectMesh);
			}
		});
	}
	

	/**
	 * ANCHOR Update Undo Redo State
	 * @description : to update undo redo state
	 */
	public updateUndoRedoState(initial = false) {
		const state: State = {
			artworks: this.artworks,
			ordinaryObjects: this.ordinaryObjects,
			texts: this._textLoader.texts,
			exhibition: this.exhibition,
			camera: this.camera,
			stateValid: this.exhibitionDataValid,
		}
		if(initial) this._undoRedoService.initStates(state);
		else {
			this._undoRedoService.addState(state);
			this.dataHasChanges = true;
		}
	}

	/**
	 * ANCHOR Update Undo Redo State With Delay
	 * @description : to update undo redo state with delay
	 */
	public updateUndoRedoStateWithDelay = debounce(() => {
		this.updateUndoRedoState()
	}, 1000)


	//#endregion
	// !SECTION

	/**
	 * * ================================================================================================ *
	 *   SECTION Camera Functions
	 * * ================================================================================================ *
   */
	// #region

	/**
	 * ANCHOR Setup Main Camera
	 * @description to setup main camera
	 */
	public setupCamera(): void {
		this.createCameraNode()
		this._setInitialCameraPosition();
		this._createMeshFrontOfCamera();
		this._setupCollisionEllipsoid();
		this._addCameraControl();
		this._setupCameraRay();
		this.setHorizontalCameraMovementObs(this.exhibition.horizontal_view_movement);
		this.createCameraBottomRaycast();
	}

	/**
	 * ANCHOR Camera Out Of Room Handler
	 * @description to handle camera out of room
	 */
	public cameraOutOfRoomHandler(): void {
		if ((this.mainCameraNode.position.y < -10 || 
			this.mainCameraNode.position.y > this.activeExhibitionNode?.dimensions.height) &&
			!this.previewAutoPlacedArtworks
		) {
			this.mainCameraNode.position = this.cameraStartPos.clone();
			this.mainCameraNode.rotation = this.cameraStartRot.clone();
			this._messageService.add({severity: "warn", summary: "Warning", detail: "Camera is out of the room!"});
		}
	}

	/**
	 * ANCHOR Set Horizontal Camera Movement Observable
	 * @description to set horizontal camera movement observable
	 * @param enable : boolean
	 */
	public setHorizontalCameraMovementObs(enable: any): void {
		if(enable){
			const rotation = 0;
			if(!this.observables['blockXRotation']){
				this.observables['blockXRotation'] = this.scene.onBeforeRenderObservable.add(() => {
					this.mainCameraNode.rotation.x = rotation
				})
			}

			if(!this.observables['moveBasedOnPointerMovement']){
				this.observables['moveBasedOnPointerMovement'] = this.scene.onPointerObservable.add((eventData:any) => {
					if (eventData.event.buttons === 1) {
						const panningSensibility = 2000;
						const evt = eventData.event;
						const offsetY = evt.movementY || evt.mozMovementY || evt.webkitMovementY || evt.msMovementY || 0;
						this.mainCameraNode.cameraDirection.addInPlace(this.mainCameraNode.getDirection(BABYLON.Vector3.Forward()).scale(-(offsetY / panningSensibility)));
					}
				}, BABYLON.PointerEventTypes.POINTERMOVE);
			}
		}else{
			if(this.observables['blockXRotation']) this.scene.onBeforeRenderObservable.remove(this.observables['blockXRotation'])
			if(this.observables['moveBasedOnPointerMovement']) this.scene.onPointerObservable.remove(this.observables['moveBasedOnPointerMovement'])
			this.observables['blockXRotation'] =  null;
			this.observables['moveBasedOnPointerMovement'] = null;
		}
	}

	/**
	 * ANCHOR Setup Camera Bottom Raycast
	 * @description to setup camera bottom raycast
	 */
	private _setupCameraRay(): void {
		this.cameraRay = new BABYLON.Ray( BABYLON.Vector3.Zero(), new BABYLON.Vector3(0,-1,0), 100);
		this.cameraStartPos = this.mainCameraNode.position.clone();
		this.cameraStartRot = this.mainCameraNode.rotation.clone();
	}

	/**
	 * ANCHOR Apply Camera Gravity And Control
	 * @description to apply camera gravity and control
	 */
	public applyCameraGravityAndControl(): void {
		this.mainCameraNode.attachControl(this.canvas, false);
		this.detectCameraAboveStairs();
		if (this.camera['aboveFloor']) this.scene.gravity.y = 0;
		this.mainCameraNode.applyGravity = true;
		this.canvas.onblur = () => {
			this.mainCameraNode.detachControl(this.canvas);
		}
		this.canvas.onfocus = () => {
			this.scene.activeCamera.attachControl(this.canvas, false)
		}
	}

	/**
	 * ANCHOR Move Camera By Clicking Floor
	 * @description to move camera by clicking floor
	 */
	private movingAnimation: any;
	private _moveCamera(endPosition:any): void {
		if(this.allAssetsHasLoaded){
			if(!this.pointerIntesectWithWall && !this.unfocusAnimatonIsRun){
				this._cancelMovingCameraAnimation();

				// Set Y position of end position to be same with camera height
				endPosition.y += this.mainCameraNode.ellipsoid.y / (49.75124378109452/100);
				
				// Set total frame & frame per second moving camera animation
				const totalFrame = BABYLON.Vector3.Distance(this.mainCameraNode.position, endPosition)
				const framePerSecond = 5;

				// Create handler when animation end
				const onEndAnimationHandler = () => {
					this.scene.gravity.y = this.gravity;
					this.movingAnimation = null;
				}

				// run animation
				this.scene.gravity.y = 0;
				this.movingAnimation = BABYLON.Animation.CreateAndStartAnimation(
					'moveCameraAnimation', 
					this.mainCameraNode, 
					'position', 
					framePerSecond, 
					totalFrame, 
					this.mainCameraNode.position, 
					endPosition,
					BABYLON.Animation.ANIMATIONLOOPMODE_RELATIVE,
					undefined,
					onEndAnimationHandler
				);
			}   
		} else {
      this._messageService.add({severity: "warn", summary: "Warning", detail: "Please wait until all assets have been loaded"})
    }
	}

	/**
	 * ANCHOR Cancel Moving Camera Animation
	 * @description to cancel moving camera animation
	 */
	private _cancelMovingCameraAnimation(): void {
		if(this.movingAnimation) {
			this.movingAnimation.pause();
			this.movingAnimation = null;
		}
	}

	/**
	 * ANCHOR Craete Camera Node
	 * @description to create camera node
	 */
	public createCameraNode(): void {
		this.mainCameraNode = new BABYLON.FreeCamera("mainCamera", new BABYLON.Vector3(0, 0, -10), this.scene);
		this.mainCameraNode.id = this.exhibition.id;
		this.mainCameraNode.speed = this.camera.movement_speed;
		this.mainCameraNode.minZ = 0;
		this.mainCameraNode.fov = this.exhibition.desktop_fov;
		this.mainCameraNode.far = 500000;
		this.mainCameraNode.angularSensibility = 3000;
    this.scene.activeCamera = this.mainCameraNode;
	}

	/**
	 * ANCHOR Set Initial Camera Position
	 * @description to set initial camera position
	 */
	private _setInitialCameraPosition(): void {
		this.mainCameraNode.position = new BABYLON.Vector3(this.camera.position.position_x, this.camera.position.position_y, this.camera.position.position_z);
		this.mainCameraNode.rotation = new BABYLON.Vector3(this.camera.target.target_x, this.camera.target.target_y, 0);
	}

	/**
	 * ANCHOR Create Mesh Front Of Camera
	 * @description to create mesh front of camera
	 */
	private _createMeshFrontOfCamera(): void {
		const mesh = BABYLON.MeshBuilder.CreateBox("_frontOfCamera", { size: 1 }, this.scene);
		mesh.position = this.mainCameraNode.position.clone();
		mesh.parent = this.mainCameraNode;
		mesh.visibility = 0;
		mesh.rotation = new BABYLON.Vector3(0, Math.PI, 0);
		mesh.position = new BABYLON.Vector3(0, 0, 2);
		mesh.isPickable = false
	}

	/**
	 * ANCHOR Setup Collision & Ellipsoid
	 */
	private _setupCollisionEllipsoid(): void {
    this.mainCameraNode.checkCollisions = true;
		this.mainCameraNode.ellipsoid = new BABYLON.Vector3( 0.4, this.camera.height_camera, 0.4);
	}

	/**
	 * ANCHOR Add Camera Control
	 * @description to add camera control
	 */
	private _addCameraControl(): void {
    // Remove default keyboard:
		this.mainCameraNode.inputs.remove(this.mainCameraNode.inputs.attached.keyboardRotate);
		this.mainCameraNode.inputs.remove(this.mainCameraNode.inputs.attached.keyboard);

		const _cameraData = this.camera;
		// Create Controls:
		let FreeCameraKeyboardRotateInput = function () {
			this._keys = [];
			this.keysUp = [87,38];
			this.keysDown = [83,40];
			this.keysLeft = [37,65];
			this.keysRight = [39,68];
			this.sensibility = _cameraData.rotate_speed;
		}
	
		// Hooking keyboard events
		FreeCameraKeyboardRotateInput.prototype.attachControl = function (noPreventDefault) {
			var _this = this;
			var engine = this.camera.getEngine();
			var element = engine.getInputElement();
	
			if (!this._onKeyDown) {
				element.tabIndex = 1;
				this._onKeyDown = function (evt) {
					if (_this.keysLeft.indexOf(evt.keyCode) !== -1 ||
						_this.keysRight.indexOf(evt.keyCode) !== -1 ||
						_this.keysUp.indexOf(evt.keyCode) !== -1 ||
						_this.keysDown.indexOf(evt.keyCode) !== -1) {
						var index = _this._keys.indexOf(evt.keyCode);
						if (index === -1) {
							_this._keys.push(evt.keyCode);
						}
						if (!noPreventDefault) {
							evt.preventDefault();
						}
					}
				};
				this._onKeyUp = function (evt) {
					if (_this.keysLeft.indexOf(evt.keyCode) !== -1 ||
						_this.keysRight.indexOf(evt.keyCode) !== -1 ||
						_this.keysUp.indexOf(evt.keyCode) !== -1 ||
						_this.keysDown.indexOf(evt.keyCode) !== -1) {
						var index = _this._keys.indexOf(evt.keyCode);
						if (index >= 0) {
							this.executeUpstairsFunction = true;
							_this._keys.splice(index, 1);
						}
						if (!noPreventDefault) {
							evt.preventDefault();
						}
					}
				};
	
				element.addEventListener("keydown", this._onKeyDown, false);
				element.addEventListener("keyup", this._onKeyUp, false);
			}
		};
	
		// Unhook
		FreeCameraKeyboardRotateInput.prototype.detachControl = function () {
			if (this._onKeyDown) {
				var engine = this.camera.getEngine();
				var element = engine.getInputElement();
				// element.removeEventListener("keydown", this._onKeyDown);
				// element.removeEventListener("keyup", this._onKeyUp);
			
				this._keys = [];
				this._onKeyDown = null;
				this._onKeyUp = null;
			}
		};
	
		// This function is called by the system on every frame
	    FreeCameraKeyboardRotateInput.prototype.checkInputs = function () {
			if (this._onKeyDown) {
				var cam = this.camera;
				// Keyboard
				for (var index = 0; index < this._keys.length; index++) {
					var keyCode = this._keys[index];
					if (this.keysLeft.indexOf(keyCode) !== -1 && keyCode == 37) {
						cam.cameraRotation.y -= this.sensibility;
					}
					else if (this.keysRight.indexOf(keyCode) !== -1 && keyCode == 39) {
						cam.cameraRotation.y += this.sensibility;
					}
					if (this.keysLeft.indexOf(keyCode) !== -1 && keyCode == 65) {
						cam.cameraDirection.addInPlace(cam.getDirection(BABYLON.Vector3.Left()).scale(cam.speed / 25));
					}
					if (this.keysRight.indexOf(keyCode) !== -1 && keyCode == 68) {
						cam.cameraDirection.addInPlace(cam.getDirection(BABYLON.Vector3.Right()).scale(cam.speed / 25));
					} 
					else if (this.keysUp.indexOf(keyCode) !== -1) {
            cam.cameraDirection.addInPlace(
                new BABYLON.Vector3(
                    Math.sin(cam.rotation.y),
                    0,
                    Math.cos(cam.rotation.y),
                ).scale(cam.speed/25),
            );
          } 
					else if (this.keysDown.indexOf(keyCode) !== -1) {
            cam.cameraDirection.addInPlace(
                new BABYLON.Vector3(
                    -Math.sin(cam.rotation.y),
                    0,
                    -Math.cos(cam.rotation.y),
                ).scale(cam.speed/25),
            );
          }
				}
			}
		};

		FreeCameraKeyboardRotateInput.prototype.getTypeName = function () {
			return "FreeCameraKeyboardRotateInput";
		};
		FreeCameraKeyboardRotateInput.prototype.getSimpleName = function () {
			return "keyboardRotate";
		};
	
		// Add controls to camera
		this.mainCameraNode.inputs.add(new FreeCameraKeyboardRotateInput());
  }
	// #endregion
	//!SECTION

	/**
	 * * ================================================================================================ *
	 *   SECTION Uncategorized Functions
	 * * ================================================================================================ *
	 */
	//#region

	/**
	 * ANCHOR Update Exhibition Config In Database
	 * @description : to update exhibition config in database
	 * @returns : Observable<any>
	 */
	public updateExhibtionConfigInDatabase = debounce((): void => {
		const query = `
			mutation UpdateConfig($config: jsonb!) {
				update_exhibitions(
					where: {id: {_eq: "${this.exhibition.id}"}},
					_set: {
						config: $config,
					}
				) {
					affected_rows
				}
			}
		`;

		const variables = {
			config: this.exhibition.config
		}
		this.mainService.queryGraphql(query, variables).subscribe()
	}, 2000)

	/**
   * * CREATE MESH BLOCKER FOR NEW HOKADIO ROOM [TEMPORARY CODE] *
   */
  createMeshBlokerForNewHokaido() {
    const meshBloker = BABYLON.MeshBuilder.CreateBox('meshBloker', {}, this.scene);
    meshBloker.position.z = -11;
    meshBloker.position.y = 1;
    meshBloker.scaling = new BABYLON.Vector3(10, 3, 0.05);
    meshBloker.visibility = 0;
    meshBloker.checkCollisions = false;
  }

	/**
	 * ANCHOR Optimize Scene
	 * @description optimize scene
	 * @param options : any
	 */
	public optimizeScene() {
		this._onOptimizingScene = true;
		const options = new BABYLON.SceneOptimizerOptions(40, 500);
		options.optimizations.push(new BABYLON.PostProcessesOptimization(1));
		options.optimizations.push(new BABYLON.ParticlesOptimization(1));
		options.optimizations.push(new BABYLON.TextureOptimization(2, 128));

		this.optimizer = new BABYLON.SceneOptimizer(this.scene, options);
		this.optimizer.start();

		// success optimization
		this.optimizer.onSuccessObservable.add(() => {
			this._onOptimizingScene = false;
		});

		// new optimization applied
		this.optimizer.onNewOptimizationAppliedObservable.add((optim) => {
			// on optimization applied
		});

		// error optimization
		this.optimizer.onFailureObservable.add(() => {
			this._onOptimizingScene = false;
		});
	}

	/**
	 * ANCHOR Mark Artwork As Updated When Undo Redo
	 * @description : to mark artwork as updated when undo redo (you must call this function before update states)
	 * @param artwork : Artwork
	 */
	public markForUpdate(artwork: Artwork , type: 'transform' | 'shape'): void {
		artwork.update = type;
		this._undoRedoService.states[this._undoRedoService.activeIndex].state.artworks.forEach((x: Artwork) => {
			if(x.id == artwork.id) x.update = type;
		})
	}

	/**
	/* ANCHOR SETUP TEXT TO SPEECH
	/* @description : to setup text to speech
	*/
	public setupTextToSpeech(): Promise<void> {
		return new Promise(async (resolve) => {
			await this._textToSpeechService.createTextToSpeechArtwork({
				sid: this.exhibition.id,
				artworks: this.artworks,
			});
			await this._textToSpeechService.createTextToSpeechExhibition(
				this.exhibition.description,
				this.exhibition.id,
				this.exhibition?.edited_description
			);

			this.artworks.map((artwork:any)=>{
				const path = this._textToSpeechService.streamUrlArtwork.find((stream:StreamUrlArtwork)=>stream.id == artwork.id);
				artwork.tts_path = path.url;
				return artwork;
			});
			if (this.exhibition.edited_description) {
				this.exhibition.tts_path = cloneDeep(this._textToSpeechService.streamUrlExhibitioin);
			}
			resolve();
		});
	}



	//#endregion
	//!SECTION
}
