// Angular modules/components/services
import { Component, Input, OnChanges } from '@angular/core';
import { NgIf, NgFor } from "@angular/common";

// Third-party plugins (NPM)
import moment from 'moment';

// User-defined services
import { ProfileService } from '../profile.service';
import { MainService } from "src/app/shared/services";
import { EditorService } from '../../virtual-gallery/editor/editor.service';
import { AlertMessageService } from 'src/app/components/alert-message/alert-message.service';

// Environment
import { environment } from '@environments';

// Interfaces
import { ExhibitionProfile, GetExhibitionParams, GetExhibitionResponse } from '../profile.interfaces';
import { Router, RouterLink } from '@angular/router';
import { DialogModule } from 'primeng/dialog';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { LazyLoadImageModule } from 'ng-lazyload-image';

@Component({
    selector: 'profile-main',
    templateUrl: './main.component.html',
    styleUrls: ['./main.component.scss'],
    standalone: true,
    imports: [
        NgIf,
        NgFor,
        LazyLoadImageModule,
        ProgressSpinnerModule,
        DialogModule,
        RouterLink,
    ],
})
export class MainComponent implements OnChanges {
  public loading:boolean = false;
	public offset: number = 0;
	public isShare: boolean = false;
	public displayConfirmDelete: boolean = false;
	public shareableLink: string;
	public iframe: string;
	public viewerPath: string = environment.viewer_path;
	public selectedExhibition: ExhibitionProfile;
  public exhibitions: ExhibitionProfile[] = [];
  public totalCount: number;
  public env = environment;

	@Input() isInside: boolean = true;
	@Input() infoUser:any;
	@Input() username:string;
  @Input() refetch: boolean = false;

  constructor(
		private alertMessageService: AlertMessageService,
    public mainService: MainService,
		private editorService: EditorService,
		public profileService: ProfileService,
    private _router: Router,
  ) {}

  ngOnChanges(): void {
		if(this.infoUser || this.profileService.refetchExhibitions) {
      this.exhibitions = [];
      this.getExhibitions();
    }
	}


  /**
   * * ======================================== *
   * * LIST OF FUNCTIONS                        *
   * * ======================================== *
   * - GET EXHIBITIONS
   * - LOAD EXHIBITION DATA
   * - SET DISPLAY EXHIBITON TIME
   * - SET EXHIBITION IMAGES PATH
   * - CREATE COUNTDOWN TIMER
   * - DELETE EXHIBITION
   * - OPEN LINK NEW TAB
   * - COPY TEXT
   * - OPEN SHARE LINK
   * - OPEN LINK
   */

  /**
   * * GET EXHIBITIONS *
   * Todo: to getting exhibition by username
   */
  getExhibitions(offset: number = 0){
    const params: GetExhibitionParams = {
      offset,
      username: this.username,
      type: this.isInside ? 'inside' : 'outside',
    }

    // Send request api to getting exibition
    this.loading = true;
    this.profileService.getExhibtions(params).subscribe((response: GetExhibitionResponse) => {
      this.loadData(response, offset);
      this.loading = false;
    }, err =>{
      if (err.name == "TimeoutError") {
        // Handle timeout error
        this.getExhibitions(offset);
      } else if(err.error.statusCode==401) {
        // Handle expired token
        this.mainService.expiredSesionPopup = true;
        this.loading = false;
      }else{
        // Handle other errors
        this.alertMessageService.add({ 
          severity: "error", 
          summary: "Error", 
          detail: "Something went wrong. Failed to load exhibitions."
        });
        this.loading = false;
      }
    });
  }

  /**
   * * LOAD EXHIBITION DATA *
   * Todo: to load exhibition data to collection
   */
  loadData(response: GetExhibitionResponse, offset: number){
    const exhibitions: ExhibitionProfile[] = response['data']['exhibitions'].map((exhibition: ExhibitionProfile) => {
      exhibition = this.setExhibitionImagesPath(exhibition);
      exhibition = this.setDisplayExhibitionTime(exhibition);

      return exhibition;
    })

    this.exhibitions = offset == 0 ? exhibitions : this.exhibitions.concat(exhibitions);
    this.totalCount = response['data']['totalCount'];
    this.profileService.totalCountExhibit = this.totalCount;
			
    if(this.mainService.isBrowser){
      setTimeout(() => window.dispatchEvent(new Event('resize')), 100);
    }

  }

  /**
   * * SET DISPLAY EXHIBITON TIME *
   * Todo: to set the exhibition time to display on the page
   */
  setDisplayExhibitionTime(exhibition: ExhibitionProfile) {
    if (exhibition.started) {
      exhibition.started_display = moment(exhibition.started, "YYYY-MM-DD").format("DD.MM.YYYY");
      exhibition.ended_display = moment(exhibition.ended, "YYYY-MM-DD").format("DD.MM.YYYY");
      exhibition.show_countdown = exhibition.countdown_timer && moment(moment().format("YYYY-MM-DD")).isBefore(exhibition.started);
    }

    if (exhibition.show_countdown) {
      exhibition = this.countdown(exhibition);
    }
    
    return exhibition;
  }

  /**
   * * SET EXHIBITION IMAGES PATH *
   * Todo: to update exibition images path (thumbail & cover image)
   */
  setExhibitionImagesPath(exhibition: ExhibitionProfile) {
    const thumbnailPath = environment.exhibition_path;
    if(!exhibition.thumbnail.includes(thumbnailPath)){
      exhibition.thumbnail = thumbnailPath + exhibition.thumbnail;
    }

    if (exhibition.cover_image && !exhibition.cover_image.includes(environment.image_path)) {
      exhibition.cover_image = this.mainService.convertPathImage(exhibition.cover_image);
    }

    return exhibition;
  }

  /**
   * * CREATE COUNTDOWN TIMER *
   * Todo: to create countdown timer
   */
  countdown(exhibition: ExhibitionProfile) {
    if(this.mainService.isBrowser){
      const getTimeCountdown = new Date(exhibition.started).getTime();
      const countdown = setInterval(() => {
        const time = Math.floor((getTimeCountdown-new Date().getTime())/1000);
        exhibition.time = {
          days: Math.floor(time / (3600 * 24)),
          hours: Math.floor((time % (3600 * 24)) / 3600),
          minutes: Math.floor((time % 3600) / 60),
          seconds: Math.floor(time % 60)
        };
        
        if (time === 0) clearInterval(countdown);
      }, 1000);

      return exhibition
    }
  }

  /**
   * * DELETE EXHIBITION *
   * Todo: to delete exhibition
   */
  public onDelete: boolean = false;
	deleteExhibition() {
		this.onDelete = true;
		this.editorService.deleteExhibition(this.selectedExhibition.id).subscribe((response: any) => {
      this.exhibitions = this.exhibitions.filter(x=>x.id!=this.selectedExhibition.id);
      this.totalCount--;

			this.onDelete = false;
			this.displayConfirmDelete = false;
			this.alertMessageService.add({severity: "success", summary: "Success", detail: "Your exhibition has been successfully deleted"})
		},err=>{
			this.onDelete = false;
			if (err.name == "TimeoutError") {
        // Handle timeout error
        this.deleteExhibition();
      }else if(err.error.statusCode === 401) {
        // Handle expired token
				this.mainService.expiredSesionPopup = true;
			}else{
        // Handle other errors
				this.alertMessageService.add({severity: "error", summary: "Failed", detail: "Something went wrong. The exhibition could not be deleted."})
			}
		})
	}
	
	/**
	 * * OPEN LINK NEW TAB *
	 * Todo: for open link in new tab
	 */
	openNewTab(url: string){ window.open(url) };

	/**
	 * * COPY TEXT *
	 * Todo: for copy text
	 */
	copyText(id){
		const copyText:any = document.getElementById(id);
		copyText.select();
		copyText.setSelectionRange(0, 99999);
		document.execCommand("copy");
    if (id === "sharelink") {
      this.alertMessageService.add({severity:"success", detail: "The link has been copied."})
    } else {
      this.alertMessageService.add({severity:"success", detail: "The code has been copied."})
    }
	}

  /**
   * * OPEN SHARE LINK *
   * Todo: to opne share link
   */
	openShareLink(e){
		this.isShare = true;
		const viewerLink = environment.viewer_path + '/' + e.share_string
		this.iframe = `<iframe width="560" height="315" src="${viewerLink}" title="${e.name}" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`;
		this.shareableLink = viewerLink;
	}

  /**
   * * OPEN LINK *
   * Todo: to open link
   */
	openLink(url){
    if(!url.toLowerCase().includes("https://")&&!url.toLowerCase().includes("http://")) {
      url = "https://"+url
    }
    window.open(url)
  }

  /**
   * Start creating exhibition
    */
  startCreating() {
    this.profileService.startCreating(() => {
      this._router.navigate([this.mainService.userInfo.username+'/create']);
    });
  }
}
