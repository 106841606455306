export const environment = {
	production: false,
	environment: 'LOCAL',
	base_host: 'https://dev.villume.com',
	baseURL: 'https://app-dev.villume.com',
	baseURL_GET: 'http://127.0.0.1:8080',
	baseGraphqlURL: 'https://api-dev.villume.com/v1/graphql',
	googleClientId: '158405709169-kpu8bqq33lr0lntt7937g0p1vrhbkgcg.apps.googleusercontent.com',
	image_path: "https://img2.villume.com/v/",
	exhibition_path: "https://assets.villume.com/DEV/",
	staticAssets: 'https://assets.villume.com/DEV/assets/',
	assets_path: "https://app-dev.villume.com/asset/",
	viewer_path: "https://dev-gallery.villume.com",
	video_path: 'https://node.villume.com',
	tts_url: 'https://tts.gothru.co/speech',
	port: 4902
};
