import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments';
import { AlertMessageService } from '../../alert-message/alert-message.service';
import { MainService } from '@services';
import { ArtworkDimension } from 'src/app/pages/virtual-gallery/editor/images/images.interfaces';
import { ProfileService } from 'src/app/pages/profile/profile.service';
import { LayoutsService } from 'src/app/layouts/layouts.service';


@Injectable({
	providedIn: 'root'
})
export class CreateExhibitionService {
	public stepWizard: number = 1;
	public fromCatalogue: boolean = false;
	public exhibitionData: any = {};

	constructor(
		private _http: HttpClient,
		private _messageService: AlertMessageService,
		private _mainService: MainService,
		private _profileService: ProfileService,
		private _layoutsService: LayoutsService,
	) {}

	/**
	 * * ANCHOR GET CATALOGUES *
	 * Todo: to get all the room catalog data
	 * @returns HttpObservable
	 */
	public getCatalogue(renderer: 'browser' | 'server') {
		let baseUrl: string;
		switch (renderer) {
			case 'browser':
				baseUrl = environment.baseURL
				break;
			case 'server':
				baseUrl = environment.baseURL_GET
				break;
		}

		return this._http.get(`${baseUrl}/room?v=1`,);
	}

	public createExhibitionUser(dataExhibition): Promise<any> {
		return new Promise((resolve, reject) => {
			this._validationProcess('create').subscribe((res: any) => {
				this._validationCreateExhibition().subscribe((res) => {
					this._createExhibition(dataExhibition).subscribe((res: any) => {
						resolve(res);

					}, err => {
						if (err.name == "TimeoutError") {
							this.createExhibitionUser(dataExhibition);
						} else if (err.error.statusCode == 403) {
							this._messageService.add({ severity: "warn", summary: "Warning", detail: "Your exhibition has reached the limit" })
						} else {
							this._messageService.add({ severity: "error", summary: "Error", detail: "Failed to create an exhibition." })
						}
						reject(err);
					})
				}, err => {
					if (err.name == "TimeoutError") {
						this.createExhibitionUser(dataExhibition);
					} else if (err.error.statusCode == 403) {
						const limit = err.error.data.limit;
						this._layoutsService.onlineExhibtionsLimit = limit.online_exhibitions;
						this._layoutsService.exhibtionDraftsLimit = limit.exhibition_drafts;
						this._layoutsService.billingProduct = err.error.data.product;
						this._layoutsService.displayLimitMessage = true;
					} else {
						this._messageService.add({ severity: "error", summary: "Error", detail: "Something went wrong. Failed to create the exhibition." })
					}
					reject(err);
				})
			}, err => {
				if (err.name == "TimeoutError") {
					this.createExhibitionUser(dataExhibition);
				} else if (err.error.statusCode == 403) {
					this._messageService.add({ severity: "warn", summary: "Warning", detail: "Please wait until the create exhibition process is complete" })
				} else if (err.error.statusCode == 401) {
					this._mainService.expiredSesionPopup = true;
				} else {
					this._messageService.add({ severity: "error", summary: "System Error", detail: "Something went wrong. Failed to create the exhibition." })
				}
				reject(err);
			})
		});
	}

	/**
	 * * VALIDATION CREATE EXHIBITION [API] *
	 * Todo: to validaion create exhibition
	 */
	private _validationCreateExhibition() {
		return this._http.get(`${environment.baseURL}/validation-create-exhibition`)
	}

	/**
	 * * VALIDATION PROCESS PUBLISH/CREATE EXHIBITION *
	 * Todo: to validating process pulish and create exhibition 
	 */
	private _validationProcess(type: string) {
		return this._http.post(`${environment.baseURL}/process-exhibition?param=${type}`, {})
	}

	/**
	 * * CREATE EXHIBITION *
	 * Todo: for creating Exhibition
	 * @returns HttpObservable
	 */
	private _createExhibition(exhibitionData) {
		const body = {
			v: 1,
			name: exhibitionData.name
		}
		return this._http.post(environment.baseURL + '/exhibitions-create/' + exhibitionData.key_room, body);
	}

	/**
	 * * GET EXHIBIBITION DATA [API] *
	 * Todo: API for get exhibition data in the database
	 * @returns HTTP Observable
	 */
	getExhibition(id:string) {
		return this._http.get(`${environment.baseURL}/exhibition-detail/${id}`);
	}

	/**
	 * * UPDATE EXHIBITION VIEWER *
	 * Todo: to update exhibition json viewer
	 */
	updateExhibitionViewer(share_string){
		return this._http.post(`${environment.baseURL}/exhibitions/update-viewer-json/${share_string}`,{});
	}

	/**
	 * * GET ARTWORK DIMENSION *
	 * Todo: to getting artwork dimension
   * @param file : File -> file
   * @returns : Promise<ArtworkDimension> -> artwork dimension
	 */
	public async  getArtworkDimension(file: File): Promise<ArtworkDimension> {
		const image = URL.createObjectURL(file);
		const imageResolution = await this.getImageResolutionFromUrl(image);
		const resizedResolution = this.resizeArtworkDimension(imageResolution);
		const resolution = {
			realWidth: imageResolution.width,
			realHeight: imageResolution.height,
			width: resizedResolution.width,
			height: resizedResolution.height
		}
		return resolution;
	}

	/**
	 * * GET IMAGE RESOLUTION *
	 * Todo : for geting resolution of image
	 * @param url: String -> path of image
	 */
	getImageResolutionFromUrl(url): Promise<{width: number, height: number}> {
		return new Promise((resolve, reject) => {
			try {
				let img = new Image();
				img.src = url;
				img.addEventListener("load", function () {
					resolve({width: this.naturalWidth, height: this.naturalHeight})
				});
			} catch (error) {
				reject(error)
			}
		})
	}

	/**
	 * * RESIZE ARTWORK DIMENSION *
	 * ANCHOR: to resize artwork dimension
	 * @param artworkDimension : ArtworkDimension -> artwork dimension
	 * @returns : ArtworkDimension -> resized artwork dimension
	 */
	public resizeArtworkDimension(artworkDimension: ArtworkDimension): ArtworkDimension {
		const { width, height } = artworkDimension;
		const ratioXY = height / width;
		const newWidth = 5000;
		const newHeight = newWidth * ratioXY;
		return {
			width: Math.round(this.covertPxToCm(newWidth)),
			height: Math.round(this.covertPxToCm(newHeight)),
			realHeight: this.convertPxToM(newHeight),
			realWidth: this.convertPxToM(newWidth)
		}
	}

	deleteExhibition(id) {
		return this._http.delete(environment.baseURL + "/delete-exhibition/" + id)
	}

	/**
	 * * UNIT CONVERTER *
	 * Todo: for convert unit
	 */
	covertPxToCm(pixel) {
		return pixel * 0.0264583333
	}

	convertPxToM(px) {
		return px * 0.0002645833
	}

	convertCmToInch(cm) {
		return cm * 0.393701
	}

	convertInchToCm(inch) {
		return inch * 2.54
	}

	
	// ======================== //
	// DISABLE KEY INPUT BACKSLASH
	// ======================= //
	blockChars(e) {
		if (e.key != "\\") return true;
		else e.preventDefault();
	}

	public blockSpaceAtStart(event: KeyboardEvent, element: HTMLInputElement): void {
		if (event.key === ' ' && element.selectionStart === 0) {
			event.preventDefault();
		}
	}
}
