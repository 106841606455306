import { Component, EventEmitter, Input, Output } from '@angular/core';
import { EditorService } from 'src/app/pages/virtual-gallery/editor/editor.service';
import { CreateExhibitionService } from '../../services/create-exhibition.service';
import { AllowedFile } from 'src/app/pages/virtual-gallery/editor/images/images.interfaces';
import { ImagesService } from 'src/app/pages/virtual-gallery/editor/images/images.service';
import { AddImageService } from 'src/app/pages/virtual-gallery/editor/images/add-image/add-image.service';
import { MainService } from '@services';
import { AlertMessageService } from 'src/app/components/alert-message/alert-message.service';
import { messages } from '@data';
import { environment } from '@environments';
import { NgIf } from '@angular/common';
declare const fetch: any;

@Component({
    selector: 'app-add-artwork',
    templateUrl: './add-artwork.component.html',
    styleUrls: ['./add-artwork.component.scss'],
    standalone: true,
    imports: [NgIf]
})
export class AddArtworkComponent {
	@Output() artworks: EventEmitter<AllowedFile[]> = new EventEmitter<AllowedFile[]>();
  @Input() config: any = {};
  @Input() isProfile: boolean = false;
  @Input() onCreating: boolean = false;

	constructor(
		private _editorService: EditorService,
		public createService: CreateExhibitionService,
		private _artworkService: ImagesService,
		private _addArtworkService: AddImageService,
		private _mainService: MainService,
		private _messageService: AlertMessageService
	) { }

	ngOnInit(): void {
		this.initDragDrop('dragArea');
    this.fetchUser(true);
	}


  /**
   * ANCHOR BACK ON PROFILE
   */
  public backOnProfile() {
    this.artworks.emit([]);
    this.createService.stepWizard--
  }

	/**
	 * * ANCHOR INIT DRAG DROP *
	 * Todo: to initialize drag and drop
	 */
	public dropHere: boolean = false;
	initDragDrop(id) {
		setTimeout(() => {
			const dropArea = document.getElementById(id);
			if (dropArea) {
				const handles = {
					ondrop: async (e) => {
						const dt = e.dataTransfer;
					
            this.handleFileInput(dt.files, true);
						this.dropHere = false;
					},
					ondragenter: (e) => {
						this.dropHere = true;
					},
					ondragleave: (e) => {
						this.dropHere = false;
					}
				}
				this._editorService.initDragAndDropFuction(dropArea, handles)
			}
		}, 1000)
	}

  fetchUser(refetch: boolean = false) {
    this._mainService.getUserProfile(refetch).subscribe((res: any) => {
      this._mainService.userInfo = res?.data?.result[0];
    });
  }

	/**
   * ANCHOR Filter Allowed Files
   * @description to filter allowed files
   * @param files : File[] -> files
   * @returns : File[] -> allowed files
   */
  private async _fillterAllowedFiles(files: File[]): Promise<AllowedFile[]> {
    const allowedFiles: AllowedFile[] = [];
    let isFileNotValid = false;
    for (let i = 0; i < files.length; i++) {
      if(!this._artworkService.validateFile(files[i])) {
        isFileNotValid = true;
        break;
      }
      
      const type = this._addArtworkService.getArtworkType(files[i]);
    
      if(type == 'other') break;
      
      if(type == 'image') {
        const isImageNotBroken = await this._mainService.isImageNotBroken(files[i]);
        if(!isImageNotBroken) { isFileNotValid = true; break };

        let lengthUnit:string;
        if (this._mainService.userInfo?.user_details[0]?.measurement == 'imperial') {
          lengthUnit = 'inch';
        } else {
          lengthUnit = 'cm';
        }
        
        const {height, width, realHeight, realWidth} = await this.createService.getArtworkDimension(files[i]);
        const ratioXY = height / width;
        const ratioYX = width / height;

        allowedFiles.push({
          id: this._addArtworkService.generateRandomString(10),
          file: files[i],
          thumbnailUrl: this._mainService.sanitize(URL.createObjectURL(files[i])) as string,
          height: height,
          width: width,
          real_height: realHeight,
          real_width: realWidth,
          ratioXY: ratioXY,
          ratioYX: ratioYX,
          lockRatio: false,
          length_unit: lengthUnit
        });
        continue;
      }
      
      if(type == 'object') {
        const thumbnailFile = await this._getThumbnailFromArtworkObject();
        const {height, width, realHeight, realWidth} = await this.createService.getArtworkDimension(thumbnailFile);
        allowedFiles.push({
          id: this._addArtworkService.generateRandomString(10),
          file: files[i],
          thumbnail: thumbnailFile,
          thumbnailUrl: this._mainService.sanitize(URL.createObjectURL(thumbnailFile)) as string,
          real_height: realHeight,
          real_width: realWidth,
          height,
          width,
        });
        continue;
      }
    }

    if (isFileNotValid) {
      this._messageService.add({
        severity:'warn', 
        summary: 'Warning',
        detail: messages.editor.artwork.upload.notAlowedFormat
      });

      return [];
    }

    return Promise.resolve(allowedFiles);
  }

	 /**
   * ANCHOR Get Thumbnail From Artwork Object
   * @returns : Promise<Blob>
   */
   private _getThumbnailFromArtworkObject(): Promise<File> {
    return new Promise((resolve, reject) => {
      fetch(environment.staticAssets+'images/other/ordinary-object.png?t='+this._mainService.appVersion)
        .then(response => response.blob())
        .then(blob => {
          const file = new File([blob], 'thumbnail.png', { type: 'image/png' });
          resolve(file)
        })
        .catch(reject);
      reject
    })
  }

  /**
   * ANCHOR Handle file input
   * @param event : Event
   */
  public handleFileInput(event, fromDrag=false): void {
    let files:any = [];
    if (fromDrag) {
      for (let i = 0; i < event.length; i++) {
        files.push(event[i]);
      }
    } else {
      const target = event.target as HTMLInputElement;
      files = target.files;
    }

    let maxFiles = this.config?.positionPlaceholders.length;
    if (maxFiles == 0) maxFiles = 10;

    if (files.length <= maxFiles) {
      this._fillterAllowedFiles(Array.from(files)).then((artworkFiles) => {
        this.artworks.emit(artworkFiles);
      });
    } else { 
      this._messageService.add({
        severity:'warn', 
        summary: 'Warning',
        detail: messages.editor.artwork.upload.add.max
      });
    }

    if (!fromDrag) event.target.value = '';
  }
}
