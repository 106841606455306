import { Component, OnInit } from '@angular/core';
import { MainService } from 'src/app/shared/services';
import { EditorService } from '../../../editor/editor.service';
import { environment } from '@environments';
import { FormsModule } from '@angular/forms';
import { SliderModule } from 'primeng/slider';
import { NgIf, NgStyle } from '@angular/common';
import { UndoRedoService } from 'src/app/shared/services/undo-redo.service';

@Component({
    selector: 'app-field-of-view',
    templateUrl: './field-of-view.component.html',
    styleUrls: ['./field-of-view.component.scss'],
    standalone: true,
    imports: [NgIf, SliderModule, FormsModule, NgStyle]
})
export class FieldOfViewComponent implements OnInit {

	public actionMenu: string = "desktop";
	public displaySize: number;
	public sizePercent: any;
	private zoomAction:any;
	private desktopFov: number;
	private tabletFov: number;
	private mobileFov: number;
	private engine = this.editorService.engine;
	public env = environment;

	constructor(
		public editorService: EditorService,
		public mainService: MainService,
		public _undoRedoService: UndoRedoService,
	) { }

	ngOnInit(): void {}

	/**
	 * * INIT/REGISTER SCENE *
	 * Todo: register & init scene on modal
	 */
	renderScene(){
		this.actionMenu = this.editorService.deviceScreen;
		this.setSliderValue();

		const canvas = document.getElementById('previewCanvas');
		this.editorService.scene.activeCamera.detachControl(this.editorService.canvas);
		this.engine.registerView(canvas);
		this.engine.inputElement = canvas;
		this.editorService.scene.activeCamera.attachControl(canvas);
		this.setupFovSize();
		this._undoRedoService.states.map((x)=>{
			if (x.state.exhibition) {
				x.state.exhibition.desktop_fov = this.desktopFov / -100;
				x.state.exhibition.tablet_fov = this.tabletFov / -100;
				x.state.exhibition.mobile_fov = this.mobileFov / -100;
			}
		})
	}

	/**
	 * * UNREGISTER SCENE ON MODAL *
	 * Todo: unregister view on modal
	 */
	unregisterScene(){
		this.engine.inputElement = document.getElementById('editorCanvas');
		this.editorService.engine.resize();
		this.engine.unRegisterView(document.getElementById('previewCanvas'));
	}

	/**
	 * * UPDATE SLIDER VALUE *
	 * Todo: update the slider value 
	 */
	updateSliderValue(value:number, min:number= -130, max:number= -65) {
		
		
		// validate input value
		value = this.editorService.validateSliderManualInput(value, min, max);
		switch (this.actionMenu) {
			case 'desktop': this.desktopFov = Math.round(value); break;
			case 'tablet': this.tabletFov = Math.round(value); break;
			case 'mobile': this.mobileFov = Math.round(value); break;
		}

		this.editorService.mainCameraNode.fov = Math.round(value) / -100;
		this.sizePercent = this.percentage(this.displaySize);
	}

	/**
	 * * UPDATE SLIDER VALUE FROM BUTTON *
	 * Todo: update the slider value from button
	 */
	updateButtonSlider(action, condition) {
		if (condition) {
			switch (action) {
				case 'up':
					this.zoomAction = setInterval(() => {
						this.displaySize += 0.1;
						this.displaySize = this.editorService.validateSliderManualInput(this.displaySize, -130, -65);
						this.updateSliderValue(this.displaySize);
					})
					break;

				case 'down':
					this.zoomAction = setInterval(() => {
						this.displaySize -= 0.1;
						this.displaySize = this.editorService.validateSliderManualInput(this.displaySize, -130, -65);
						this.updateSliderValue(this.displaySize);
					})
					break;
			}
		} else {
			if (this.zoomAction) {
				clearInterval(this.zoomAction)
				this.zoomAction = null;
			}
		}
	}

	// calculate percent (65 - 130)
	percentage(value: number) {
		value = value + 195;
		value -= 65;
		return ((100 * value) / 65).toFixed(0);
	}


	/**
	 * * SET SLIDER VALUE *
	 * Todo: initial slider value
	 */
	setSliderValue() {
		if (this.desktopFov == null || this.mobileFov == null || this.tabletFov == null) {
			this.desktopFov = Math.round(this.editorService.exhibition.desktop_fov * -100);
			this.tabletFov = Math.round(this.editorService.exhibition.tablet_fov * -100);
			this.mobileFov = Math.round(this.editorService.exhibition.mobile_fov * -100);
		}

		switch (this.actionMenu) {
			case 'desktop':
				this.displaySize = this.desktopFov;
				this.sizePercent = this.percentage(this.displaySize);
				break;
			case 'tablet':
				this.displaySize = this.tabletFov;
				this.sizePercent = this.percentage(this.displaySize);
				break;
			case 'mobile':
				this.displaySize = this.mobileFov;
				this.sizePercent = this.percentage(this.displaySize);
				break;
		}
	}

	saveChange() {
		this.editorService.exhibition.desktop_fov = this.desktopFov / -100;
		this.editorService.exhibition.tablet_fov = this.tabletFov / -100;
		this.editorService.exhibition.mobile_fov = this.mobileFov / -100;
		this.setupFovSize();

		this.editorService.dataHasChanges = true;
		this.editorService.updateLogActivity("Change field of view");

		this.editorService.showFieldOfView = false;
	}

	cancel() {
		this.setupFovSize();
		this.unregisterScene();
		this.actionMenu = 'desktop';
		this.desktopFov = null;
		this.mobileFov = null;
		this.displaySize = null;
	}

	tabMenu(display) {
		this.actionMenu = display;
		this.setSliderValue();
		this.updateSliderValue(this.displaySize);
	}

	setupFovSize(){
		switch (this.editorService.deviceScreen) {
            case 'desktop':
                this.editorService.mainCameraNode.fov = this.editorService.exhibition.desktop_fov;
                break;
            case 'tablet':
                this.editorService.mainCameraNode.fov = this.editorService.exhibition.tablet_fov;
                break;
            case 'mobile':
                this.editorService.mainCameraNode.fov = this.editorService.exhibition.mobile_fov;
                break;
        }
	}
}
