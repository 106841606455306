<div class="wrap-set-accont">
    <!-- Main Form -->
    <div [style.display]="showChangePasswordForm ? 'none' : 'block'">
        <!-- Email Input Group -->
        <div class="form-group">
            <label for="email">Email · private</label>
            <input type="text" 
                id="email"
                class="form-control" 
                disabled
                [value]="this.mainService.userInfo?.email" />
        </div>

        <!-- Password Input Group -->
        <div class="form-group">
            <label for="password" class="d-block">Password</label>
            <button 
                class="btn btn-sm btn-light" 
                (click)="openChangePasswordForm()">
                Change Password
            </button>
        </div>

        <!-- birthdate -->
        <div class="form-group mb25">
            <label>Birthdate</label>
            <app-input-date 
                (selectDate)="changeDate($event)"
                [day]="birth_date" 
                [month]="birth_month" 
                [year]="birth_year">
            </app-input-date>
            <small class="text-danger" *ngIf="!birthDateValid">
                The date of birth cannot be more than the current date
            </small>
        </div>

        <!-- timezone -->
        <div class="form-group mb25">
            <label>Time zone</label>
            <p-dropdown
                [options]="timeZones"
                [filter]="true"
                [(ngModel)]="selectedTimeZone"
                optionLabel="label"
                styleClass="bg-dark dropdown-filter dropdown-filter-dark md">
                <ng-template pTemplate="selectedItem">
                    <div class="flex align-items-center gap-2" *ngIf="selectedTimeZone">
                        <div>{{ selectedTimeZone.label }}</div>
                    </div>
                </ng-template>
            </p-dropdown>
        </div>
        
        <!-- Gender Input Group -->
        <div class="form-group mb50">
            <label>Gender:</label>
            <div class="gender">
                <!-- Male -->
                <div class="item-gender">
                    <input id="male" type="radio" [checked]="selectedGender === 'male'"/>
                    <label 
                        (click)="selectedGender = 'male'" 
                        for="male" 
                        class="text-nowrap">
                        male
                    </label>
                </div>

                <!-- Female -->
                <div class="item-gender">
                    <input id="female" type="radio" [checked]="selectedGender === 'female'"/>
                    <label 
                        (click)="selectedGender = 'female'" 
                        for="female" 
                        class="text-nowrap">
                        female
                    </label>
                </div>

                <!-- Non-Binary -->
                <div class="item-gender">
                    <input id="non-binary" type="radio" [checked]="selectedGender === 'non-binary'"/>
                    <label 
                        (click)="selectedGender = 'non-binary'" 
                        for="non-binary" 
                        class="text-nowrap">
                        non-binary
                    </label>
                </div>
            </div>
        </div>
    
        <!-- Save Changes Button -->
        <div class="wrap-btn-save">
            <button 
                class="btn btn-primary btn-sm" 
                [disabled]="loading" 
                (click)="saveChanges()" 
                [class.loading-button-primary]="loading">
                Save Changes
            </button>
        </div>

        <!-- Delete Account Button -->
        <div *ngIf="!isSupportAccount()" class="btn-delete-account">
            <button 
            class="btn btn-sm btn-light" 
            (click)="displayConfirmDelete = true">
            Delete This Account and Account Data
        </button>
    </div>
    </div>
    
    <!-- Change Password Popup -->
    <div 
        class="password-popup" 
        [formGroup]="formPassword" 
        [style.display]="showChangePasswordForm ? 'block' : 'none'">
        <!-- Current Password Input Group -->
        <ng-container *ngIf="havePassword">
            <div class="form-group" *ngIf="formPassword.controls.oldPassword as password">
                <label for="oldPasswors">Current password</label>
                <div class="d-flex align-items-end">
                    <input
                        #oldPassword
                        type="password" 
                        id="oldPasswors" 
                        class="form-control" 
                        [class.is-invalid]="password.invalid && password.touched" 
                        formControlName="oldPassword" />
                    <a href="javascript:;" routerLink="/forgot-password" class="forgot">Forgot it?</a>
                </div>
                <div class="invalid-feedback" *ngIf="password.invalid && password.touched">
                    <span *ngIf="password.errors?.minlength">Password must be at least 6 characters</span>
                    <span *ngIf="password.errors?.required">Password required</span>
                </div>
            </div>
        </ng-container>

        <!-- New Password Input Group -->
        <div class="form-group" *ngIf="formPassword.controls.newPassword as password">
            <label for="npassword">New password</label>
            <div class="d-flex align-items-center">
                <input [type]="showNewPassword ? 'text' : 'password'" 
                    class="form-control mb-0 pr-0"
                    id="npassword"
                    class="form-control" 
                    [class.is-invalid]="password.invalid&&password.touched" 
                    formControlName="newPassword"
                    [ngStyle]="{'padding-right.px': 40}" />

                <!-- SHOW/HIDE PASSWORD -->
                <div class="field-icon" (click)="showNewPassword = !showNewPassword">
                    <i class="vi vi-eye" *ngIf="showNewPassword"></i>
                    <i class="vi vi-eye-closed" *ngIf="!showNewPassword"></i>
                </div>
            </div>

            <div class="invalid-feedback" *ngIf="password.invalid&&password.touched">
                <span *ngIf="password.errors?.minlength">Password must be at least 6 characters</span>
                <span *ngIf="password.errors?.required">Password required</span>
            </div>
        </div>

        <!-- Confirm New Password Input Group -->
        <div class="form-group" *ngIf="formPassword.controls.confirmPassword as password">
            <label for="cpassword">Confirm new password</label>
            <div class="d-flex align-items-center">
                <input [type]="showConfirmPassword ? 'text' :'password'" 
                    class="form-control mb-0 pr-0"
                    id="cpassword" 
                    class="form-control" 
                    [class.is-invalid]="password.invalid && password.touched" 
                    formControlName="confirmPassword"
                    [ngStyle]="{'padding-right.px': 40 }" />

                <!-- SHOW/HIDE PASSWORD -->
                <div class="field-icon" (click)="showConfirmPassword = !showConfirmPassword">
                    <i class="vi vi-eye" *ngIf="showConfirmPassword"></i>
                    <i class="vi vi-eye-closed" *ngIf="!showConfirmPassword"></i>
                </div>
            </div>

            <div class="invalid-feedback" *ngIf="formPassword.invalid && password.touched">
                <span *ngIf="password.value > 0 && password.value !== formPassword.controls.newPassword.value">
                    Password must be match
                </span>
                <span *ngIf="password.errors?.required">Password required</span>
            </div>
        </div>

        <!-- Change Password Button -->
        <div class="btn-change-password">
            <button class="btn btn-sm btn-light" style="margin-right: auto;" (click)="showChangePasswordForm = false" [disabled]="loading">Cancel</button>
            <button class="btn btn-sm btn-light" (click)="changePassword()" [disabled]="formPassword.invalid || loading" [class.loading-button-primary]="loading">
                Change Password
            </button>
        </div>
    </div>
</div>

<!-- Confirm Delete Account Popup -->
<p-dialog 
    [(visible)]="displayConfirmDelete" 
    [modal]="true" 
    [style]="{maxWidth: '725px'}"
    [baseZIndex]="10000"
    [draggable]="false" 
    [resizable]="false"
    styleClass="confirm-delete">
    <div class="wrap-content-confirm">
        <div class="desc">
            <h3>Are you sure you want to delete this account forever?</h3>
            <p>By deleting your account you’re no longer be able to access any of your exhibitions or log in to Villume.</p>
        </div>
        <div class="wrap-btn">
            <button class="btn btn-sm btn-light" (click)="deleteAccount()" [disabled]="loading" [class.loading-button-primary]="loading">
                Yes
            </button>
            <button class="btn btn-sm btn-light" (click)="displayConfirmDelete=false" [disabled]="loading">No</button>
        </div>
    </div>
</p-dialog>