import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { MainService } from "src/app/shared/services";
import { SsrCookieService as CookieService } from 'ngx-cookie-service-ssr';
import { environment } from '@environments';
import { Router } from '@angular/router';
import { AlertMessageService } from 'src/app/components/alert-message/alert-message.service';

// Profile Interfaces
import { GetExhibitionParams } from './profile.interfaces';
import { FirstUpdateUserParams } from 'src/app/shared/interfaces';
import { LayoutsService } from 'src/app/layouts/layouts.service';

@Injectable({
  providedIn: 'root'
})
export class ProfileService {
	public isInside:boolean = false;
  public totalCountExhibit: number = 0;
	public refetchExhibitions: boolean = false;

  constructor(
    public mainService:MainService,
    private alertMessageService: AlertMessageService,
    private cookieService: CookieService,
    private router: Router,
    private http:HttpClient,
		private _layoutsService: LayoutsService,
  ) {}

	/**
	 * * ======================= *
	 * *  FUNCTIONS LIST
	 * * ======================= *
	 * - GET EXHIBITION DATA BY USERNAME CLIENT SIDE [API]
	 * - GET EXHIBITION DATA BY USERNAME SEVER SIDE [API]
	 * - UPDATE USER OTP TO VERIFICATION EMAIL [API]
	 * - CREATE EXHIBTION
	 * - VALIDATION CREATE EXHIBITION [API]
	 * - UPDATE PROFILE USER [QUERY]
	 * - UPDATE TOKEN [API]
   */

	/**
	 * * GET EXHIBITION DATA BY USERNAME CLIENT SIDE [API] *
	 * Todo: to getting exhibition by username client side
	 * @param username: String
	 * @param active: Any
	 * @param offset: Any
	 */
	getExhibtionClientSide(username,offset){
		return this.http.get(`${environment.baseURL}/exhibition/${username}?offset=${offset}`);
	}

	/**
	 * * GET EXHIBITION DATA BY USERNAME SEVER SIDE [API] *
	 * Todo: to getting exhibition by username server side
	 */
	getExhibtions(params: GetExhibitionParams){
    const { username, offset, type } = params;
		const stamp = Date.now();

		return this.http.get(`${environment.baseURL}/exhibition/${username}?offset=${offset}&type=${type}&stamp=${stamp}`);
	}

	/**
	 * * UPDATE USER OTP TO VERIFICATION EMAIL [API] *
	 * Todo: to updating user otp to verfication email
	 * @param code: Number
	 * @param token: String
	 */
	updateVerificationEmail(code,token){
		const options = {
			headers: new HttpHeaders({
				"accept": 'application/json',
				"Authorization": token
			})
		};
		
		return this.http.post(`${environment.baseURL}/users/check-otp`, {otp: Number(code)}, options);
	}

	/**
	 * * GET USER INFO WELCOME *
	 * Todo: to updating user info
	 * @param code: Number
	 * @param token: String
	 */
	getUserInfoWelcome(token){
		const query = `
			query {
				users {
					username
					email
				}
			}
		`;

		const options = {
			headers: new HttpHeaders({
				"accept": 'application/json',
				"authorization": "Bearer "+ token
			})
		};
		
		return this.http.post(`${environment.baseGraphqlURL}`, {query:query}, options);
	}

	/**
	 * * CREATE EXHIBTION *
	 * Todo: to creating create exhibition
	 */
	startCreating(callback = null, error = null): Promise<void> {
		return new Promise<void>((resolve, reject) => {
			const subscription = this.validationCreateExhibition().subscribe(
				(res: any) => {
					subscription.unsubscribe();
					if (callback) {
						callback();
					} else {
						this.router.navigateByUrl("/catalogue");
					}
					resolve();
				},
				(err) => {
					if (err.error.statusCode === 403) {
						const limit = err.error.data.limit;
						this._layoutsService.onlineExhibtionsLimit = limit.online_exhibitions;
						this._layoutsService.exhibtionDraftsLimit = limit.exhibition_drafts;
						this._layoutsService.billingProduct = err.error.data.product;
						this._layoutsService.displayLimitMessage = true;
						reject(err);
					} else if (err.name == "TimeoutError") {
						subscription.unsubscribe();
						this.startCreating().then(resolve).catch(reject);
					} else if (err.error.statusCode === 401) {
						this.mainService.expiredSesionPopup = true;
						reject(401);
					} else {
						this.alertMessageService.add({ severity: "error", summary: "Error", detail: "Something went wrong. Failed to create an exhibition." });
						reject();
					}
	
					if (error) {
						error();
						reject();
					}
				}
			);
		});
	}

	/**
	 * * VALIDATE CREATE AFTER LOGIN *
	 * Todo: to validate create after login
	 */
	public validateCreateAfterLogin(): Promise<void> {
		return new Promise<void>((resolve, reject) => {
			this.validationCreateExhibition().subscribe({
				next: (response) => {
					resolve();
				},
				error: (err) => {
					if (err.error.statusCode === 403) {
						const limit = err.error.data.limit;
						this._layoutsService.onlineExhibtionsLimit = limit.online_exhibitions;
						this._layoutsService.exhibtionDraftsLimit = limit.exhibition_drafts;
						this._layoutsService.billingProduct = err.error.data.product;
						this._layoutsService.displayLimitMessage = true;
					}
					reject(err);
				}
			});

			this.cookieService.set('validateCreate', 'false');
		});
  }
	

	/**
	 * * VALIDATION CREATE EXHIBITION [API] *
	 * Todo: to validaion create exhibition
	 */
	validationCreateExhibition(){
		return this.http.get(`${environment.baseURL}/validation-create-exhibition?stamp=${new Date().getTime()}`);
	}

	/**
	 * * UPDATE PROFILE USER [QUERY] *
	 * Todo: to updating profile user
	 */
	updateProfileUser(userData: FirstUpdateUserParams){
		return this.http.post(`${environment.baseURL}/users/update-info-user`, userData)
	}

	/**
	 * * UPDATE TOKEN [API] *
	 * Todo: to updating user token
	 * @param token: String
	 */
	updateToken(token, expires = null){
		return this.http.post(`${environment.base_host}/api/save-token`,{token, expires});
	}
}
