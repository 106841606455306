<app-layouts>
    <div class="wrap-main-profile" #mainLayout content>
        <div class="wrap-content">
            <profile-main 
                [isInside]="isInside"
                [infoUser]="infoUser"
                [username]="username"
                [refetch]="profileService.refetchExhibitions">
            </profile-main>
        </div>
    </div>
</app-layouts>
